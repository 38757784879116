import { CircularProgress, ListItemButton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { API, graphqlOperation } from "aws-amplify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LeftIcon from "./Assets/Images/left.svg";
import RightIcon from "./Assets/Images/right.svg";
import Tick from "./Assets/Images/tick.svg";
import { publish } from "./events";
import { createCompany, updateCompany } from "./graphql/mutations";
import {
  companiesByName,
  dnbSearchCompany,
  getTenant,
  s3fileLists,
} from "./graphql/queries";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
//stepper-changes
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import Close from "./Assets/Images/close.svg";
import { fetchFileFromS3, formatCurrency } from "./utils/Helper.js";

function VerifyEntityModal(props) {
  const {
    handleDelete,
    companyUniqueID = "",
    onClose,
    onDunsIdSelect,
    companyInfo,
    requestedLimit,
  } = props;
  console.log("requested limit from porps", requestedLimit);

  const [companySearchResults, setCompanySearchResults] = useState([]);

  const [error, setError] = useState(false);
  const [errorTxt, setErrorTxt] = useState("This field is required");
  const location = useLocation();

  function isValid(value) {
    return (
      value !== null &&
      value !== undefined &&
      value !== "" &&
      !Number.isNaN(value) &&
      value !== "NaN" &&
      value !== Infinity
    );
  }

  const initilRequestLimit = () => {
    console.log("inside ");
    if (requestedLimit) {
      const reqLimit = isValid(requestedLimit)
        ? parseFloat(requestedLimit)
        : "";
      console.log("inside initil request limit", reqLimit);
      if (isValid(reqLimit)) {
        return formatCurrency(reqLimit);
      }
    }
    return "";
  };
  console.log("after requ limit", initilRequestLimit());

  const [currentTenant, setCurrentTenant] = useState(null);
  const [loading, setLoading] = useState(false);

  //Parent company values
  const [parentSelectedCompany, setParentSelectedCompany] = useState("");
  const [addCompanyWithoutParent, setAddCompanyWithoutParent] = useState(true);

  const [modalOpen, setModalOpen] = useState(false);
  const [tenantId, setTenantId] = useState();

  const isAdmin = useSelector((state) => state.userInfo.isAdmin);
  const URLTenant = getTenantIdFromURL();
  const currentTenantId = useSelector((state) => state.userInfo.tenantId);
  const tenantEmail = useSelector((state) => {
    const email = state.userInfo.email;
    if (email) {
      const username = email.split("@")[0];
      return username.length > 5 ? `${username.slice(0, 5)}..` : username;
    }
    return null;
  });

  const selectTenantId = () => {
    if (isAdmin) setTenantId(URLTenant);
    else setTenantId(currentTenantId);
  };

  const [showLeftButton, setShowLeftButton] = useState(false);
  const [creditLimits, setCreditLimits] = useState({});

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#E9E9E9",
    zIndex: 1,
    color: "#fff",
    width: 45,
    height: 45,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 3px 6px #00000029",
    ...(ownerState.active && {
      backgroundColor: "#2F3D63",
      boxShadow: "0px 3px 6px #00000029",
      border: "2px solid #5186EC",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#2F3D63",
      boxShadow: "0px 3px 6px #00000029",
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className, icon } = props;

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}>
        {completed ? (
          <img src={Tick} width={25} alt='' />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <span
              style={{
                color: active ? "#fff" : "#777A82",
                fontSize: "18px",
                fontWeight: "500",
              }}>
              {icon}
            </span>
          </div>
        )}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  const listRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);

  const handleScroll = () => {
    if (listRef.current) {
      const newScrollLeft = listRef.current.scrollLeft;
      setShowLeftButton(newScrollLeft > 0);
      setIsScrolling(false);
    }
  };

  const scrollListLeft = useCallback(() => {
    if (listRef.current) {
      const scrollAmount = 705;
      const newScrollLeft = Math.max(
        0,
        listRef.current.scrollLeft - scrollAmount
      );
      listRef.current.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });

      setIsScrolling(true);
      listRef.current.addEventListener("scroll", handleScroll);
    }
  }, [listRef]);

  const scrollListRight = useCallback(() => {
    if (listRef.current) {
      const scrollAmount = 705;
      listRef.current.scrollTo({
        left: listRef.current.scrollLeft + scrollAmount,
        behavior: "smooth",
      });
      listRef.current.addEventListener("scroll", handleScroll);
    }
  }, [listRef]);

  useEffect(() => {
    return () => {
      if (listRef.current) {
        listRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [listRef]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "833px",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
  };

  const modalStyle = {
    backdropFilter: "blur(2px)",
    backgroundColor: "rgb(255, 255, 255, 0.5)",
  };

  const companyListStyle = {
    //  height:"263px",
    minWidth: "225px",
    maxWidth: "225px",
    border: "1px solid #E9E9E9",
    boxShadow: "0px 1px 3px #00000029",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    // margin:"5px",
  };

  const handleAddCompany = async (event) => {
    await handleAddCompanyClick();
    handleClose();
    if (props.open) return;
  };

  useEffect(() => {
    async function run() {
      await getCurrentTenant();
    }
    run();
    selectTenantId();
  }, [tenantId]);

  const handleAddCompanyClick = async () => {
    if (props?.open) {
      companyAdded();
      handleOptionsModalClose();
      onDunsIdSelect(selectedCompanies[0]?.organization?.duns);
      return;
    }
    let operation = "";
    if (parentSelectedCompany === null) {
      setAddCompanyWithoutParent(true);
    }
    if (selectedCompanies?.length === 0) {
      toast.error("No Company Selected");
      setError(true);
      return;
    }
    //dont allow multiple select option
    for (let selectedCompany of selectedCompanies) {
      const companyName = selectedCompany.organization
        ? selectedCompany.organization.primaryName
        : selectedCompany?.primary_name.data;
      const companyDuns = selectedCompany.organization
        ? selectedCompany.organization.duns
        : null;
      const companyTicker = selectedCompany.organization
        ? selectedCompany?.organization?.tickerSymbol
        : selectedCompany?.ticker.data;

      let result = await API.graphql(
        graphqlOperation(companiesByName, {
          name: companyName,
          limit: 1,
          filter: {
            tenantId: {
              eq: tenantId,
            },
          },
        })
      );
      let company = null;
      console.log("testing value of requested limit", initilRequestLimit());
      if (
        result.data.companiesByName &&
        result.data.companiesByName.items.length > 0
      ) {
        company = result.data.companiesByName.items[0];
        await API.graphql(
          graphqlOperation(updateCompany, {
            input: {
              tenantId: tenantId,
              id: company.id,
              dnbCompanyId: companyDuns,
              view: 1,
              ticker: companyTicker || "",
              requestedCreditLimit: parseFloat(initilRequestLimit()),
              parentCompanyName:
                parentSelectedCompany?.organization?.primaryName || "",
              parentDnbCompanyId:
                parentSelectedCompany?.organization?.duns || "",
              parentCountry:
                parentSelectedCompany?.organization?.primaryAddress
                  ?.addressCountry?.name || "",
              parentTicker:
                parentSelectedCompany?.organization?.tickerSymbol || "",
            },
          })
        );
        operation = "update";
      } else {
        result = await API.graphql(
          graphqlOperation(createCompany, {
            input: {
              tenantId: tenantId,
              name: companyName,
              dnbCompanyId: companyDuns,
              country:
                selectedCompany?.organization?.primaryAddress?.addressCountry
                  ?.name || "",
              requestedCreditLimit: parseFloat(initilRequestLimit()),
              view: 1,
              ticker: companyTicker || "",
              parentCompanyName:
                parentSelectedCompany?.organization?.primaryName || "",
              parentDnbCompanyId:
                parentSelectedCompany?.organization?.duns || "",
              parentCountry:
                parentSelectedCompany?.organization?.primaryAddress
                  ?.addressCountry?.name || "",
              parentTicker:
                parentSelectedCompany?.organization?.tickerSymbol || "",
            },
          })
        );
        company = result.data.createCompany;
        operation = "create";
      }

      if (!company) {
        setError(true);
        setErrorTxt(`The company: (${companyName}) already exists`);
        return;
      }

      handleOptionsModalClose();
      handleDelete();
      if (operation === "create") {
        toast.success(
          `${company?.name} Added Successfully and ${
            companyInfo?.name || "selected company"
          } will be removed`,
          {
            bodyStyle: {
              fontWeight: "bold",
            },
          }
        );
      } else if (operation === "update") {
        toast.success(
          `${company?.name} Updated Successfully  and ${
            companyInfo?.name || "selected company"
          } will be removed`,
          {
            bodyStyle: {
              fontWeight: "bold",
            },
          }
        );
      }
      publish("create_company", company);
      companyAdded();
    }

    setParentSelectedCompany(null);
    setSelectedCompanies([]);
  };

  const companyAdded = () => {
    setError(false);
  };

  const handleClose = async () => {
    setError(false);

    publish("create_company", {});
    setSelectedCompanies([]);
  };

  const getCurrentTenant = async () => {
    if (tenantId) {
      try {
        const response = await API.graphql(
          graphqlOperation(getTenant, {
            id: tenantId,
          })
        );
        console.log("Current Tenant -> ", response.data?.getTenant);
        setCurrentTenant(response?.data?.getTenant);
      } catch (error) {
        console.error("Error fetching tenant:", error);
      }
    }
  };

  const getRelatedCompany = async (rawData) => {
    const companyName = companyInfo?.name;
    const companyDnbId = companyInfo?.dnbCompanyId;

    console.log("company name -->", companyInfo);

    function filterFilesByFuzzyMatch(files, companyName) {
      const normalizedCompanyName = companyName.toLowerCase();
      const companyWords = normalizedCompanyName.split(" ");
      const extractedNames = [];
      const filteredFiles = files?.filter((file) => {
        const match = file.match(/search-([^/]+)-[a-f0-9]{32}\.json/); // Extract company name from file
        const fileCompanyName = match ? match[1].trim().toLowerCase() : null;

        if (fileCompanyName) {
          extractedNames.push(fileCompanyName);

          const fileWords = fileCompanyName.split(" ");

          // Check if any word in the file matches a word in the company name
          return fileWords.some((fileWord) => {
            return companyWords.some((companyWord) => {
              return (
                fileWord.length >= 3 && // Ensure fileWord is at least 4 characters
                fileWord.length <= companyWord.length && // Ensure fileWord is not longer than companyWord
                companyWord.startsWith(fileWord) // Ensure fileWord matches the start of companyWord
              );
            });
          });
        }
        return false;
      });

      return { filteredFiles, extractedNames };
    }

    const { filteredFiles } = filterFilesByFuzzyMatch(rawData, companyName);

    console.log("filterred arrays", filteredFiles);

    if (filteredFiles?.length === 0) {
      let result = await API.graphql(
        graphqlOperation(dnbSearchCompany, {
          name: companyName,
          tenantId: tenantId,
          isPaid: currentTenant?.isPaid,
        })
      );

      if (result?.data?.dnbSearchCompany) {
        const responseBody = JSON.parse(result.data.dnbSearchCompany);
        const searchResults = responseBody.body?.searchCandidates;
        if (searchResults === undefined) {
          setCompanySearchResults([]);
        } else {
          setCompanySearchResults(searchResults);
        }
      } else {
        setCompanySearchResults([]);
      }
    } else {
      const result = await processFiles(filteredFiles, companyDnbId);
      console.log("out put of process email", result);
      if (result.foundCompany) {
        let { searchCandidates, comp } = result;
        const finalResult = [
          comp,
          ...searchCandidates.filter(
            (org) => org.organization.duns !== companyDnbId
          ),
        ];

        console.log("Final Result (with matching company first):", finalResult);
        setCompanySearchResults(finalResult);
      } else {
        console.log(
          "Aggregated List (No Matching Company):",
          result.searchCandidates
        );
        let finalResult = [];
        //handle empty case
        if (
          result?.searchCandidates?.length > 10 &&
          result?.eachFilteredList > 10
        ) {
          finalResult = result.searchCandidates;
        } else {
          finalResult = result?.searchCandidates || [];
        }

        setCompanySearchResults(finalResult);
      }
    }
  };

  async function processFiles(filteredFiles, companyDnbId, companyInfo) {
    let dunsSet = new Set();
    let eachFileList = []; // Aggregated list of all non-matching companies
    let eachFilteredList = []; // List of companies with matching names
    const companyName = companyInfo?.name || ""; // Safe check for companyInfo
    const normalizedCompanyName = companyName.toLowerCase(); // Normalize company name to lowercase
    const companyWords = normalizedCompanyName.split(" "); // Split company name into words

    for (const fileName of filteredFiles) {
      try {
        console.log("Key or file name:", fileName);

        const existingJson = await fetchFileFromS3(fileName); // Assuming this is an async function
        console.log("existing json:", existingJson);

        if (existingJson?.length > 0) {
          const rawData = existingJson[0].response;
          const data =
            typeof rawData === "string" ? JSON.parse(rawData) : rawData;

          const searchCandidates = data?.searchCandidates || [];
          console.log("search candidates", searchCandidates);

          if (searchCandidates?.length > 0) {
            for (const comp of searchCandidates) {
              const orgId = comp.organization.duns;

              if (!dunsSet.has(orgId)) {
                if (orgId === companyDnbId) {
                  // Found matching company, return immediately
                  console.log("Found the matching company", {
                    foundCompany: true,
                    searchCandidates,
                    comp,
                  });
                  return { foundCompany: true, searchCandidates, comp };
                } else {
                  const primaryName = comp?.primaryName || "";
                  const searchNames = primaryName.split(" ");

                  // Check if any word in company name starts with the respective word in file name
                  const boolval = searchNames.some((fileWord) => {
                    return companyWords.some((companyWord) => {
                      return companyWord.startsWith(fileWord);
                    });
                  });

                  if (boolval) {
                    eachFilteredList.push(comp);
                  }

                  dunsSet.add(orgId);
                  eachFileList.push(comp);
                }
              }
            }
          }
        }
      } catch (error) {
        console.error(`Error processing file ${fileName}:`, error);
      }
    }

    console.log("Aggregated results", eachFileList);
    return {
      foundCompany: false,
      searchCandidates: eachFileList,
      eachFilteredList,
    };
  }

  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const handleCheckboxChange = (company) => {
    if (
      selectedCompanies.some(
        (selected) => selected.organization.duns === company.organization.duns
      )
    ) {
      setSelectedCompanies(
        selectedCompanies.filter(
          (selected) => selected.organization.duns !== company.organization.duns
        )
      );

      const { [company.organization.duns]: _, ...updatedCreditLimits } =
        creditLimits;
      setCreditLimits(updatedCreditLimits);
    } else {
      setSelectedCompanies([...selectedCompanies, company]);

      setCreditLimits((prev) => ({
        ...prev,
        [company.organization.duns]: "",
      }));
    }
  };

  const handleOptionsModalOpen = async () => {
    const prefix = "public/dnb-search-companies/";
    try {
      setLoading(true);
      const response = await API.graphql(
        graphqlOperation(s3fileLists, { prefix })
      );

      const result = JSON.parse(response.data.s3fileLists);
      const body = JSON.parse(result?.body);
      console.log("result of body", body?.files);
      setModalOpen(true);
      if (body?.files) {
        await getRelatedCompany(body?.files);
      }
      setLoading(false)
      return [];
    } catch (error) {
      setLoading(false);
      console.error("Error fetching S3 files:", error);
      return [];
    }
  };

  const handleOptionsModalClose = () => {
    setSelectedCompanies([]);
    setModalOpen(false);
    setShowLeftButton(false);
    if (props.open) onClose();
  };

  return (
    <React.Fragment>
      <div>
        <Button
          onClick={handleOptionsModalOpen}
          style={{
            color: loading ? "grey" : "#5186EC",
            width: "104px",
            height: "17px",
            fontSize: "14px",
            textTransform: "none",
            fontFamily: "Rubik, sans-serif",
            fontWeight: "250",
            display: "flex",
            alignItems: "center",
          }}
          disabled={loading}
          sx={{
            display: isAdmin && location.pathname === "/" ? "none" : "inline",
          }}>
          Verify Entity
        </Button>
        <Modal
          open={modalOpen || props.open}
          style={modalStyle}
          onClose={handleOptionsModalClose}>
          <div style={{ color: "#2F3D63" }}>
            <Box sx={style}>
              <button
                style={{ position: "absolute", top: "20px", left: "30px" }}
                onClick={handleOptionsModalClose}>
                <img
                  style={{ width: "23px", height: "23px" }}
                  src={Close}
                  alt=''
                />
              </button>
              <Typography
                id='modal-modal-title'
                variant='h6'
                component='h2'
                sx={{
                  textAlign: "center",
                  padding: 2,
                  color: "#2F3D63",
                }}>
                Verify Entity
              </Typography>

              <div style={{ display: "flex", justifyContent: "center" }}>
                {loading ? (
                  <div
                    style={{
                      padding: "20px",
                      paddingTop: "30px",
                      paddingBottom: "20px",
                    }}>
                    <CircularProgress size={24} />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "8px",
                    }}>
                    <Typography variant='h6' sx={{ marginTop: "10px" }}>
                      Search Results
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      color='textSecondary'
                      sx={{ fontSize: "12px" }}>
                      {companySearchResults?.length} companies found
                    </Typography>
                    <div style={{ position: "relative" }}>
                      <div
                        style={{
                          maxWidth: "705px",
                          overflow: "hidden",
                          display: "flex",
                          gap: "10px",
                          width: "1920px",
                        }}
                        ref={listRef}>
                        {companySearchResults?.map((company, index) => {
                          let showVerifiedCompany =
                            companyUniqueID &&
                            company.organization.duns === companyUniqueID;
                          return (
                            <div
                              style={{
                                ...companyListStyle,
                                border: showVerifiedCompany
                                  ? "2px solid #101049"
                                  : "1px solid #E9E9E9",
                              }}
                              key={index}>
                              <ListItemButton
                                style={{
                                  border: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  width: "225px",
                                }}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}>
                                  {!showVerifiedCompany && (
                                    <input
                                      type='checkbox'
                                      style={{
                                        marginLeft: 0,
                                        marginRight: "8px",
                                        border: "1px solid #E9E9E9",
                                      }}
                                      checked={selectedCompanies.some(
                                        (selected) =>
                                          selected.organization.duns ===
                                          company.organization.duns
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(company)
                                      }
                                    />
                                  )}

                                  <ListItemText
                                    primaryTypographyProps={{
                                      fontSize: 14,
                                      fontWeight: "bold",
                                    }}
                                    primary={company.organization.primaryName}
                                  />
                                </div>
                                <div>
                                  {company?.organization?.tickerSymbol && (
                                    <ListItemText
                                      secondaryTypographyProps={{
                                        fontSize: 12,
                                        color: "#1A2A56",
                                      }}
                                      secondary={`Ticker - ${company?.organization?.tickerSymbol}`}
                                    />
                                  )}
                                  <ListItemText
                                    secondaryTypographyProps={{
                                      fontSize: 12,
                                      color: "#1A2A56",
                                    }}
                                    secondary={`Street - ${company?.organization?.primaryAddress.streetAddress?.line1}`}
                                  />
                                  {company?.organization?.primaryAddress
                                    .addressLocality?.name && (
                                    <ListItemText
                                      secondaryTypographyProps={{
                                        fontSize: 12,
                                        color: "#1A2A56",
                                      }}
                                      secondary={`City - ${company?.organization?.primaryAddress.addressLocality?.name}`}
                                    />
                                  )}
                                  {company?.organization?.primaryAddress
                                    .addressRegion?.name && (
                                    <ListItemText
                                      secondaryTypographyProps={{
                                        fontSize: 12,
                                        color: "#1A2A56",
                                      }}
                                      secondary={`State - ${company?.organization?.primaryAddress.addressRegion?.name}`}
                                    />
                                  )}
                                  <ListItemText
                                    secondaryTypographyProps={{
                                      fontSize: 12,
                                      color: "#1A2A56",
                                    }}
                                    secondary={`Country - ${company?.organization?.primaryAddress?.addressCountry?.name}`}
                                  />
                                  {company?.organization?.primaryAddress
                                    ?.postalCode && (
                                    <ListItemText
                                      secondaryTypographyProps={{
                                        fontSize: 12,
                                        color: "#1A2A56",
                                      }}
                                      secondary={`Zip Code - ${company?.organization?.primaryAddress?.postalCode}`}
                                    />
                                  )}
                                </div>
                              </ListItemButton>
                            </div>
                          );
                        })}
                      </div>
                      {showLeftButton ? (
                        <button
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "-35px",
                            transform: "translateY(-50%)",
                            zIndex: 1,
                          }}
                          onClick={scrollListLeft}>
                          <img src={LeftIcon} alt='' />
                        </button>
                      ) : null}

                      {companySearchResults?.length > 3 ? (
                        <button
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "-30px",
                            transform: "translateY(-50%)",
                            zIndex: 1,
                          }}
                          onClick={scrollListRight}>
                          <img src={RightIcon} alt='' />
                        </button>
                      ) : null}
                    </div>
                    <div
                      style={{ margin: "20px 0 30px 0", textAlign: "center" }}>
                      <Button
                        variant='contained'
                        sx={{
                          width: "200px",
                          height: "36px",
                          marginLeft: "10px",
                          // border: "1px solid #5186EC",
                          borderRadius: "8px",
                          backgroundColor: "#5186EC",
                          "&:disabled": {
                            backgroundColor: "#f0f0f0",
                          },
                          "&:hover": {
                            backgroundColor: "#5186EC", // Same color on hover
                          },
                        }}
                        disabled={false}
                        onClick={handleAddCompany}>
                        Add and Complete
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </Box>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default VerifyEntityModal;
