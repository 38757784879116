import React, { useState } from "react";
import { Typography, Switch, Box, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Button from "@mui/material/Button";
import {
  createDashboardPreferences,
  updateDashboardPreferences,
} from "./graphql/mutations";
import { getTenant } from "./graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { getDashboardPreferences } from "./graphql/queries";
import { useEffect } from "react";
import { toast } from "react-toastify";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
import { useSelector } from "react-redux";
import axios from "axios";
import AWS from 'aws-sdk'
import { useARSetting } from "./context/arSettingsContext";
import { accessToken } from "./authUtils";


const { createMimeMessage } = require('mimetext')
const { SESClient, SendRawEmailCommand } = require('@aws-sdk/client-ses');

export default function SettingsPage({openOthers}) {
    const [loading, setLoading] = useState(true);
    const [currentCounter, setCurrentCounter] = useState(0);
    const [tenantId,setTenantId] = useState()
    const isAdmin = useSelector(state => state.userInfo.isAdmin);
    const URLTenant = getTenantIdFromURL();
    const currentTenant  = useSelector(state => state.userInfo.tenantId);
    const [open, setOpen] = useState(false);
    const selectTenantId =()=>{
      if(isAdmin) setTenantId(URLTenant)
      else setTenantId(currentTenant)
    }

    const [user, setUser] = useState(null);
    const handleClose = () => {
      setOpen(false);
    };

    useEffect(() => {
      async function fetchUser() {
        try {
          const subId = localStorage.getItem("subId");
          const response = await axios.get(
            `https://dev-g8ufszuhs6pup6zf.us.auth0.com/api/v2/users/${encodeURIComponent(subId)}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          getCurrentTenant();

          console.log('User data fetched successfully');
          setLoading(false);
          setUser(response.data);
        } catch (error) {
          console.log("Error fetching user details:", error);
          setUser(null);
        }
      }
      fetchUser();
      if(openOthers)setIsExpandedOtherServices(true)
    }, [tenantId]);

    const getCurrentTenant = async () => {
      if(tenantId) {
        try {
          const response = await API.graphql(
            graphqlOperation(getTenant, {
              id: tenantId,
            })
          );
          console.log(response?.data?.getTenant);
          setCurrentCounter(response?.data?.getTenant.counter);
        } catch (error) {
          console.error("Error fetching tenant:", error);
        }
      }
    };

    useEffect(() => {
      selectTenantId();
      
      const checkAndFetchPreferences = async () => {
        try {
          const resultBar = await API.graphql(
            graphqlOperation(getDashboardPreferences, { 
              tenantId, 
              feature: "Notification Preferences" 
            })
          );
          console.log("Bar Notification Preferences", resultBar.data.getDashboardPreferences);
          if (resultBar.data.getDashboardPreferences) {
            const jsonStringBar = resultBar.data.getDashboardPreferences.preferences;
            const jsonDataBar = JSON.parse(jsonStringBar);
            setNotesBar(jsonDataBar.Complete);
            setUpdatesBar(jsonDataBar.Update);
            setStatusBar(jsonDataBar["Status Change"]);
            setFinancialReportBar(jsonDataBar["Financial Report"])
          } else {
            setNotesBar(true);
            setUpdatesBar(true);
            setStatusBar(true);
            setFinancialReportBar(true)
            updateNotificationPreferences(true, true, true, true);
          }
          const resultEmail = await API.graphql(
            graphqlOperation(getDashboardPreferences, { 
              tenantId, 
              feature: "Email Notification Preferences" 
            })
          );
      
          console.log("Email Notification Preferences", JSON.parse(resultEmail.data.getDashboardPreferences.preferences));
      
          if (resultEmail.data.getDashboardPreferences) {
            const jsonStringEmail = resultEmail.data.getDashboardPreferences.preferences;
            const jsonDataEmail = JSON.parse(jsonStringEmail);
      
            setNotesEmail(jsonDataEmail.Complete);
            setUpdatesEmail(jsonDataEmail.Update);
            setStatusEmail(jsonDataEmail["Status Change"]);
            setFinancialReportEmail(jsonDataEmail["Financial Report"])
          } else {
            setNotesEmail(false);
            setUpdatesEmail(false);
            setStatusEmail(true);
            setFinancialReportEmail(true)
      
            updateEmailNotificationPreferences(false, false, true, true);
          }
        } catch (error) {
          console.error("Error checking and fetching preferences:", error);
        }
      };

      if (tenantId) {
        checkAndFetchPreferences();
      }
    }, [tenantId, isAdmin, URLTenant, currentTenant]); 

    const updateNotificationPreferences = async (notesBar, updatesBar, statusBar, financialReportBar) => {
      const preferences = {
        "Complete": notesBar,
        "Update": updatesBar,
        "Status Change": statusBar,
        "Financial Report": financialReportBar
      };
    
      const jsonString = JSON.stringify(preferences);
      
      try {
        const result = await API.graphql(
          graphqlOperation(getDashboardPreferences, {
            tenantId: tenantId,
            feature: "Notification Preferences",
          })
        );
    
        if (result.data.getDashboardPreferences) {
          // Update existing preferences
          await API.graphql(
            graphqlOperation(updateDashboardPreferences, {
              input: {
                tenantId: tenantId,
                feature: "Notification Preferences",
                preferences: jsonString,
              },
            })
          );
        } else {
          // Create new preferences
          await API.graphql(
            graphqlOperation(createDashboardPreferences, {
              input: {
                tenantId: tenantId,
                feature: "Notification Preferences",
                preferences: jsonString,
              },
            })
          );
        }
    
        console.log("Notification preferences updated successfully");
      } catch (error) {
        console.error("Error updating notification preferences:", error);
      }
    };
    const updateEmailNotificationPreferences = async (notesBar, updatesBar, statusBar, financialReportBar) => {
      const emailPreferences = {
        "Complete": notesBar,
        "Update": updatesBar,
        "Status Change": statusBar,
        "Financial Report": financialReportBar
      };
    
      const jsonString = JSON.stringify(emailPreferences);
    
      try {
        const result = await API.graphql(
          graphqlOperation(getDashboardPreferences, {
            tenantId: tenantId,
            feature: "Email Notification Preferences", 
          })
        );
    
        if (result.data.getDashboardPreferences) {
          await API.graphql(
            graphqlOperation(updateDashboardPreferences, {
              input: {
                tenantId: tenantId,
                feature: "Email Notification Preferences", 
                preferences: jsonString,
              },
            })
          );
        } else {
          await API.graphql(
            graphqlOperation(createDashboardPreferences, {
              input: {
                tenantId: tenantId,
                feature: "Email Notification Preferences", 
                preferences: jsonString,
              },
            })
          );
        }
    
        console.log("Email notification preferences updated successfully");
      } catch (error) {
        console.error("Error updating email notification preferences:", error);
      }
    };
    

  const handleAddPreferenceClick = async (jsonString) => {
    console.log("tnt in settings",tenantId)
    
    let result = await API.graphql(
      graphqlOperation(getDashboardPreferences, {
        tenantId: tenantId,
        feature: "dashboard",
        limit: 1,
        filter: {
          tenantId: {
            eq: tenantId,
          },
        },
      })
    );
    if (result.data.getDashboardPreferences) {
      //update   
      result = await API.graphql(
        graphqlOperation(updateDashboardPreferences, {
          input: {
            tenantId: tenantId,
            feature: "dashboard",
            preferences: jsonString,
          },
        })
      );
    } else {
      //create
      result = await API.graphql(
        graphqlOperation(createDashboardPreferences, {
          input: {
            tenantId: tenantId,
            feature: "dashboard",
            preferences: jsonString,
          },
        })
      );
    }
  };


  const handleChange = (row) => {
    console.log(row);
    const updatedRows = rows.map((r) => {
      if (r.id === row.id) {
        return { ...r, show: !r.show };
      }
      return r;
    });
    setRows(updatedRows);
  };

  const OriginalRows = [
    { id: "1", name: "Collection Table", show: true },
    { id: "2", name: "Heat Map: Collection Table: Total Sum", show: true },
    { id: "3", name: "Heat Map: Collection Table: Customers", show: true },
    { id: "4", name: "10 Most Risky Customers", show: true },
    { id: "5", name: "Credit Limit", show: true },
    { id: "6", name: "Chart - Credit Limit", show: true },
    { id: "7", name: "Account Receivable", show: true },
    { id: "8", name: "Chart - Amount Receivable", show: true },
    { id: "9", name: "Heat Map : Amount Receivable : Total Sum", show: true },
    { id: "10", name: "Heat Map : Amount Receivable : Customers", show: true },
    { id: "11", name: "Summary & Recommendations", show: true },
    { id: "12", name: "Collection Table - II", show: true },
    { id: "13", name: "Collection At Risk", show: true },
  ];

  const [rows, setRows] = React.useState(OriginalRows);
  
  const columns = [
    { field: "name", headerName: "Table name", width: 400 },
    {
      field: "show",
      headerName: "Show",
      isEditable: true,
      width: 100,
      renderCell: (params) => (
        <Switch
          onClick={() => handleChange(params.row)}
          checked={params.row.show}
        />
      ),
    },
  ];

  function updateRowPosition(initialIndex, newIndex, rows) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const rowsClone = [...rows];
        const row = rowsClone.splice(initialIndex, 1)[0];
        rowsClone.splice(newIndex, 0, row);
        resolve(rowsClone);
      }, Math.random() * 500 + 100); // simulate network latency
    });
  }

  // let initialLoadingState = false;
  // // const [loading, setLoading] = React.useState(initialLoadingState);
  // React.useEffect(() => {
  //   setLoading(initialLoadingState);
  // }, [initialLoadingState]);

  const HandleRowOrderChange = async (params) => {
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows
    );
    setRows(newRows);
    setLoading(false);
  };

  const handleSave = () => {
    const jsonData = rows;
    const jsonString = JSON.stringify(jsonData);
    try {
      handleAddPreferenceClick(jsonString);
      toast.success("Preferences saved successfully!");
    } catch (error) {
      console.log(error);
    }
  };


  //// Anurag 


  const [isExpandedAccountInfo, setIsExpandedAccountInfo] = useState(false);
  const [isExpandedNotifications, setIsExpandedNotifications] = useState(false);
  const [isExpandedOtherServices, setIsExpandedOtherServices] = useState(false);

  const toggleExpandAccountInfo = () => setIsExpandedAccountInfo(!isExpandedAccountInfo);
  const toggleExpandNotofications = () => setIsExpandedNotifications(!isExpandedNotifications);
  const toggleOtherServices = () => setIsExpandedOtherServices(!isExpandedOtherServices);

  const [isVisibleCurrPass, setIsVisibleCurrPass] = useState(false);
  const [isVisibleNewPass, setIsVisibleNewPass] = useState(false);
  const [isVisibleConfirmPass, setIsVisibleConfirmPass] = useState(false);
  const [isVisibleEnterPass, setIsVisibleEnterPass] = useState(false);

  const toggleMaskCurrPass = () => setIsVisibleCurrPass(!isVisibleCurrPass);
  const toggleMaskNewPass = () => setIsVisibleNewPass(!isVisibleNewPass);
  const toggleMaskConfirmPass = () => setIsVisibleConfirmPass(!isVisibleConfirmPass);
  const toggleMaskEnterPass = () => setIsVisibleEnterPass(!isVisibleEnterPass);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isNotesBar, setNotesBar] = useState(true);
  const [isNotesEmail, setNotesEmail] = useState(false);
  const [isNotesPush, setNotesPush] = useState(true);

  const handleNotesBar = () => {
    setNotesBar((prevNotesBar) => {
      const newNotesBar = !prevNotesBar;
      updateNotificationPreferences(newNotesBar, isUpdatesBar, isStatusBar, isFinancialReportBar);
      return newNotesBar;
    });
  };
  const handleNotesEmail = () => {
    setNotesEmail((prevNotesEmail) => {
      const newNotesEmail = !prevNotesEmail;
      updateEmailNotificationPreferences(newNotesEmail, isUpdatesEmail, isStatusEmail, isFinancialReportEmail);
      return newNotesEmail;
    });
  };
  const handleNotesPush = () => setNotesPush(!isNotesPush);

  const [isUpdatesBar, setUpdatesBar] = useState(true);
  const [isUpdatesEmail, setUpdatesEmail] = useState(false);
  const [isUpdatesPush, setUpdatesPush] = useState(true);

  const handleUpdatesBar = () => {
    setUpdatesBar((prevUpdatesBar) => {
      const newUpdatesBar = !prevUpdatesBar;
      updateNotificationPreferences(isNotesBar, newUpdatesBar, isStatusBar, isFinancialReportBar);
      return newUpdatesBar;
    });
  };
  const handleUpdatesEmail = () => {
    setUpdatesEmail((prevUpdatesEmail) => {
      const newUpdatesEmail = !prevUpdatesEmail;
      updateEmailNotificationPreferences(isNotesEmail, newUpdatesEmail, isStatusEmail, isFinancialReportEmail);
      return newUpdatesEmail;
    });
  };
  const handleUpdatesPush = () => setUpdatesPush(!isUpdatesPush);

  const [isStatusBar, setStatusBar] = useState(true);
  const [isStatusEmail, setStatusEmail] = useState(true);
  const [isStatusPush, setStatusPush] = useState(true);

  const handleStatusBar = () => {
    setStatusBar((prevStatusBar) => {
      const newStatusBar = !prevStatusBar;
      updateNotificationPreferences(isNotesBar, isUpdatesBar, newStatusBar, isFinancialReportBar);
      return newStatusBar;
    });
  };
  const handleStatusEmail = () => {
    setStatusEmail((prevStatusEmail) => {
      const newStatusEmail = !prevStatusEmail;
      updateEmailNotificationPreferences(isNotesEmail, isUpdatesEmail, newStatusEmail, isFinancialReportEmail);
      return newStatusEmail;
    });
  };
  const handleStatusPush = () => setStatusPush(!isStatusPush);

  const [isRemainderBar, setRemainderBar] = useState(true);
  const [isRemainderEmail, setRemainderEmail] = useState(true);
  const [isRemainderPush, setRemainderPush] = useState(true);

  const handleRemainderBar = () => setRemainderBar(!isRemainderBar);
  const handleRemainderEmail = () => setRemainderEmail(!isRemainderEmail);
  const handleRemainderPush = () => setRemainderPush(!isRemainderPush);

  const [isExpandedARSettings, setExpandedARSettings] = useState(false);
  const toggleExpandARSettings = () => setExpandedARSettings(!isExpandedARSettings)

  const { arBal30, setArBal30, arBal60, setArBal60, arBal90, setArBal90 } = useARSetting();

  const [isFinancialReportBar, setFinancialReportBar] = useState(true);
  console.log("🚀 ~ isFinancialReportBar:", isFinancialReportBar)
  const [isFinancialReportEmail, setFinancialReportEmail] = useState(true);
  console.log("🚀 ~ isFinancialReportEmail:", isFinancialReportEmail)

  const handleFinancialReportBar = () => {
    setFinancialReportBar((prevFinancialReportBar) => {
      const newFinancialReportBar = !prevFinancialReportBar;
      updateNotificationPreferences(isNotesBar, isUpdatesBar, isStatusBar, newFinancialReportBar);
      return newFinancialReportBar;
    });
  };
  const handleFinancialReportEmail = () => {
    setFinancialReportEmail((prevFinancialReportEmail) => {
      const newFinancialReportEmail = !prevFinancialReportEmail;
      updateEmailNotificationPreferences(isNotesEmail, isUpdatesEmail, isStatusEmail, newFinancialReportEmail);
      return newFinancialReportEmail;
    });
  };
  

  const handleCheckboxARBal30 = (event) => {
    setArBal30(!arBal30);
  };

  const handleCheckboxARBal60 = (event) => {
    setArBal60(!arBal60);
  };

  const handleCheckboxARBal90 = (event) => {
    setArBal90(!arBal90);
  };

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  }

  const handleChangePassword = (event) => {
    setNewPassword(event.target.value);
  }

  const handleNameChange = (event) => {
    setFullName(event.target.value);
  }

  const handleEmailChange = (event) =>{
    setEmail(event.target.value);
  }

  const handlePhoneChange = (event) => {
    setPhoneNumber("" + event.target.value);
  }

  const handleUpdateUser = async () => {
    try {
      const subId = localStorage.getItem("subId");
      const updatedFields = {};
      const passwordUpdate = {};
  
      if (fullName.length !== 0 && fullName !== user.name) {
        updatedFields.name = fullName;
      }
      if (email.length !== 0 && email !== user.email) {
        updatedFields.email = email;
      }
      if (phoneNumber.length !== 0 && phoneNumber !== user.user_metadata.phone_number) {
        updatedFields.user_metadata = {
          phone_number: phoneNumber,
        };
      }
  
      if (newPassword.length !== 0) {
        if (newPassword === confirmPassword) {
          passwordUpdate.password = newPassword;
          passwordUpdate.connection = 'Username-Password-Authentication';
        } else {
          toast.error("Confirm password not same as new password");
          return;
        }
      }
  
      if (Object.keys(updatedFields).length > 0) {
        const response = await axios.patch(
          `https://dev-g8ufszuhs6pup6zf.us.auth0.com/api/v2/users/${encodeURIComponent(subId)}`,
          updatedFields,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
  
        toast.success('User general info updated successfully');
        setUser(response.data);
      } else {
        toast.info('No changes detected in general info, user data not updated.');
      }
  
      // Update password separately after updating general info
      if (Object.keys(passwordUpdate).length > 0) {
        const response = await axios.patch(
          `https://dev-g8ufszuhs6pup6zf.us.auth0.com/api/v2/users/${encodeURIComponent(subId)}`,
          passwordUpdate,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            },
          }
        );
  
        toast.success('Password updated successfully');
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleOnClickEnableModule = async () => {
    setLoading(true);
    AWS.config.update({region: "eu-central-1"});
    const ses = new AWS.SES({apiVersion: '2010-12-01'});   
    const message = `
      User Info for Tenant ${user?.name} :

      Tenant Name : ${user?.name}
      Tenant Id : ${tenantId}
      Tenant Email : ${user?.email}
      Tenant Phone No : ${user?.user_metadata?.phone_number}
      ` 
    const params = {
      Destination: {
        ToAddresses: ['nimrod.lanir@crediarc.com']
      },
      Message: {
        Body: {
          Text: {
            Charset: "UTF-8",
            Data: message,
          }
        },
        Subject: {
          Charset: 'UTF-8',
          Data: 'User requested AR module'
        }
      },
      Source: 'mahekadhaduk007@gmail.com'
    };
    
    ses.sendEmail(params, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        setOpen(true);
        console.log("Email sent successfully:", data);
      }
    });
    setLoading(false)
  }

  const handleOnClickBuyMoreCompanies = async () => {
    setLoading(true)

    AWS.config.update({region: "eu-central-1"});
    const ses = new AWS.SES({apiVersion: '2010-12-01'});   
    const message = `
      User Info for Tenant ${user?.name} :

      Tenant Name : ${user?.name}
      Tenant Id : ${tenantId}
      Tenant Email : ${user?.email}
      Tenant Phone No : ${user?.user_metadata?.phone_number}


      Current Limit For Companies : 10
      Number Of Companies Used : ${10 - currentCounter}/10
      Remaining Balance : ${currentCounter}
      ` 
    const params = {
      Destination: {
        ToAddresses: ['nimrod.lanir@crediarc.com']
      },
      Message: {
        Body: {
          Text: {
            Charset: "UTF-8",
            Data: message,
          }
        },
        Subject: {
          Charset: 'UTF-8',
          Data: 'Request to increase Company Limit'
        }
      },
      Source: 'mahekadhaduk007@gmail.com'
    };
    
    ses.sendEmail(params, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        setOpen(true);
        console.log("Email sent successfully:", data);
      }
    });
    setLoading(false)
  }
  

  return(
    <>
      {loading ? <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Typography variant="h5">Loading User Details...</Typography>
      </Box> : 
      <div className="setting-fields" style={{
        background : '#FAFAFC 0% 0% no-repeat padding-box',
        boxShadow : 'inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029',
        borderRadius : '8px',
        opacity : '1',
        marginLeft : '10px',
        height : '100%',
        marginRight : '25px',
        position : 'relative',
        zIndex : '2'
      }}>
        <h4 className="setting-heading" style={{
          textAlign : 'left',
          font : 'normal normal medium 18px/22px Rubik',
          fontWeight : '500',
          letterSpacing : '0px',
          color : '#1A2A56',
          opacity : '1',
          paddingTop : '30px',
          paddingLeft : '57px',
          paddingBottom : '15px',
        }}>
          Change your profile and account settings
        </h4>
        
        <div style={{
          marginTop : '15px',
          marginBottom : '30px',
          marginLeft : '57px',
          marginRight : '57px',
        }}>
          <div className="collapsible-heading-1" onClick={toggleExpandAccountInfo} style={{
            background : '#5186EC1A 0% 0% no-repeat padding-box',
            boxShadow : 'inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029',
            borderRadius : '8px 8px 0px 0px',
            opacity : '1',
            minHeight: '60px',
            display : 'grid',
            gridTemplateColumns : '4.5fr 4fr 4fr',
            alignContent : 'center',
            cursor:"pointer"
          }}>
            <h4 style={{
              paddingTop : '12px', 
              marginLeft : '22px', 
              paddingBottom : '12px', 
              textAlign : 'left', 
              font : 'normal normal medium 18px/22px Rubik',
              letterSpacing : '0px',
              color : '#1A2A56',
              opacity : '1',
              fontWeight : '450',
              }}>Account-General Info</h4>
            <h4 style={{
              paddingTop : '12px',
              textAlign : 'left', 
              paddingBottom : '12px', 
              font : 'normal normal medium 18px/22px Rubik',
              letterSpacing : '0px',
              color : '#1A2A56',
              opacity : '1',
              fontWeight : '450',
              }}>Password & Security</h4>
            <button style={{ marginLeft : 'auto', marginRight : '20px', marginTop : '8px'}}>
              {isExpandedAccountInfo ? <img src={`${process.env.PUBLIC_URL}/Group 7232.svg`} alt="Expand Less" width="32" height="32" />
 : <img src={`${process.env.PUBLIC_URL}/Group 7231.svg`} alt="Expand More" width="32" height="32" />
}
            </button>
          </div>

          {isExpandedAccountInfo && (<div className="expanded-content-1" style={{
            background: '#FAFAFC',
            opacity: '1',
            boxShadow: 'inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029',
            borderRadius: '0 0 8px 8px',
            display : 'grid',
            gridTemplateColumns : '4.5fr 4fr 4fr',
            gridTemplateRows : '3fr 3fr 3fr 3fr',
            alignContent : 'center',
            paddingBottom : '25px'
          }}>
            <div className="Full-Name" style={{
              marginLeft : '22px', 
              textAlign : 'left', 
              letterSpacing : '0px',
              marginTop : '22px',
              gridRow : '1',
              gridColumn : '1',
              fontSize : '14px',
            }}>
              <h4 style={{
                color : "#777A82",
                fontWeight : '400',
              }}>Full Name</h4>
              <input style={{
                background : '#FFFFFF 0% 0% no-repeat padding-box',
                border : '1px solid #E9E9E9',
                borderRadius : '8px',
                width : '70%',
                height : '30px',
                paddingLeft : '18px',
                font : 'normal normal medium 18px/22px Rubik',
                color : '#2F3D63',
                fontWeight : '450'
              }} defaultValue={user && user.name ? user.name : ''} onChange={handleNameChange}></input>
            </div>

            <div className="Phone-Number" style={{
              marginLeft : '22px', 
              textAlign : 'left', 
              letterSpacing : '0px',
              marginTop : '22px',
              gridRow : '2',
              gridColumn : '1',
              fontSize : '14px',
            }}>
              <h4 style={{
                color : "#777A82",
                fontWeight : '400',
              }}>Phone Number</h4>
              <input style={{
                background : '#FFFFFF 0% 0% no-repeat padding-box',
                border : '1px solid #E9E9E9',
                borderRadius : '8px',
                width : '70%',
                height : '30px',
                paddingLeft : '18px',
                font : 'normal normal medium 18px/22px Rubik',
                color : '#2F3D63',
                fontWeight : '450'
              }} defaultValue={user && user.user_metadata.phone_number ? user.user_metadata.phone_number : ''} onChange={handlePhoneChange} ></input>
            </div>

            <div className="Email" style={{
              marginLeft : '22px', 
              textAlign : 'left', 
              letterSpacing : '0px',
              marginTop : '22px',
              gridRow : '3',
              gridColumn : '1',
              fontSize : '14px',
            }}>
              <h4 style={{
                color : "#777A82",
                fontWeight : '400',
              }}>Email</h4>
              <input style={{
                background : '#FFFFFF 0% 0% no-repeat padding-box',
                border : '1px solid #E9E9E9',
                borderRadius : '8px',
                width : '70%',
                height : '30px',
                paddingLeft : '18px',
                font : 'normal normal medium 18px/22px Rubik',
                color : '#2F3D63',
                fontWeight : '450'
              }} defaultValue={user && user.email ? user.email : ''} onChange={handleEmailChange}></input>
            </div>

            <div className="User-Name" style={{
              marginLeft : '22px', 
              textAlign : 'left', 
              letterSpacing : '0px',
              marginTop : '22px',
              gridRow : '4',
              gridColumn : '1',
              fontSize : '14px',
            }}>
              <h4 style={{
                color : "#777A82",
                fontWeight : '400',
              }}>User Name</h4>
              <input style={{
                background : '#FFFFFF 0% 0% no-repeat padding-box',
                border : '1px solid #E9E9E9',
                borderRadius : '8px',
                width : '70%',
                height : '30px',
                paddingLeft : '18px',
                font : 'normal normal medium 18px/22px Rubik',
                color : '#2F3D63',
                fontWeight : '450',
              }} defaultValue={user && user.email ? user.email : ''} value={user?.email} onChange={handleEmailChange} ></input>
            </div>

            <div className="Curr-Password" style={{
              textAlign : 'left', 
              letterSpacing : '0px',
              marginTop : '22px',
              gridRow : '1',
              gridColumn : '2',
              fontSize : '14px',
            }}>
              <h4 style={{
                color : "#777A82",
                fontWeight : '400',
              }}>Current Password</h4>
              <div>
                <input style={{
                  background : '#FFFFFF 0% 0% no-repeat padding-box',
                  border : '1px solid #E9E9E9',
                  borderRadius : '8px',
                  width : '75%',
                  height : '30px',
                  paddingLeft : '18px',
                  font : 'normal normal medium 18px/22px Rubik',
                  color : '#2F3D63',
                  fontWeight : '450',
                  position : 'relative',
                }} type={isVisibleCurrPass ? 'text' : 'password'} 
                ></input>
                <button
                    onClick={toggleMaskCurrPass}
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                      marginLeft: '5px',
                      position : 'relative',
                      left : '-35px',
                      top : '5px'
                    }}
                >{isVisibleCurrPass ? <img src={`${process.env.PUBLIC_URL}/Group 7277.svg`} alt="Expand Less" width="20" height="20" />
                : <img src={`${process.env.PUBLIC_URL}/Group 7276.svg`} alt="Expand More" width="20" height="20" />}
                </button>
              </div>
            </div>

            <div className="BreakLine-Heading" style={{
              textAlign : 'left', 
              letterSpacing : '0px',
              marginTop : '22px',
              gridRow : '2',
              gridColumn : '2',
              fontSize : '14px',
              paddingTop : '10px'
            }}>
              <hr />
              <h4 style={{
                font : 'normal normal medium 18px/22px Rubik',
                letterSpacing : '0px',
                color : '#1A2A56',
                opacity : '1',
                fontWeight : '450',
                fontSize : '16px',
                paddingTop : '18px'
              }}>Change Password</h4>
            </div>

            <div className="BreakLine-Heading" style={{
              textAlign : 'left', 
              letterSpacing : '0px',
              marginTop : '22px',
              gridRow : '2',
              gridColumn : '3',
              fontSize : '14px',
              marginRight : '30px',
              paddingTop : '10px'
            }}>
              <hr />
            </div>

            <div className="New-Password" style={{
              textAlign : 'left', 
              letterSpacing : '0px',
              marginTop : '22px',
              gridRow : '3',
              gridColumn : '2',
              fontSize : '14px',
            }}>
              <h4 style={{
                color : "#777A82",
                fontWeight : '400',
              }}>Enter New Password</h4>
              <div>
                <input style={{
                  background : '#FFFFFF 0% 0% no-repeat padding-box',
                  border : '1px solid #E9E9E9',
                  borderRadius : '8px',
                  width : '75%',
                  height : '30px',
                  paddingLeft : '18px',
                  font : 'normal normal medium 18px/22px Rubik',
                  color : '#2F3D63',
                  fontWeight : '450',
                  position : 'relative',
                }} type={isVisibleNewPass ? 'text' : 'password'} onChange={handleChangePassword} ></input>
                <button
                    onClick={toggleMaskNewPass}
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                      marginLeft: '5px',
                      position : 'relative',
                      left : '-35px',
                      top : '5px',
                    }}
                >{isVisibleNewPass ? <img src={`${process.env.PUBLIC_URL}/Group 7277.svg`} alt="Expand Less" width="20" height="20" />
                : <img src={`${process.env.PUBLIC_URL}/Group 7276.svg`} alt="Expand More" width="20" height="20" />}
                </button>
              </div>
            </div>

            <div className="Confirm-Password" style={{
              textAlign : 'left', 
              letterSpacing : '0px',
              marginTop : '22px',
              gridRow : '4',
              gridColumn : '2',
              fontSize : '14px',
            }}>
              <h4 style={{
                color : "#777A82",
                fontWeight : '400',
              }}>Confirm New Password</h4>
              <div>
                <input style={{
                  background : '#FFFFFF 0% 0% no-repeat padding-box',
                  border : '1px solid #E9E9E9',
                  borderRadius : '8px',
                  width : '75%',
                  height : '30px',
                  paddingLeft : '18px',
                  font : 'normal normal medium 18px/22px Rubik',
                  color : '#2F3D63',
                  fontWeight : '450',
                  position : 'relative',
                }} type={isVisibleConfirmPass ? 'text' : 'password'} onChange={handleConfirmPassword}></input>
                <button
                  onClick={toggleMaskConfirmPass}
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    marginLeft: '5px',
                    position : 'relative',
                    left : '-35px',
                    top : '5px',
                  }}
              >{isVisibleConfirmPass ? <img src={`${process.env.PUBLIC_URL}/Group 7277.svg`} alt="Expand Less" width="20" height="20" />
              : <img src={`${process.env.PUBLIC_URL}/Group 7276.svg`} alt="Expand More" width="20" height="20" />}
                </button>
              </div>
            </div>

            <div className="Enter-Curr-Password" style={{
              textAlign : 'left', 
              letterSpacing : '0px',
              marginTop : '22px',
              gridRow : '3',
              gridColumn : '3',
              fontSize : '14px',
              marginRight : '10px'
            }}>
              <h4 style={{
                color : "#777A82",
                fontWeight : '400',
              }}>Enter Current Password</h4>
              <div>
                <input style={{
                  background : '#FFFFFF 0% 0% no-repeat padding-box',
                  border : '1px solid #E9E9E9',
                  borderRadius : '8px',
                  width : '75%',
                  height : '30px',
                  paddingLeft : '18px',
                  font : 'normal normal medium 18px/22px Rubik',
                  color : '#2F3D63',
                  fontWeight : '450',
                  position : 'relative',
                }} type={isVisibleEnterPass ? 'text' : 'password'}></input>
                <button
                    onClick={toggleMaskEnterPass}
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                      marginLeft: '5px',
                      position : 'relative',
                      left : '-35px',
                      top : '5px',
                    }}
                >{isVisibleEnterPass ? <img src={`${process.env.PUBLIC_URL}/Group 7277.svg`} alt="Expand Less" width="20" height="20" />
                : <img src={`${process.env.PUBLIC_URL}/Group 7276.svg`} alt="Expand More" width="20" height="20" />}
                </button>
              </div>
            </div>

            <div className="Update-Button" style={{
              textAlign : 'left', 
              letterSpacing : '0px',
              marginTop : '22px',
              gridRow : '4',
              gridColumn : '3',
              fontSize : '14px',
              marginRight : '10px'
            }}>
              <button style={{
                marginLeft : 'auto',
                marginRight : '70px',
                background : '#5186EC 0% 0% no-repeat padding-box',
                borderRadius : '8px',
                display : 'flex',
                height : '40px',
                width : '100px',
                justifyContent : 'center',
                alignItems : 'center',
              }} onClick={handleUpdateUser}>
                <span style={{
                  color : '#FFFFFF'
                }}>
                  Update
                </span>
              </button>
            </div>

          </div>)}
        </div>
        <div style={{
          marginTop : '15px',
          marginBottom : '30px',
          marginLeft : '57px',
          marginRight : '57px',
        }}>
          <div className="collapsible-heading-2" onClick={toggleExpandNotofications} style={{
            background : '#5186EC1A 0% 0% no-repeat padding-box',
            boxShadow : 'inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029',
            borderRadius : '8px 8px 0px 0px',
            opacity : '1',
            minHeight: '60px',
            display : 'grid',
            gridTemplateColumns : '4.5fr auto',
            alignContent : 'center',
            cursor:"pointer"
          }}>
            <h4 style={{
              paddingTop : '12px', 
              paddingBottom : '12px', 
              marginLeft : '22px', 
              textAlign : 'left', 
              font : 'normal normal medium 18px/22px Rubik',
              letterSpacing : '0px',
              color : '#1A2A56',
              opacity : '1',
              fontWeight : '450',
              }}>Notifications</h4>
            <button style={{ marginLeft : 'auto', marginRight : '20px', marginTop : '8px'}}>
              {isExpandedNotifications ? <img src={`${process.env.PUBLIC_URL}/Group 7232.svg`} alt="Expand Less" width="32" height="32" />
 : <img src={`${process.env.PUBLIC_URL}/Group 7231.svg`} alt="Expand More" width="32" height="32" />
}
            </button>
          </div>

          {isExpandedNotifications && (<div className="expanded-content-2" style={{
            background: '#FAFAFC',
            opacity: '1',
            boxShadow: 'inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029',
            borderRadius: '0 0 8px 8px',
            alignContent : 'center',
            paddingBottom : '25px'
          }}>
            <div className="notification-table" style={{
              width : '100%',
              marginTop : '22px',
              marginLeft : '22px',
            }}>
              <table style={{
                width: '60%',
                borderCollapse: 'collapse',
                backgroundColor: '#FAFAFC', 
                marginBottom : '20px',
                marginLeft : '15px',
              }}>
                <thead style={{
                  font : 'normal normal medium 18px/22px Rubik',
                  letterSpacing : '0px',
                  color : '#1A2A56',
                  opacity : '1',
                  fontWeight : '450',
                }}>
                  <tr style={{textAlign : 'center'}}>
                    <th style={{borderBottom: '1px solid #ddd' , paddingTop : '10px'}}></th>
                    <th style={{borderBottom: '1px solid #ddd' , paddingTop : '10px', width : '15%'}}>Bar</th>
                    <th style={{borderBottom: '1px solid #ddd' , paddingTop : '10px', width : '15%'}}>Email</th>
                    {/* <th style={{borderBottom: '1px solid #ddd' , paddingTop : '10px', width : '15%'}}>Push</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr style={{textAlign : 'center'}}>
                    <td style={{borderTop: '1px solid #ddd', borderRight : '1px solid #ddd', paddingTop : '15px'}}>
                      <h5 style={{
                        color : '#1A2A56',
                        fontWeight : '450',
                        textAlign : 'left'
                      }}>
                        Notes
                      </h5>
                      <p style={{
                        color : '#777A82',
                        fontWeight : '400',
                        textAlign : 'left'
                      }}>
                         Notes are notifications that inform users on completed process 
                      </p>
                    </td>
                    <td style={{borderTop: '1px solid #ddd', borderRight : '1px solid #ddd'}}>
                      <button onClick={handleNotesBar} style={{ paddingTop : '30px'}}>
                      {isNotesBar ? <img src={`${process.env.PUBLIC_URL}/Group 7280.svg`} />
        : <img src={`${process.env.PUBLIC_URL}/Group 7284.svg`} />
        }
                    </button></td>
                    <td style={{borderTop: '1px solid #ddd', borderRight : '1px solid #ddd'}}>
                      <button onClick={handleNotesEmail} style={{ paddingTop : '30px'}}>
                      {isNotesEmail ? <img src={`${process.env.PUBLIC_URL}/Group 7280.svg`}  />
        : <img src={`${process.env.PUBLIC_URL}/Group 7284.svg`} />
        }
                    </button>
                    </td>
                    {/* <td style={{borderTop: '1px solid #ddd', borderRight : '1px solid #ddd'}}>
                      <button onClick={handleNotesPush} style={{ paddingTop : '30px'}}>
                      {isNotesPush ? <img src={`${process.env.PUBLIC_URL}/Group 7280.svg`}  />
        : <img src={`${process.env.PUBLIC_URL}/Group 7284.svg`} />
        }
                    </button>
                    </td> */}
                  </tr>
                  <tr style={{textAlign : 'center'}}>
                    <td style={{borderRight : '1px solid #ddd', paddingTop : '15px'}}>
                      <h5 style={{
                        color : '#1A2A56',
                        fontWeight : '450',
                        textAlign : 'left'
                      }}>
                        Updates
                      </h5>
                      <p style={{
                        color : '#777A82',
                        fontWeight : '400',
                        textAlign : 'left'
                      }}>
                        Update notifications include information about new events or other type of information recently added to a company card. 
                      </p>
                    </td>
                    <td style={{borderRight: '1px solid #ddd' }}>
                    <button onClick={handleUpdatesBar} style={{ paddingTop : '30px'}}>
                      {isUpdatesBar ? <img src={`${process.env.PUBLIC_URL}/Group 7280.svg`}  />
        : <img src={`${process.env.PUBLIC_URL}/Group 7284.svg`} />
        }
                    </button>
                    </td>
                    <td style={{borderRight: '1px solid #ddd' }}>
                      <button onClick={handleUpdatesEmail} style={{ paddingTop : '30px'}}>
                      {isUpdatesEmail ? <img src={`${process.env.PUBLIC_URL}/Group 7280.svg`}  />
        : <img src={`${process.env.PUBLIC_URL}/Group 7284.svg`} />
        }
                    </button></td>
                    {/* <td style={{borderRight: '1px solid #ddd' }}>
                      <button onClick={handleUpdatesPush} style={{ paddingTop : '30px'}}>
                      {isUpdatesPush ? <img src={`${process.env.PUBLIC_URL}/Group 7280.svg`}  />
        : <img src={`${process.env.PUBLIC_URL}/Group 7284.svg`} />
        }
                    </button></td> */}
                  </tr>
                  <tr style={{textAlign : 'center'}}>
                    <td style={{borderRight : '1px solid #ddd', paddingTop : '15px'}}>
                      <h5 style={{
                        color : '#1A2A56',
                        fontWeight : '450',
                        textAlign : 'left'
                      }}>
                        Status Changes
                      </h5>
                      <p style={{
                        color : '#777A82',
                        fontWeight : '400',
                        textAlign : 'left'
                      }}>
                        This type of notification will alert you to any significant changes in the company’s scores or credit recommendations. 
                      </p>
                    </td>
                    <td style={{borderRight: '1px solid #ddd'}}>
                      <button onClick={handleStatusBar} style={{ paddingTop : '30px'}}>
                      {isStatusBar ? <img src={`${process.env.PUBLIC_URL}/Group 7280.svg`}  />
        : <img src={`${process.env.PUBLIC_URL}/Group 7284.svg`} />
        }
                    </button></td>
                    <td style={{borderRight: '1px solid #ddd'}}>
                      <button onClick={handleStatusEmail} style={{ paddingTop : '30px'}}>
                      {isStatusEmail ? <img src={`${process.env.PUBLIC_URL}/Group 7280.svg`}  />
        : <img src={`${process.env.PUBLIC_URL}/Group 7284.svg`} />
        }
                    </button></td>
                    {/* <td style={{borderRight: '1px solid #ddd'}}>
                      <button onClick={handleStatusPush} style={{ paddingTop : '30px'}}>
                      {isStatusPush ? <img src={`${process.env.PUBLIC_URL}/Group 7280.svg`}  />
        : <img src={`${process.env.PUBLIC_URL}/Group 7284.svg`} />
        }
                    </button></td> */}
                  </tr>
                  <tr style={{textAlign : 'center'}}>
                    <td style={{borderRight : '1px solid #ddd', paddingTop : '15px'}}>
                      <h5 style={{
                        color : '#1A2A56',
                        fontWeight : '450',
                        textAlign : 'left'
                      }}>
                        Financial Reports
                      </h5>
                      <p style={{
                        color : '#777A82',
                        fontWeight : '400',
                        textAlign : 'left'
                      }}>
                        This type of notification will alert you to new Financial Reports added in the company. 
                      </p>
                    </td>
                    <td style={{borderRight: '1px solid #ddd'}}>
                      <button onClick={handleFinancialReportBar} style={{ paddingTop : '30px'}}>
                      {isFinancialReportBar ? <img src={`${process.env.PUBLIC_URL}/Group 7280.svg`}  />
        : <img src={`${process.env.PUBLIC_URL}/Group 7284.svg`} />
        }
                    </button></td>
                    <td style={{borderRight: '1px solid #ddd'}}>
                      <button onClick={handleFinancialReportEmail} style={{ paddingTop : '30px'}}>
                      {isFinancialReportEmail ? <img src={`${process.env.PUBLIC_URL}/Group 7280.svg`}  />
        : <img src={`${process.env.PUBLIC_URL}/Group 7284.svg`} />
        }
                    </button></td>
                    {/* <td style={{borderRight: '1px solid #ddd'}}>
                      <button onClick={handleStatusPush} style={{ paddingTop : '30px'}}>
                      {isStatusPush ? <img src={`${process.env.PUBLIC_URL}/Group 7280.svg`}  />
        : <img src={`${process.env.PUBLIC_URL}/Group 7284.svg`} />
        }
                    </button></td> */}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>)}
        </div>
 
        <div style={{
          marginTop : '15px',
          marginBottom : '30px',
          marginLeft : '57px',
          marginRight : '57px',
          paddingBottom : '50px'
        }}>
          <div className="collapsible-heading-3" onClick={toggleOtherServices} style={{
            background : '#5186EC1A 0% 0% no-repeat padding-box',
            boxShadow : 'inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029',
            borderRadius : '8px 8px 0px 0px',
            opacity : '1',
            minHeight: '60px',
            display : 'grid',
            gridTemplateColumns : '4.5fr auto',
            alignContent : 'center',
            cursor:"pointer"
          }}>
            <h4 style={{
              paddingTop : '12px', 
              marginLeft : '22px', 
              paddingBottom : '12px', 
              textAlign : 'left', 
              font : 'normal normal medium 18px/22px Rubik',
              letterSpacing : '0px',
              color : '#1A2A56',
              opacity : '1',
              fontWeight : '450',
              }}>Other Services</h4>
            <button style={{ marginLeft : 'auto', marginRight : '20px', marginTop : '8px'}}>
              {isExpandedOtherServices ? <img src={`${process.env.PUBLIC_URL}/Group 7232.svg`} alt="Expand Less" width="32" height="32" />
 : <img src={`${process.env.PUBLIC_URL}/Group 7231.svg`} alt="Expand More" width="32" height="32" />
}
            </button>
          </div>

          {isExpandedOtherServices && (<div className="expanded-content-3" style={{
            background: '#FAFAFC',
            opacity: '1',
            boxShadow: 'inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029',
            borderRadius: '0 0 8px 8px',
            alignContent : 'center',
            paddingBottom : '25px',
            paddingLeft : '22px',
            textAlign : 'left',
          }}>
            <div className="other-services" style={{width : '55%'}}>
              <h4 style={{
                paddingLeft : '13px',
                paddingTop : '22px',
                font : 'normal normal medium 18px/22px Rubik',
                color : '#2F3D63',
                fontWeight : '450',
              }}>
                We have some more stuff for you!
              </h4>

              <h4 style={{
                paddingLeft : '13px',
                paddingTop : '22px',
                font : 'normal normal medium 18px/22px Rubik',
                color : '#2F3D63',
                fontWeight : '450'
              }}>
                AR
              </h4>

              <p style={{
                paddingLeft : '13px',
                color : '#777A82',
                fontWeight : '400',
              }}>
                Enable Account Receivable and Aging monitoring module 
              </p>

              <button style={{
                marginLeft : '13px',
                background : '#5186EC 0% 0% no-repeat padding-box',
                borderRadius : '8px',
                height : '40px',
                width : '125px',
                display : 'flex',
                justifyContent : 'center',
                alignItems : 'center',
                marginBottom : '30px',
                marginTop : '5px'
              }} onClick={handleOnClickEnableModule}>
                <span style={{
                  color : '#FFFFFF',
                  fontSize : '14px'
                }}>
                  Enable AR
                </span>
              </button>

              <h4 style={{
                paddingLeft : '13px',
                paddingTop : '22px',
                font : 'normal normal medium 18px/22px Rubik',
                color : '#2F3D63',
                fontWeight : '450'
              }}>
                Buy more companies
              </h4>

              <p style={{
                paddingLeft : '13px',
                color : '#777A82',
                fontWeight : '400',
              }}>
                Based on your contract, you can add up to <span> {currentCounter} </span> additional companies. <br />
                <span style={{ color: '#777A82'}}>
                  Click here to increase the allowed number of companies.
                </span>
              </p>

              <p style={{
                paddingTop : '14px',
                paddingLeft : '13px',
                color : '#777A82',
                fontWeight : '450',
              }}>Number of companies used 
                <span style={{
                  marginLeft : '13px',
                  fontWeight : '500',
                  fontSize : '17px',
                  color : '#2F3D63'
                }}>
                  {10 - currentCounter}/10
                </span>
              </p>

              <button style={{
                marginLeft : '13px',
                background : '#5186EC 0% 0% no-repeat padding-box',
                borderRadius : '8px',
                height : '40px',
                width : '125px',
                display : 'flex',
                justifyContent : 'center',
                alignItems : 'center',
                marginBottom : '30px',
                marginTop : '5px',
              }} onClick={handleOnClickBuyMoreCompanies}>
                <span style={{
                  color : '#FFFFFF',
                  fontSize : '14px'
                }}>
                  Charge More
                </span>
              </button>

              <a style={{
                paddingLeft : '13px',
                color : '#5186EC',
              }} href = 'mailto:support@crediarc.com'>
                <span style={{textDecoration : 'underline'}}>
                  Contact us for help & more information<span style={{fontSize : '20px'}}>
                  &nbsp;&raquo;
                  </span>
                </span>
              </a>
            </div>
          </div>)}
        </div>
        <div>
        <Dialog
    open={open}
    onClose={handleClose}
    sx={{
      '& .MuiDialog-paper': {
        borderRadius: '8px', 
        padding: '20px', 
        width: '400px', 
        maxWidth: '80%', 
      },
      '& .MuiDialogContent-root': {
        padding: '20px', 
      },
      '& .MuiDialogActions-root': {
        padding: '10px', 
      },
    }}
  >
    <DialogTitle>
      Request Sent
    </DialogTitle>
    <DialogContent>
      <Typography>We have received your request. A CrediArc representative will contact you soon.</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
    </div>
      </div>
      }
    </>
  );

}