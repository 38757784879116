import {
  Button,
  Card,
  LinearProgress,
  linearProgressClasses,
  Typography,
  Menu,
  MenuItem,
  Box,
  CircularProgress,
  Tooltip,
  Divider,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { triggerQuestionsAgent } from "./graphql/queries";
import ReactGA from "react-ga4"
import RequestManualEvaluationDialog from "./RequestManualEvaluationDialog";
import { getTenant, triggerSendEvaluationEmail } from "./graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import AWS from 'aws-sdk';
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { toast } from "react-toastify";
import SaveIcon from "./Assets/Images/saveIcon.svg";
import EditIcon from './Assets/Images/editIcon.svg'
import { updateCompany } from "./graphql/mutations";
import { useSelector } from "react-redux";
import { getTenantInfo } from "./utils/GraphQLHelper/GetTenantInfo";
import SendRequestModal from "./SendRequestModal";
import { fetchCompanySummary, formatCurrency, getCompanyData, MitigationOptions, updateCompanySummary } from "./utils/Helper";

Amplify.configure(awsExports);


function ExecutiveSummary({
  company,
  summary,
  AgingDataForCompany,
  DnbData,
  tenantId,
  setShowExport,
  setCurrentCompany,
  executiveData,
  summaryScores
}) {

  const availableMitigations = Array.isArray(summary?.["Recommended Action"])
  ? MitigationOptions.filter((item) =>
      summary["Recommended Action"].some(
        (action) => action.toLowerCase() === item.key?.toLowerCase()
      )
    )
  : []; 
  const [anchorEl, setAnchorEl] = useState(null);

  const [title, setTitle] = useState("");
  const [comment, setComment] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [specificCreditLimit, setSpecificCreditLimit] = useState("");
  const [loading, setLoading] = useState(true);
  const [companyDnbID, setCompanyDnbID] = React.useState(null);
  const [requestLimitState, setRequestLimitState] = useState(false);
  const [loadingQuestion, setLoadingQuestion] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileType, setFileType] = useState(null);

  const {
    ARCScore,
    legalScore,
    alternativeScore,
    countryScore,
    riskScore,
    recommendedLimit,
    requestedLimit,
    underwritingReason,
    currentArBalance,
    totalArBalance,
    termsRecommended,
    currentRecommended,
    paydex,
    tenure,
    avgCustomerPaymentDays,
    arrear,
    delinquencyScore,
    failureScore,
    sectorScore,
    altmanZScore,
    arBalance30,
    arBalance60,
    arBalance90,
    lastUpdatedDate,
  } = summaryScores;

  const isAdmin = useSelector((state) => state.userInfo.isAdmin);
  const initilRequestLimit = () => {
    if (summary) {
      const requestedLimit = isValid(summary["Requested Limit"]) ?  parseFloat(summary["Requested Limit"]) : "" ;
      if (isValid(requestedLimit)) {
        return formatCurrency(requestedLimit);
      }
    }
    return "";
  };
  const [newRequestLimit, setNewRequestLimit] = useState(() =>
    initilRequestLimit()
  );
  const tenantEmail = useSelector((state) => {
    const email = state.userInfo.email;
    if (email) {
      const username = email.split("@")[0];
      return username.length > 5 ? `${username.slice(0, 5)}..` : username;
    }
    return null;
  });

  const manualSource = company?.manualSource
    ? JSON.parse(company.manualSource)
    : {};

  useEffect(() => {
    setCompanyDnbID(company?.dnbCompanyId)
    console.log("company && summary && DnbData && tenantId",{company , summary , DnbData , tenantId})
    if (company && summary && Object.keys(summary).length > 0  && DnbData && tenantId) {
      const timeoutId = setTimeout(() => {
        setLoading(false);
        setShowExport(true)
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [company, summaryScores, DnbData, tenantId]);


  const handleCloseSR = () => {
    setIsModalOpen(false)};

    
  const handleMenuItemClick = (key) => {
    setFileType(key)
    setIsModalOpen(true);
    handleClose();
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCommentsChange = (event) => {
    setComment(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleSpecificCreditLimitValue = (event) => {
    setSpecificCreditLimit(event.target.value);
  };

  const handleSubmit = async (row) => {
    const companyName = company?.name;
    try {
      let limit;
      if (selectedOption === "Specific Credit Limit") {
        limit = specificCreditLimit;
      } else {
        limit = "Max";
      }

      let result = await getCompanyData(summary, tenantId)
      console.log("Company", result)
      const companyid = result.id;
      let tenant = await API.graphql(
        graphqlOperation(getTenant, {
          id: tenantId,
        })
      );
      console.log("Tenant", tenant)
      const tenantName = tenant.data.getTenant.name;
      const response = await API.graphql(
        graphqlOperation(triggerSendEvaluationEmail, {
          tenantId: tenantId,
          tenantName: tenantName,
          id: companyid,
          companyName: companyName,
          requestedAmount: limit,
          comments: comment,
          fullName: fullName,
          title: title,
          email: email,
          phoneNumber: phoneNumber
        })
      );
      console.log("triggerSendEvaluationEmail Response",response)
      handleCloseDialog();
      toast.success("Evaluation request sent successfully!")
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error("An error occurred while sending the evaluation request. Please try again later.");
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleManualEvaluationClick = (event) => {
    setIsDialogOpen(true);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getColor = (score) => {
    if (isNaN(score)) return "#2F3D63";

    if (score >0 && score < 4) {
      return "#3AA623";
    } else if (score >= 4 && score < 7) {
      return "#E7BF3C";
    } else if (score >= 7) {
      return "#F25A48";
    } else {
      return "#2F3D63"; // default color
    }
  };

  const getImage = (score) => {
    if (score >= 1 && score < 4) {
      return "/greenSmiley.svg";
    } else if (score >= 4 && score < 7) {
      return "/yellowSmiley.svg";
    } else {
      return "/redSmiley.svg";
    }
  };

  const open = Boolean(anchorEl);



  const {
    Description,
    Main_sector,
    Number_of_employees,
    Market_cap,
    Credit_Rating,
    Founders,
    Key_staff,
    Main_location_office,
    Revenue,
    Symbol,
    Founded,
    Parent_company,
    Website,
    Main_competitors,
  } = executiveData;

  function extractNumber(value) {
    if (!value || value.trim() === "") {
      return 0;
    }
    const extractedNumber = value.replace(/[^0-9.]/g, "");
    return parseFloat(extractedNumber) || 0; 
  }

  const uploadJsonToS3 = async () => {
    try {
      const companyDetail = await fetchCompanySummary(company?.tenantId, company?.id)
      companyDetail["Requested Limit"] = extractNumber(newRequestLimit);
      const companySummaryUpdateInput = {
        tenantId: company?.tenantId,
        companyId: company?.id,
        summaryData: JSON.stringify(companyDetail),
      };
      await updateCompanySummary(companySummaryUpdateInput)
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  const evaluateUW = async (name) => {

    ReactGA.event({
      category: isAdmin ? "Admin User" : "User",
      action: isAdmin
        ? `Trigger_QA-${tenantEmail}_admin_${name}`
        : `Trigger_QA-${tenantEmail}_${name}`,
    });

    toast.success(`Trigger Agent started!`, {
      bodyStyle: {
        fontWeight: "bold",
      },
    });

    await API.graphql(
      graphqlOperation(triggerQuestionsAgent, {
        tenantId: company.tenantId,
        id: company.id,
      })
    );
  };

  const updateRequestLimit = async () => {
    let newLimit = extractNumber(newRequestLimit) || 0;
    let oldLimit = extractNumber(requestedLimit) || 0;
    let UWRecommendation = extractNumber(recommendedLimit) || 0;

    if (oldLimit === newLimit) {
      setRequestLimitState(false);
      setNewRequestLimit(() => initilRequestLimit());
      return;
    }
    setLoadingQuestion(true);
    try {
      await API.graphql(
        graphqlOperation(updateCompany, {
          input: {
            tenantId: tenantId,
            id: company.id,
            requestedCreditLimit: newLimit,
          },
        })
      );
      await uploadJsonToS3();
      let requestedLimitTemp = formatCurrency(newLimit);
      setNewRequestLimit(requestedLimitTemp);
      const { name } = await getTenantInfo();
      if (newLimit > UWRecommendation) {
        await evaluateUW(name)
      }
      setCurrentCompany({ ...summary, "Requested Limit": newLimit });
      setRequestLimitState(false);
      setLoadingQuestion(false)
    } catch (error) {
      console.error("Error updating request limit:", error);
      setRequestLimitState(false);
      setLoadingQuestion(false)
    }
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: "12.78px",
    borderRadius: 5,
    width: "199.68px",
    boxShadow: "inset 0px 3px 6px rgba(0, 0, 0, 0.16)",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: getColor(ARCScore),
    },
  }));

  const fields = [
    Description,
    Main_sector,
    Founded,
    Symbol,
    Revenue,
    Market_cap,
    Number_of_employees,
    Credit_Rating,
    Main_location_office,
    Founders,
    Key_staff,
    Parent_company,
    Website,
  ];

  let isAllFieldsInvalid = fields.every(
    (v) => v === undefined || v === null || v === ""
  );


  const predefinedFields = [
    "description",
    "industry",
    "founders",
    "keyPeople",
    "founded",
    "ticker",
    "website",
    "country",
    "city",
    "parentCompany",
    "revenue",
    "marketCap",
    "numberOfEmployees",
    "AQR",
    "netIncome",
    "yearsOfOperation",
    "equity"
  ];

  const infoboxCard = [
    { label: "Description:", value: Description },
    { label: "Main sector:", value: Main_sector },
    { label: "Founded:", value: Founded },
    { label: "Symbol:", value: Symbol },
    { label: "Website:", value: Website },
    { label: "Revenue:", value: Revenue },
    { label: "Market Cap/Evaluation:", value: Market_cap },
    { label: "Number of employees:", value: Number_of_employees },
    { label: "Credit Rating:", value: Credit_Rating },
    { label: "Main location:", value: Main_location_office },
    { label: "Founders:", value: Founders },
    { label: "Key Staff:", value: Key_staff },
    { label: "Parent Company:", value: Parent_company },
  ];

  const additionalFields = Object.keys(manualSource)
  .filter(field => !predefinedFields.includes(field))
  .map(field => ({
    label: `${field}:`,
    value: manualSource[field]
  }))
  .filter(field => field.value !== undefined && field.value !== null && field.value !== '');  // Filter out empty values

const combinedInfoboxCard = [...infoboxCard, ...additionalFields];
  function isValid(value) {
    return (
      value !== null &&
      value !== undefined &&
      value !== "" &&
      !Number.isNaN(value) &&
      value !== "NaN" && 
      value !== Infinity
    );
  }

    const ScoreCard = ({
      risk,
      alternative,
      delinquency,
      legal,
      sector,
      failure,
      country,
      altmanZ,
    }) => {
      const scores = [
        { label: "Risk Segment", value: risk, priority: 1 },
        { label: "Alternative Score", value: alternative, priority: 2 },
        { label: "Delinquency Score", value: delinquency, priority: 3 },
        { label: "Legal Score", value: legal, priority: 4 },
        { label: "Failure Score", value: failure, priority: 5 },
        { label: "Sector Score", value: sector, priority: 6 },
        { label: "Country Score", value: country, priority: 7 },
        { label: "Altman Z Score", value: altmanZ, priority: 8 },
      ];
  
  const availableScores = scores.filter(
    (score) =>
      score.value !== undefined &&
      score.value !== null &&
      !isNaN(score.value)
  );

  const nonAltmanScores = availableScores.filter(
    (score) => score.label !== "Altman Z Score"
  );
  if(nonAltmanScores.length < 4 && isValid(altmanZ)){
    nonAltmanScores.push({ label: "Altman Z Score", value: altmanZ, priority: 7 })
  }
  nonAltmanScores.sort((a, b) => {
    if (b.value === a.value) {
      return a.priority - b.priority;
    }
    return b.value - a.value;
  });

  const displayScores =
  nonAltmanScores.length > 4
      ? nonAltmanScores.slice(0, 4)
      : nonAltmanScores;
      return (
        <div
          style={{
            width: "30%",
            gridTemplateColumns: "repeat(auto-fit, minmax(335px, 1fr))",
          }}
        >
          {displayScores.map((score, index) => (
            <React.Fragment key={index}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitBoxOrient: "horizontal",
                  color: "#777A82",
                  textAlign: "left",
                  fontSize: "14px",
                  paddingTop: "15px",
                }}
              >
                {score.label}:
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "horizontal",
                    fontSize: "14px",
                    fontWeight: "500",
                    width: "fit-content",
                    marginRight: "5px",
                    color: getColor(score.value),
                  }}
                >
                  {score.value}
                </Typography>
                <img
                  src={getImage(score.value)}
                  alt="smiley"
                  height={"12.22px"}
                  width={"10.9px"}
                />
              </div>
            </React.Fragment>
          ))}
        </div>
      );
    }

  return (
    <Box padding={"25px"}>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="335px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <Card
              style={{
                flex: "1",
                minHeight: "335px",
                background:
                  "rgba(250, 250, 252, 1) 0% 0% no-repeat padding-box",
                boxShadow:
                  "inset 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.16)",
                borderRadius: "8px",
                opacity: 1,
                padding: "25px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: "60%",
                    gridTemplateColumns: "repeat(auto-fit, minmax(335px, 1fr))",
                  }}
                >
                  <Typography
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "horizontal",
                      textAlign: "left",
                      fontSize: "16px",
                      color: "#2F3D63",
                      marginRight: "10px",
                      paddingRight: "10px",
                      fontWeight: "500",
                      paddingTop: 4,
                    }}
                  >
                    ARC Score
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitBoxOrient: "horizontal",
                        textAlign: "left",
                        fontSize: "24px",
                        color: getColor(ARCScore),
                        marginRight: "10px",
                        paddingRight: "10px",
                        fontWeight: "500",
                        paddingTop: 4,
                      }}
                    >
                      {ARCScore}
                    </Typography>
                    <img
                      src={getImage(ARCScore)}
                      alt="smiley"
                      height={"18px"}
                      width={"16px"}
                    />
                  </div>
                  <BorderLinearProgress
                    variant="determinate"
                    value={ARCScore * 10}
                  />
                  <Typography
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "horizontal",
                      width: "248px",
                      color: "#777A82",
                      textAlign: "left",
                      fontSize: "14px",
                      paddingTop: "15px",
                    }}
                  >
                    Recommended Limit (USD):
                  </Typography>
                  <Typography
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "horizontal",
                      width: "153px",
                      textAlign: "left",
                      fontSize: "12px",
                      color: "#2F3D63",
                      marginRight: "10px",
                      paddingRight: "10px",
                      fontWeight: "500",
                      paddingTop: 4,
                    }}
                  >
                    {recommendedLimit}
                  </Typography>
                  {isValid(recommendedLimit) &&
                    (requestLimitState ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 8,
                        }}
                      >
                        <input
                          type="text"
                          placeholder="in ($)"
                          onChange={(e) => setNewRequestLimit(e.target.value)}
                          value={newRequestLimit}
                          className="text-sm text-[#2F3D63] w-3/4 border-b-2 border-transparent bg-[#fafafc] focus:border-[#5186EC] focus:outline-none"
                        />
                        <div
                          onClick={loadingQuestion ? undefined : updateRequestLimit}
                          style={{
                            cursor: loadingQuestion ? "not-allowed" : "pointer", 
                            opacity: loadingQuestion ? 0.5 : 1, 
                          }}
                        >
                          <img src={SaveIcon} alt="save icon" style={{ width: "20px" }} />
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 8,
                          flex: "no",
                        }}
                      >
                        <Typography
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitBoxOrient: "horizontal",
                            width: "153px",
                            textAlign: "left",
                            fontSize: "12px",
                            color: "#2F3D63",
                            marginRight: "10px",
                            paddingRight: "10px",
                            fontWeight: "500",
                            paddingTop: 4,
                          }}
                        >
                          [Requested : {newRequestLimit}]
                        </Typography>
                        <div
                          onClick={() => {
                            setRequestLimitState(true);
                          }}
                        >
                          <img src={EditIcon} alt="edit icon" style={{ cursor: 'pointer', display: 'block', width:"20px" }} />
                        </div>
                      </div>
                    ))}
                    {termsRecommended && termsRecommended !== "-" && termsRecommended !== "N/A" && (
                    <>
                      <Typography
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitBoxOrient: "horizontal",
                          width: "248px",
                          color: "#777A82",
                          textAlign: "left",
                          fontSize: "14px",
                          paddingTop: "15px",
                        }}
                      >
                        Terms Recommended:
                      </Typography>

                      <Typography
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitBoxOrient: "horizontal",
                          width: "153px",
                          textAlign: "left",
                          fontSize: "12px",
                          color: "#2F3D63",
                          marginRight: "10px",
                          paddingRight: "10px",
                          fontWeight: "500",
                          paddingTop: 4,
                        }}
                      >
                        {termsRecommended}
                      </Typography>
                    </>
                  )}
                  <Typography
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "horizontal",
                      color: "#777A82",
                      textAlign: "left",
                      fontSize: "14px",
                      paddingTop: "15px",
                    }}
                  >
                    Rationale:
                  </Typography>
                  <Typography
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "horizontal",
                      textAlign: "left",
                      fontSize: "12px",
                      color: "#2F3D63",
                      marginRight: "10px",
                      paddingRight: "10px",
                      fontWeight: "500",
                      paddingTop: 4,
                      paddingBottom: 20,
                    }}
                  >
                    {underwritingReason}
                  </Typography>
                </div>
                <ScoreCard
                  risk={riskScore}
                  alternative={alternativeScore}
                  delinquency={delinquencyScore}
                  legal={legalScore}
                  sector={sectorScore}
                  failure={failureScore}
                  country={countryScore}
                  altmanZ={altmanZScore}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <Box>
                {availableMitigations.length === 1 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={false}
                    sx={{
                      background: "#5186EC 0% 0% no-repeat padding-box",
                      borderRadius: "6px",
                      backgroundColor: "#5186EC",
                      fontSize: "13px",
                      textTransform: "none",
                      fontWeight: "400",
                      lineHeight: "1", 
                      margin: "0 auto",
                      padding: "8px", 
                      textAlign: "center", 
                      whiteSpace: "normal", 
                      wordBreak: "break-word", 
                  }}
                  onClick={() => handleMenuItemClick(availableMitigations[0].key)}
                >
                  {availableMitigations[0].value}
                </Button>
                ) : availableMitigations.length > 1 ? (
                <Button
                    id="action-button"
                    aria-controls={open ? "action-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    color="primary"
                    disabled={false}
                    sx={{
                      background: "#5186EC",
                      borderRadius: "6px",
                      backgroundColor: "#5186EC",
                      width: "130px",
                      height: "30px",
                      fontSize: "13px",
                      textTransform: "none",
                      fontWeight: "400",
                      padding: "2px",
                    }}
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={handleClick}
                  >
                    Risk Mitigations
                  </Button>):null}
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "action-button",
                    }}
                  >
                    {availableMitigations.length > 0 ? (
                      availableMitigations.map((item, index) => (
                        <div key={index}>
                          <MenuItem
                            onClick={() => handleMenuItemClick(item.key)}
                            sx={{ fontFamily: "Rubik, sans-serif" }}
                          >
                            {item.value}
                          </MenuItem>
                          {availableMitigations.length - 1 !== index && (
                            <Divider />
                          )}
                        </div>
                      ))
                    ) : null}
                  </Menu>
                  {isModalOpen && (
                    <SendRequestModal
                      open={isModalOpen}
                      onClose={handleCloseSR}
                      companyInfo={{
                        address: "",
                        domain: "",
                        name: summary["Company Name"],
                        id: "",
                      }}
                      fileType={fileType}
                      navigationFrom={"table"}
                    />
                  )}
                </Box>
                <Box>
                  <Button
                    id="action-button"
                    aria-controls={open ? "action-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    color="primary"
                    disabled={false}
                    sx={{
                      background: "#5186EC",
                      borderRadius: "6px",
                      backgroundColor: "#5186EC",
                      height: "30px",
                      fontSize: "13px",
                      textTransform: "none",
                      fontWeight: "400",
                      padding: "5px",
                      lineHeight: "13px",
                    }}
                    onClick={handleManualEvaluationClick}
                  >
                    Request Manual Evaluation
                  </Button>
                </Box>
                <div style={{ display: isDialogOpen ? "block" : "none" }}>
                  <RequestManualEvaluationDialog
                    title={title}
                    comment={comment}
                    email={email}
                    fullName={fullName}
                    phoneNumber={phoneNumber}
                    isDialogOpen={isDialogOpen}
                    selectedOption={selectedOption}
                    specificCreditLimit={specificCreditLimit}
                    handleTitleChange={handleTitleChange}
                    handleCloseDialog={handleCloseDialog}
                    handleCommentsChange={handleCommentsChange}
                    handleEmailChange={handleEmailChange}
                    handleFullNameChange={handleFullNameChange}
                    handleOptionChange={handleOptionChange}
                    handlePhoneNumberChange={handlePhoneNumberChange}
                    handleSpecificCreditLimitValue={
                      handleSpecificCreditLimitValue
                    }
                    handleSubmit={handleSubmit}
                  />
                </div>
              </div>
            </Card>
            <Card
              style={{
                flex: "1.5",
                minHeight: "335px",
                background:
                  "rgba(250, 250, 252, 1) 0% 0% no-repeat padding-box",
                boxShadow:
                  "inset 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.16)",
                borderRadius: "8px",
                opacity: 1,
                display: isAllFieldsInvalid ? "none" : "",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  padding: "0 25px",
                }}
              />
              <Grid
                container
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "10px",
                  justifyContent: "flex-start",
                  padding: "25px 25px",
                }}
              >
                {combinedInfoboxCard.map(
                  (data, index) =>
                    data.value && data.value !== undefined && data.value !== null && (
                      <Grid item key={index}>
                        <Typography
                          sx={{
                            color: "#777A82",
                            textAlign: "left",
                            fontSize: "14px",
                          }}
                        >
                          {data.label}
                        </Typography>
                        {data.label === "Description:" ? (
                          <Tooltip title={data.value} arrow>
                            <Typography
                              sx={{
                                color: "#2F3D63",
                                textAlign: "left",
                                fontSize: "12px",
                                fontWeight: "500",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 3,
                                cursor: "default",
                              }}
                              component="p"
                            >
                              {data.value}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography
                            style={{
                              color:
                                data.label === "Website:"
                                  ? "#1E90FF"
                                  : "#2F3D63",
                              textAlign: "left",
                              fontSize: "12px",
                              fontWeight: "500",
                              textDecoration:
                                data.label === "Website:"
                                  ? "underline"
                                  : "none",
                              cursor:
                                data.label === "Website:"
                                  ? "pointer"
                                  : "default",
                            }}
                            component={data.label === "Website:" ? "a" : "p"}
                            href={
                              data.label === "Website:"
                                ? (data.value.startsWith('http://') || data.value.startsWith('https://') ? data.value : `http://${data.value}`)
                                : undefined
                            }
                            target={
                              data.label === "Website:" ? "_blank" : undefined
                            }
                            rel={
                              data.label === "Website:"
                                ? "noopener noreferrer"
                                : undefined
                            }
                          >
                            {data.value}
                          </Typography>
                        )}
                      </Grid>
                    )
                )}
              </Grid>
            </Card>
          </div>
          {AgingDataForCompany && (
            <>
            <Typography
              style={{
                textAlign: "left",
                fontSize: "20px",
                color: "#1A2A56",
                fontWeight: "600",
                width: "290px",
                height: "24px",
                padding: "25px 0",
              }}
            >
              Aging
            </Typography>
            <Box
              sx={{
                paddingTop: "20px",
                width: "100%",
                display: "flex",
                gap: "20px",
              }}
            >
              <Card
                sx={{
                  width: "100%",
                  height: "275px",
                  background: "#FAFAFC",
                  boxShadow:
                    "inset 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.16)",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#2F3D63",
                      fontSize: "16px",
                      fontWeight: 500,
                      mb: 2,
                    }}
                  >
                    Payment Behavior
                  </Typography>
                  <Box
                    sx={{
                      textAlign: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#777A82",
                        fontSize: "14px",
                        fontWeight: 500,
                        mb: 1,
                      }}
                    >
                      Payment Index
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2F3D63",
                        fontSize: "12px",
                        fontWeight: 500,
                        mb: 2,
                      }}
                    >
                      {paydex}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#777A82",
                        fontSize: "14px",
                        fontWeight: 500,
                        mb: 1,
                      }}
                    >
                      Avg. Payment Days
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2F3D63",
                        fontSize: "12px",
                        fontWeight: 500,
                        mb: 2,
                      }}
                    >
                      {avgCustomerPaymentDays}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#777A82",
                        fontSize: "14px",
                        fontWeight: 500,
                        mb: 1,
                      }}
                    >
                      Tenure
                    </Typography>
                    <Typography
                      sx={{
                        color: "#2F3D63",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      {tenure}
                    </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          color: "#777A82",
                          fontSize: "14px",
                          fontWeight: 500,
                          mb: 1,
                        }}
                      >
                        Terms Recommended
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2F3D63",
                          fontSize: "12px",
                          fontWeight: 500,
                          mb: 2,
                        }}
                      >
                        {termsRecommended}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          color: "#777A82",
                          fontSize: "14px",
                          fontWeight: 500,
                          mb: 1,
                        }}
                      >
                        Actual Terms
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2F3D63",
                          fontSize: "12px",
                          fontWeight: 500,
                          mb: 2,
                        }}
                      >
                        {currentRecommended}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "20px 20px 0px 20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        mt: "2px",
                      }}
                    >
                      <Typography
                        sx={{
                          mt: "-1px",
                          color: "#777A82",
                          fontSize: "11px",
                          fontWeight: 500,
                        }}
                      >
                        Last Updated
                      </Typography>
                      <Typography
                        sx={{
                          color: "#777A82",
                          fontSize: "11px",
                          fontWeight: 500,
                        }}
                      >
                        {lastUpdatedDate}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Card>
              <Card
                sx={{
                  width: "100%",
                  height: "275px",
                  background: "#FAFAFC",
                  boxShadow:
                    "inset 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.16)",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#2F3D63",
                      fontSize: "16px",
                      fontWeight: 500,
                      mb: 3,
                    }}
                  >
                    Account Receivable Summary
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      padding: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#777A82",
                          fontSize: "14px",
                          fontWeight: 500,
                          mb: 1,
                        }}
                      >
                        Current
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2F3D63",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {currentArBalance}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#777A82",
                          fontSize: "14px",
                        fontWeight: 500,
                          mb: 1,
                        }}
                      >
                        In Arrear
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2F3D63",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {arrear}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#777A82",
                          fontSize: "14px",
                          fontWeight: 500,
                          mb: 1,
                        }}
                      >
                        Total
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2F3D63",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {totalArBalance}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      padding: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#777A82",
                          fontSize: "14px",
                          fontWeight: 500,
                          mb: 1,
                        }}
                      >
                        AR Aging 30
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2F3D63",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {arBalance30}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#777A82",
                          fontSize: "14px",
                          fontWeight: 500,
                          mb: 1,
                        }}
                      >
                        AR Aging 60
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2F3D63",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {arBalance60}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#777A82",
                          fontSize: "14px",
                          fontWeight: 500,
                          mb: 1,
                        }}
                      >
                        AR Aging 90
                      </Typography>
                      <Typography
                        sx={{
                          color: "#2F3D63",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {arBalance90}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "20px 20px 0px 20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        mt : "2px",
                      }}
                    >
                      <Typography
                        sx={{
                          mt: "-1px",
                          color: "#777A82",
                          fontSize: "11px",
                          fontWeight: 500,
                        }}
                      >
                        Last Updated
                      </Typography>
                      <Typography
                        sx={{
                          color: "#777A82",
                          fontSize: "11px",
                          fontWeight: 500,
                        }}
                      >
                        {lastUpdatedDate}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
}

export default ExecutiveSummary;
