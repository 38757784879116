import { Box } from "@mui/material";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { extractNumberOfEmployees, extractYearlyRevenue, fetchFileFromS3, normalizeRowData, parseCurrencyField, } from "./utils/Helper";
import { useSelector } from "react-redux";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";

const columns = [
  {
    field: "rowName",
    headerName: "",
    cellClassName: "first-column",
    sortable: false,
  },
  { field: "location", headerName: "Location" },
  { field: "employees", headerName: "Employees" },
  { field: "revenue", headerName: "Revenue" },
  { field: "netProfit", headerName: "Net Profit" },
  { field: "totalEquity", headerName: "Total Equity" },
  { field: "marketableSecurities", headerName: "Marketable Securities" },
  { field: "currentLiabilities", headerName: "Current Liabilities" },
  { field: "marketCap", headerName: "Market Cap" },
  { field: "cashEquivalents", headerName: "Cash & Cash Equivalents" },
  { field: "totalAssets", headerName: "Total Assets" },
  { field: "funding", headerName: "Funding" },
  { field: "parentRevenue", headerName: "Parent Revenue" },
];

export default function CompanyAnalysisTable() {
  const { company } = useLoaderData();
  const URLTenant = getTenantIdFromURL();
  const isAdmin = useSelector((state) => state.userInfo.isAdmin);
  const currentTenant = useSelector((state) => state.userInfo.tenantId);
  const [tenantId, setTenantId] = useState();

  const [manualData, setManualData] = useState({});
  const [avData, setAvData] = useState({});
  const [financialStatementData, setFinancialStatementData] = useState({});
  const [dnbData, setDnbData] = useState({});
  const [linkedInData, setLinkedInData] = useState({});
  const [wikipediaData, setWikipediaData] = useState({});
  const [zoomInfoData, setZoomInfoData] = useState({});

  const [rows, setRows] = useState([
    { id: 1, rowName: "Manual" },
    { id: 2, rowName: "AV" },
    { id: 3, rowName: "Financial Statement" },
    { id: 4, rowName: "Wikipedia" },
    { id: 5, rowName: "DnB" },
    { id: 6, rowName: "Zoominfo" },
    { id: 7, rowName: "LinkedIn" },
    { id: 8, rowName: "UW Source" },
  ]);

  const prioritySources = [
    "Manual",
    "Financial Statement",
    "AV",
    "DnB",
    "LinkedIn",
    "Wikipedia",
    "Zoominfo",
  ];

  const selectTenantId = () => {
    if (isAdmin) setTenantId(URLTenant);
    else setTenantId(currentTenant);
  };

  const getLinkedInFile = async () => {
    if (company?.linkedInFileName) {
      try {
        const key = company?.linkedInFileName;
        const bucket = "crediarc-linkedin-data";
        const linkedInFile = await fetchFileFromS3(key, bucket);
        console.log("🚀 linkedIn File", linkedInFile);
        return linkedInFile;
      } catch (error) {
        console.log(error);
        return null;
      }
    }
  };

  const updateAllRows = async () => {
    selectTenantId();
    await fetchManualData();
    await fetchAVData();
    await fetchFinancialStatementData();
    await fetchDnbData();
    await fetchLinkedInData();
    await fetchWikipediaData();
    // await fetchZoomInfoData();
    updateUWSourceRow();
  };
      
  useEffect(() => {
    updateAllRows();
  }, [tenantId, company]);

  const fetchManualData = async () => {
    try {
      const data = company?.manualSource
        ? JSON.parse(company.manualSource)
        : null;
      console.log("Manual Data:", data);
      if (data) {
        const standardData = {
          totalEquity: data.equity,
          marketCap: data.marketCap,
          netProfit: data.netIncome,
          employees: data.numberOfEmployees,
          revenue: data.revenue,
        };
        console.log("Standardized Manual Data:", standardData);
        setManualData(standardData);
        updateRows("Manual", standardData);
      }
    } catch (error) {
      console.error("Error fetching Manual data:", error);
    }
  };

  const fetchAVData = async () => {
    try {
      const key = `public/alpha-vantage-financial-info/${company?.name}-${tenantId}.json`;
      const data = await fetchFileFromS3(key);
      console.log("AV Data:", data);

      if (data) {
        const standardData = {
          revenue: parseInt(
            data?.incomeStatement?.annualReports[0]?.totalRevenue || 0
          ),
          netProfit: parseInt(
            data?.incomeStatement?.annualReports[0]?.netIncome || 0
          ),
          totalEquity:
            data?.balanceSheet?.annualReports[0]?.totalShareholderEquity ===
            "None"
              ? 0
              : parseInt(
                  data?.balanceSheet?.annualReports[0]
                    ?.totalShareholderEquity || 0
                ),
          totalAssets:
            data?.balanceSheet?.annualReports[0]?.totalAssets === "None"
              ? 0
              : parseInt(
                  data?.balanceSheet?.annualReports[0]?.totalAssets || 0
                ),
          cashEquivalents:
            data?.balanceSheet?.annualReports[0]
              ?.cashAndCashEquivalentsAtCarryingValue === "None"
              ? 0
              : parseInt(
                  data?.balanceSheet?.annualReports[0]
                    ?.cashAndCashEquivalentsAtCarryingValue || 0
                ),
          marketableSecurities:
            data?.balanceSheet?.annualReports[0]
              ?.cashAndShortTermInvestments === "None"
              ? 0
              : parseInt(
                  data?.balanceSheet?.annualReports[0]
                    ?.cashAndShortTermInvestments || 0
                ),
          currentLiabilities:
            data?.balanceSheet?.annualReports[0]?.totalCurrentLiabilities ===
            "None"
              ? 0
              : parseInt(
                  data?.balanceSheet?.annualReports[0]
                    ?.totalCurrentLiabilities || 0
                ),
          marketCap:
            data?.overview?.MarketCapitalization === "None"
              ? 0
              : parseInt(data?.overview?.MarketCapitalization || 0),
          location: data?.overview?.Country || "",
        };

        console.log("Standardized AV Data:", standardData);
        setAvData(standardData);
        updateRows("AV", standardData);
      }
    } catch (error) {
      console.error("Error fetching AV data:", error);
    }
  };

  const fetchFinancialStatementData = async () => {
    try {
      const key = `public/parsed-sec-files/${company?.name}_10-K.json`;
      const data = await fetchFileFromS3(key); // invalid json received
      if (data) {
        setFinancialStatementData(data);
      }
      updateRows("Financial Statement");
    } catch (error) {
      console.error("Error fetching Financial Statement data:", error);
    }
  };
 
  const fetchDnbData = async () => {
    try {
      const companyInfo = await fetchDnbCompanyInfo();
      if (!companyInfo) {
        console.log("DnB Company Info not found.");
        return;
      }
  
      let dnbRevenue = 0,
        dnbNetIncome = 0,
        dnbTotalAsset = 0,
        dnbLocation = "",
        dnbEmployees = 0;
  
      console.log("DnB Company Info Data:", companyInfo);
  
      dnbRevenue = extractYearlyRevenue(companyInfo)
  
      if (companyInfo?.primaryAddress) {
        const locality = companyInfo.primaryAddress.addressLocality?.name || "";
        const country = companyInfo.primaryAddress.addressCountry?.name || "";
        dnbLocation = `${locality}${locality && country ? " , " : ""}${country}`;
      }
  
      dnbEmployees = extractNumberOfEmployees(companyInfo)

      const financialV2 = await fetchDnBFinancialV2();
      if (financialV2) {
        console.log("DnB Financial V2 Data:", financialV2);
  
        const latestFinancials = financialV2?.latestFinancials || {};
        const unit = latestFinancials?.units || "";
        const multiplier =
          unit === "Thousand"
            ? 1e3
            : unit === "Million"
            ? 1e6
            : unit === "Billion"
            ? 1e9
            : 1;
  
        const isUSD = latestFinancials?.currency === "USD";
  
        if (isUSD) {
          dnbNetIncome =
            latestFinancials?.overview?.netIncome * multiplier || dnbNetIncome;
            if(!dnbRevenue){
              dnbRevenue =
                latestFinancials?.overview?.salesRevenue * multiplier || dnbRevenue;
            }
          dnbTotalAsset =
            latestFinancials?.overview?.totalAssets * multiplier || dnbTotalAsset;
        }
  
      }
  
      const standardData = {
        location: dnbLocation,
        revenue: dnbRevenue,
        netProfit: dnbNetIncome,
        totalAssets: dnbTotalAsset,
        employees: dnbEmployees,
      };
  
      console.log("Standardized DnB Data:", standardData);
  
      if (Object.values(standardData).some((value) => value !== null && value !== undefined)) {
        setDnbData(standardData);
        updateRows("DnB", standardData);
      } else {
        console.warn("No valid DnB data to update.");
      }
    } catch (error) {
      console.error("Error fetching DnB data:", error);
    }
  };
 
  const fetchLinkedInData = async () => {
    try {
      const data = await getLinkedInFile();
      console.log("LinkedIn Data:", data);
      if (data) {
        let location;
        const primaryLocation = data?.locations?.find(
          (item) => (item.tag === "Primary" || item.tag === "Primär")
        );
        location = primaryLocation?.address;

        const standardData = {
          location: location,
          employees: parseEmployeeCount(data.company_size),
          funding: data?.lastRound?.value ? data?.lastRound?.value : "",
        };
        setLinkedInData(data);
        updateRows("LinkedIn", standardData);
        console.log("Standard LinkedIn Data:", standardData);
      }
    } catch (error) {
      console.error("Error fetching LinkedIn data:", error);
    }
  };

  const fetchWikipediaData = async () => {
    try {
      const data = company?.wikipedia?.infobox
        ? JSON.parse(company.wikipedia.infobox)
        : {};
      if (data) {
        const standardData = {
          location:
            data.hq_location_country && data.hq_location_city
              ? `${data.hq_location_city}, ${data.hq_location_country}`
              : data.hq_location_country || data.hq_location_city || "",
          totalEquity: parseCurrencyField(data.equity)?.value,
          marketCap: parseCurrencyField(data.marketCap)?.value,
          netProfit: parseCurrencyField(data.netIncome)?.value,
          revenue: parseCurrencyField(data.revenue)?.value, 
          employees: data.num_employees,
        };
        setWikipediaData(standardData); 
        updateRows("Wikipedia", standardData);
      }
    } catch (error) {
      console.error("Error fetching Wikipedia data:", error);
    }
  };

  const fetchZoomInfoData = async () => {
    try {
      const key = `public/zoominfo-data/${company?.name}.json`;
      const data = await fetchFileFromS3(key);
      if (data) setZoomInfoData(data);
      updateRows("Zoominfo");
    } catch (error) {
      console.error("Error fetching ZoomInfo data:", error);
    }
  };

  const fetchDnBFinancialV2 = async () => {
    try {
      const key = "public/company-financial-v2/financial-" + company?.dnbCompanyId + ".json";
      let summary = await fetchFileFromS3(key)
      summary = normalizeRowData(summary)
      const tempData = summary[0].response;
      console.log("DnB Financial V2", tempData)
      return tempData.organization; 
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDnbCompanyInfo = async () => {
    if(company?.dnbCompanyId){
      try {
        const key = "public/company-info/info-"+ company?.dnbCompanyId +".json";
        let currentCompany = await fetchFileFromS3(key);
        currentCompany = normalizeRowData(currentCompany);
        const tempData = currentCompany[0].response;
        console.log("DnB Company Info", tempData)
        return tempData.organization; 
      } catch (error) {
        console.log(error);
      }
    }
  };

  const updateRows = (source, sourceData) => {
    sourceData =
      sourceData ||
      {
        Manual: manualData,
        AV: avData,
        DnB: dnbData,
        LinkedIn: linkedInData,
        "Financial Statement": financialStatementData,
        Wikipedia: wikipediaData,
        Zoominfo: zoomInfoData,
      }[source];

    if (!sourceData) return;

    setRows((prevRows) => {
      const rowIndex = prevRows.findIndex((row) => row.rowName === source);
      if (rowIndex === -1) return prevRows;

      const updatedRow = { ...prevRows[rowIndex] };
      columns.forEach(({ field }) => {
        if (field !== "rowName" && sourceData[field] !== undefined) {
          updatedRow[field] = sourceData[field] ? sourceData[field] : "";
        }
      });

      const newRows = [...prevRows];
      newRows[rowIndex] = updatedRow;
      return newRows;
    });

    updateUWSourceRow();
  };
  
  const updateUWSourceRow = () => {
    setRows((prevRows) => {
      const uwSourceRow = {
        ...prevRows.find((row) => row.rowName === "UW Source"),
      };
      
      columns.forEach(({ field }) => {
        if (field === "location") {
          const locationPriority = ["Manual", "Wikipedia", "LinkedIn", "DnB"];
          for (const source of locationPriority) {
            const sourceRow = prevRows.find((row) => row.rowName === source);
            if (sourceRow?.[field]) {
              uwSourceRow[field] = source;
              break;
            }
          }
        } else if (field === "employees") {
          uwSourceRow[field] = determineEmployeeCount(prevRows);
        } else if (field !== "rowName") {
          for (const source of prioritySources) {
            const sourceRow = prevRows.find((row) => row.rowName === source);
            if (sourceRow?.[field]) {
              uwSourceRow[field] = source;
              break;
            }
          }
        }
      });
  
      return prevRows.map((row) =>
        row.rowName === "UW Source" ? uwSourceRow : row
      );
    });
  };

  const isRange = (size) => {
    return size.includes("-");
  };

  const parseEmployeeCount = (employeeRange) => {
    if (!employeeRange || !isNaN(employeeRange)) {
      return null;
    }
    employeeRange = employeeRange.replace(/employees?/i, "").trim();

    if (employeeRange.endsWith("+")) {
      const baseCount = employeeRange.replace(/\+$/, "").replace(/,/g, "");
      return parseInt(baseCount, 10);
    }

    const rangeMatch = employeeRange.match(
      /^(\d{1,3}(?:,\d{3})*)\s*-?\s*(\d{0,3}(?:,\d{3})*)$/
    );
    if (rangeMatch) {
      const minCount = parseInt(rangeMatch[1].replace(/,/g, ""), 10);
      const maxCount = rangeMatch[2]
        ? parseInt(rangeMatch[2].replace(/,/g, ""), 10)
        : minCount;
      return maxCount;
    }

    return 0;
  };
  const determineEmployeeCount = (rows) => {
    const sources = [];
    
    ["Manual", "LinkedIn", "DnB", "Wikipedia"].forEach(sourceName => {
      const sourceRow = rows.find(row => row.rowName === sourceName);
      const employeeCount = sourceRow?.employees;
      if (employeeCount) {
        console.log("employeeCount",{sourceName,employeeCount})
        sources.push({ source: sourceName, value: Number(employeeCount) });
      }
    });
    console.log("sources",sources)
    if (sources.length === 0) return null;
  
    sources.sort((a, b) => b.value - a.value);
    
    const maxEmployees = sources[0].value;
    const minEmployees = sources[sources.length - 1].value;
  
    if ((maxEmployees - minEmployees) / maxEmployees > 0.25) {
      for (const priority of ["Manual", "LinkedIn", "DnB", "Wikipedia"]) {
        const source = sources.find(s => s.source === priority);
        if (source) return source.source;
      }
    }
  
    return sources[0].source;
  };

  return (
    <Box sx={{ width: "100%", padding: 2 }}>
      <Box sx={{ height: 600, width: "100%" }}>
        <DataGridPremium
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{ toolbar: { showQuickFilter: true } }}
          pageSizeOptions={[5, 10, 50]}
          getRowClassName={(params) =>
            params.row.rowName === "UW Source" ? "uw-source-row" : ""
          }
          sx={{
            "& .first-column": {
              backgroundColor: "#f5f5f5",
              fontWeight: "bold",
              color: "#2F3D63",
            },
            "& .uw-source-row": {
              backgroundColor: "#e0f7fa",
              fontWeight: "bold",
              color: "#004d40",
            },
          }}
        />
      </Box>
    </Box>
  );
}
