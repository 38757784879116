const BucketRangeEnum = {
  Bucket_1: "1-1.33",
  Bucket_2: "1.33-1.66",
  Bucket_3: "1.66-2",
  Bucket_4: "2-2.33",
  Bucket_5: "2.33-2.66",
  Bucket_6: "2.66-3",
  Bucket_7: "3-3.33",
  Bucket_8: "3.33-3.66",
  Bucket_9: "3.66-4",
  Bucket_10: "4-4.33",
  Bucket_11: "4.33-4.66",
  Bucket_12: "4.66-5",
  Bucket_13: "5-5.33",
  Bucket_14: "5.33-5.66",
  Bucket_15: "5.66-6",
  Bucket_16: "6-6.33",
  Bucket_17: "6.33-6.66",
  Bucket_18: "6.66-7",
  Bucket_19: "7-7.33",
  Bucket_20: "7.33-7.66",
  Bucket_21: "7.66-8",
  Bucket_22: "8-8.33",
  Bucket_23: "8.33-8.66",
  Bucket_24: "8.66-9",
  Bucket_25: "9-9.33",
  Bucket_26: "9.33-9.66",
  Bucket_27: "9.66-10",
};

export const getDataBasedOnCombinedScore = ({data, isProspects}) => {
  let computedData = {};

  const temp = {
    count: 0,
    totalAR: 0,
  };

  for (let i = 1; i <= 27; i++) {
    computedData[`Bucket_${i}`] = { ...temp };
  }

  data.forEach((item) => {
    const combinedScore = isProspects ? parseFloat(item["Combined Score"]) : parseFloat(item["Aging Arc score"]);
    const totalARBalance = isProspects ? 0 : parseFloat(item["Total AR Balance"]);

    if (isNaN(combinedScore) || (!isProspects && isNaN(totalARBalance))) {
      return; // Skip invalid data
    }

    if (combinedScore >= 1 && combinedScore <= 1.33) {
      computedData.Bucket_1.count += 1;
      if (!isProspects) computedData.Bucket_1.totalAR += totalARBalance;
    } else if (combinedScore > 1.33 && combinedScore <= 1.66) {
      computedData.Bucket_2.count += 1;
      if (!isProspects) computedData.Bucket_2.totalAR += totalARBalance;
    } else if (combinedScore > 1.66 && combinedScore <= 2) {
      computedData.Bucket_3.count += 1;
      if (!isProspects) computedData.Bucket_3.totalAR += totalARBalance;
    } else if (combinedScore > 2 && combinedScore <= 2.33) {
      computedData.Bucket_4.count += 1;
      if (!isProspects) computedData.Bucket_4.totalAR += totalARBalance;
    } else if (combinedScore > 2.33 && combinedScore <= 2.66) {
      computedData.Bucket_5.count += 1;
      if (!isProspects) computedData.Bucket_5.totalAR += totalARBalance;
    } else if (combinedScore > 2.66 && combinedScore <= 3) {
      computedData.Bucket_6.count += 1;
      if (!isProspects) computedData.Bucket_6.totalAR += totalARBalance;
    } else if (combinedScore > 3 && combinedScore <= 3.33) {
      computedData.Bucket_7.count += 1;
      if (!isProspects) computedData.Bucket_7.totalAR += totalARBalance;
    } else if (combinedScore > 3.33 && combinedScore <= 3.66) {
      computedData.Bucket_8.count += 1;
      if (!isProspects) computedData.Bucket_8.totalAR += totalARBalance;
    } else if (combinedScore > 3.66 && combinedScore <= 4) {
      computedData.Bucket_9.count += 1;
      if (!isProspects) computedData.Bucket_9.totalAR += totalARBalance;
    } else if (combinedScore > 4 && combinedScore <= 4.33) {
      computedData.Bucket_10.count += 1;
      if (!isProspects) computedData.Bucket_10.totalAR += totalARBalance;
    } else if (combinedScore > 4.33 && combinedScore <= 4.66) {
      computedData.Bucket_11.count += 1;
      if (!isProspects) computedData.Bucket_11.totalAR += totalARBalance;
    } else if (combinedScore > 4.66 && combinedScore <= 5) {
      computedData.Bucket_12.count += 1;
      if (!isProspects) computedData.Bucket_12.totalAR += totalARBalance;
    } else if (combinedScore > 5 && combinedScore <= 5.33) {
      computedData.Bucket_13.count += 1;
      if (!isProspects) computedData.Bucket_13.totalAR += totalARBalance;
    } else if (combinedScore > 5.33 && combinedScore <= 5.66) {
      computedData.Bucket_14.count += 1;
      if (!isProspects) computedData.Bucket_14.totalAR += totalARBalance;
    } else if (combinedScore > 5.66 && combinedScore <= 6) {
      computedData.Bucket_15.count += 1;
      if (!isProspects) computedData.Bucket_15.totalAR += totalARBalance;
    } else if (combinedScore > 6 && combinedScore <= 6.33) {
      computedData.Bucket_16.count += 1;
      if (!isProspects) computedData.Bucket_16.totalAR += totalARBalance;
    } else if (combinedScore > 6.33 && combinedScore <= 6.66) {
      computedData.Bucket_17.count += 1;
      if (!isProspects) computedData.Bucket_17.totalAR += totalARBalance;
    } else if (combinedScore > 6.66 && combinedScore <= 7) {
      computedData.Bucket_18.count += 1;
      if (!isProspects) computedData.Bucket_18.totalAR += totalARBalance;
    } else if (combinedScore > 7 && combinedScore <= 7.33) {
      computedData.Bucket_19.count += 1;
      if (!isProspects) computedData.Bucket_19.totalAR += totalARBalance;
    } else if (combinedScore > 7.33 && combinedScore <= 7.66) {
      computedData.Bucket_20.count += 1;
      if (!isProspects) computedData.Bucket_20.totalAR += totalARBalance;
    } else if (combinedScore > 7.66 && combinedScore <= 8) {
      computedData.Bucket_21.count += 1;
      if (!isProspects) computedData.Bucket_21.totalAR += totalARBalance;
    } else if (combinedScore > 8 && combinedScore <= 8.33) {
      computedData.Bucket_22.count += 1;
      if (!isProspects) computedData.Bucket_22.totalAR += totalARBalance;
    } else if (combinedScore > 8.33 && combinedScore <= 8.66) {
      computedData.Bucket_23.count += 1;
      if (!isProspects) computedData.Bucket_23.totalAR += totalARBalance;
    } else if (combinedScore > 8.66 && combinedScore <= 9) {
      computedData.Bucket_24.count += 1;
      if (!isProspects) computedData.Bucket_24.totalAR += totalARBalance;
    } else if (combinedScore > 9 && combinedScore <= 9.33) {
      computedData.Bucket_25.count += 1;
      if (!isProspects) computedData.Bucket_25.totalAR += totalARBalance;
    } else if (combinedScore > 9.33 && combinedScore <= 9.66) {
      computedData.Bucket_26.count += 1;
      if (!isProspects) computedData.Bucket_26.totalAR += totalARBalance;
    } else if (combinedScore > 9.66 && combinedScore <= 10) {
      computedData.Bucket_27.count += 1;
      if (!isProspects) computedData.Bucket_27.totalAR += totalARBalance;
    }
  });

  const customerArray = [];
  const totalARBalArray = [];
  for (let i = 1; i <= 27; i++) {
    const bucket = computedData[`Bucket_${i}`];
    const range = BucketRangeEnum[`Bucket_${i}`];
    customerArray.push({ range, value: bucket.count });
    if (!isProspects) {
      totalARBalArray.push({ range, value: bucket.totalAR });
    }
  }

  return { customerArray, totalARBalArray: isProspects ? [] : totalARBalArray };
};
