import React, { useState, useEffect } from "react";
import { Paper, Typography, Box, Tabs, Tab } from "@mui/material";
import Button from "@mui/material/Button";
import { useLoaderData, useNavigate } from "react-router-dom";
import "./CompanyPage.css";
import { API, Amplify, graphqlOperation } from "aws-amplify";
import { companyDocGenerator, googleNewsCompanySearch, listCustomRawTexts, listLastVisitedCompanies, triggerQuestionsAgent } from "./graphql/queries";
import { getUnreadAlerts } from './utils/GraphQLHelper/AlertTable';
import { useFeatureFlag } from "./context/featureFlagContext";
import {
  deleteCompany,
  deleteGoogleSearch,
  deleteKnowledgeGraph,
  deleteLastVisitedCompanies,
  deleteWikipedia,
  deleteYahooFinance,
} from "./graphql/mutations";
import QuestionsAccordion from "./QuestionsAccordion.js";
import { toast } from "react-toastify";
import CompanySummary from "./CompanySummary.js";
import ChatFile from "./ChatFile.js";
import ReactGA from "react-ga4"
import { getTenantInfo } from "./utils/GraphQLHelper/GetTenantInfo.js";
import AWS from 'aws-sdk';
import awsExports from "./aws-exports";
import getTenantIdFromURL from "./utils/getTenantIdFromURL.js";
import { useSelector } from "react-redux";
import ExecutiveSummary from "./ExecutiveSummary.js";
import RefreshIcon from '@mui/icons-material/Refresh';
import Avatar from '@mui/material/Avatar';
import CompanyFinancialInfo from "./CompanyFinancialInfo.js";
import News from "./News.js";
import CompanySummaryEvents from "./companySummaryEvents.js";
import CompanyEventTimeline from "./CompanyEventTimeline/CompanyEventTimeline.js";
import yellowBulb from "./Assets/Images/bulb.svg";
import redBulb from "./Assets/Images/redBulb.svg";
import greenBulb from "./Assets/Images/greenBulb.svg";
import getExecutiveData from "./getExecutiveData.js";
import getFinancialData from "./getFinancialData.js";
import { deleteCompanySummary, fetchCompanySummary, fetchFileFromS3, formatCurrency, normalizeRowData, } from "./utils/Helper.js";
import CompanyTree from './CompanyTree';
import { getCompanyTreeData } from "./getCompanyTreeData.js";
import VerifyEntityModal from "./VerifyEntityModal.js";

const trackingID = "G-9QZ6JYFX19";
const adminTrackingId = "G-VYW7DRSVZE";

Amplify.configure(awsExports);



function CompanyPage({adminAction}) {
  const { company } = useLoaderData();
  const [disabled, setDisabled] = useState(false);
  const [companyOverviewData, setCompanyOverviewData] = useState(null);
  const [companyBalanceSheetData, setCompanyBalanceSheetData] = useState(null);
  const [companyIncomeStatementData, setCompanyIncomeStatementData] = useState(null);
  const [dnBFinancialV2Data, setDnBFinancialV2Data] = useState(null);
  const [tenantName, setTenantName] = useState("");
  const [tenantId,setTenantId] = useState()
  const isAdmin = useSelector(state => state.userInfo.isAdmin);
  const tenantEmail = useSelector(state => {
    const email = state.userInfo.email;
    if (email) {
      const username = email.split('@')[0];
      return username.length > 5 ? `${username.slice(0, 5)}..` : username;
    }
    return null;
  });
  const URLTenant = getTenantIdFromURL();
  const currentTenant  = useSelector(state => state.userInfo.tenantId);
  const [selectedTab , setSelectedTab] = useState(0);
  const [agingData , setAgingData] = useState(undefined);
  const [linkedInJSON , setLinkedInJSON] = useState({});
  const [DnbData , setDnbData] = useState();
  const [alerts , setAlerts] = useState([]);
  const [latestAlert , setLatestAlert] = useState("No new alerts!");
  const [bulbColor, setBulbColor] = React.useState(yellowBulb);
  const [currentCompany , setCurrentCompany] = useState({});
  const [questions, setQuestions] = useState(company?.questions?.items||[])
  const [companySummary,setCompanySummary] = useState({})
  const [companyDnbID , setCompanyDnbID] = React.useState(null);
  const [dnbJsonData , setDnbJsonData] = React.useState([]);
  const [showExport , setShowExport] = React.useState(false);
  const [events , setEvents] = React.useState([]);
  const [loading , setLoading] = React.useState(false);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [companyEvents, setCompanyEvents] = useState([]);

  const email= useSelector(state => state.userInfo.email);
  const selectTenantId =()=>{
    if(isAdmin) setTenantId(URLTenant)
    else setTenantId(currentTenant)
  }
  const navigate = useNavigate();
  const timer = React.useRef();

  const fetchDnbDataFromJson = async () => {
    if(company?.dnbCompanyId){
      try {
        const key = "public/company-info/info-"+ company?.dnbCompanyId +".json";
        let currentCompany = await fetchFileFromS3(key);
        currentCompany = normalizeRowData(currentCompany);
        setDnbJsonData(currentCompany[0]?.response);
      } catch (error) {
        setDnbJsonData({});
        console.log(error);
      }
    }
  };

  useEffect(() => {
    setCompanyDnbID(company?.dnbCompanyId);
    fetchDnbDataFromJson();
  }, [ companyDnbID ]);
  useEffect(()=>{
    const tempEvents  = getSignificantEvents(company?.summary)
    const companyInfo = getCompanyInfo(company?.summary)
    const recommendation = getRecommendation(company?.summary)
    setCompanySummary({events:tempEvents,companyInfo,recommendation});
    setEvents(tempEvents)
    fetchCompanyEvents();
  },[company])

  const getAdditionalInfo = async() => {
    //use composite key or gsi
    try {
      const result = await API.graphql(
        graphqlOperation(listCustomRawTexts, {
          filter: {
            companyId: { eq: company.id },
            tenantId: { eq: company.tenantId },
          }
        })
      );
      let response = result?.data?.listCustomRawTexts?.items
      if(response && response?.length>0){
        setAdditionalInfo(response[0]?.text)
      }
    } catch (error) {
      console.log("error while fetching additional info",error)
    }
  }

  useEffect(() => {
    const fetchAllData = async () => {
      selectTenantId();
      await getDnBFile();
      await fetchTenantName();
      await getSummaryData();
      await getAgingFile();
      await getLinkedInFile();
      await fetchDnBFinancialV2();
      await readJsonFromS3();
      await getAdditionalInfo();
    }
    fetchAllData();
  }, [tenantId , company]);

const fetchCompanyEvents = async () => {
    const payload = {
        body: JSON.stringify({
            company_name: company?.name,
        }),
        httpMethod: "POST",
    };

    console.log("Event [fetchCompanyEvents] --> ", payload);
    try {
      let response = await API.graphql(
        graphqlOperation(googleNewsCompanySearch, {
            event: JSON.stringify(payload),
        })
    );

    const getCompanyEventResponse = JSON.parse(
        response.data?.googleNewsCompanySearch
    )?.body;
    const parsedResponse = JSON.parse(getCompanyEventResponse);
    const sortedResponse = parsedResponse.sort((a, b) => b.date - a.date);
    setCompanyEvents(sortedResponse);
    } catch (error) {
      console.log("Error fetching Google News",error)
    }
};

  const selectedTenant = getTenantIdFromURL();
  const readJsonFromS3 = async () => {
    try {
      const key = `public/alpha-vantage-financial-info/${company?.name}-${tenantId}.json`;
      const jsonData = await fetchFileFromS3(key)
      console.log("🚀 AV data",jsonData)
      setCompanyOverviewData(jsonData?.overview);
      setCompanyBalanceSheetData(jsonData.balanceSheet);
      setCompanyIncomeStatementData(jsonData.incomeStatement);
    } catch (error) {
      console.log('Failed to read file', error);
    }
  }

  const fetchDnBFinancialV2 = async () => {
    try {
      const key = "public/company-financial-v2/financial-" + companyDnbID + ".json";
      let summary = await fetchFileFromS3(key)
      summary = normalizeRowData(summary)
      const tempData = summary[0].response;
      setDnBFinancialV2Data(tempData);
    } catch (error) {
      setDnBFinancialV2Data(null);
      console.log(error);
    }
  };

  const getAlerts = async () => {
    try {
      const allAlerts = await getUnreadAlerts();
      setAlerts(allAlerts);
      const filteredAlerts = allAlerts?.filter((alert) => {
        const companyInfo = JSON.parse(alert.companyInfo);
        return (
          alert.tenantId === tenantId &&
          company?.name === companyInfo?.companyName
        );
      });
      
      if (filteredAlerts.length !== 0) {
        setLatestAlert(filteredAlerts[0].message);
        const notifBuldColor = JSON.parse(
          filteredAlerts[0].companyInfo
        )?.bulbColor;
        if (notifBuldColor === "red") setBulbColor(redBulb);
        else if (notifBuldColor === "green") setBulbColor(greenBulb);
        else setBulbColor(yellowBulb);
      }
    } catch (error) {
      console.log(error)
    }
}

const getSignificantEvents = (summary) => {
  if (!summary) return [];
  if (
    summary.includes("No significant events were reported at this time.") ||
    summary.includes("No significant events or news were reported at this time.") ||
    summary.includes("No news available.") ||
    summary.includes("No significant events found.")
  ) {
    return [];
  }
  const lines = summary.split("\\n");
  const significantEventsIndex = lines.findIndex(line => line.trim().toUpperCase().includes("3. SIGNIFICANT EVENTS"));

  if (significantEventsIndex === -1) return [];

  const events = [];
  for (let i = significantEventsIndex + 1; i < lines.length; i++) {
    const line = lines[i].trim();
    if (line.startsWith("1.") || line.startsWith("2.") || line.startsWith("4.")) {
      break;
    }
    // Remove leading dashes and extra spaces
    const cleanedLine = line.replace(/^\s*-\s*/, '');
    if (cleanedLine) {
      events.push(cleanedLine.replace(/\"$/, ''));
    }
  }

  return events;
};

  const getCompanyInfo = (summary) => {
    if (!summary) return "";
    const lines = summary.split("\\n");
    const companyInfoIndex = lines.findIndex(line => line.trim().toUpperCase().includes("1. COMPANY INFO"));

  if (companyInfoIndex === -1 || companyInfoIndex + 1 >= lines.length) return "";

  let infoLines = [];
  for (let i = companyInfoIndex + 1; i < lines.length; i++) {
    const line = lines[i].trim();
    if (line.startsWith("1.") || line.startsWith("2.") || line.startsWith("3.") || line.startsWith("4.")) {
      break; 
    }
    infoLines.push(line);
  }

  return infoLines.join(" "); 
};

const getRecommendation = (summary) => {
  if (!summary) return "";

    const lines = summary.split("\\n");
    const recommendationIndex = lines.findIndex(line => line.trim().toUpperCase().includes("2. RECOMMENDATION"));

  if (recommendationIndex === -1 || recommendationIndex + 1 >= lines.length) return "";

  let recommendationLines = [];
  for (let i = recommendationIndex + 1; i < lines.length; i++) {
    const line = lines[i].trim();
    if (line.startsWith("1.") || line.startsWith("3.") || line.startsWith("4.")) {
      break;
    }
    recommendationLines.push(line);
  }

    return recommendationLines.join(" ");
  };

  var revenueYear1 = "";

  var revenueYear2 = "";

  var revenueYear3 = "";

  const formatMoney = (number) => {
    if(number===null || number===undefined || number==="") return ""
    if (typeof number == "string") return number;
    if (number >= 1e9) {
      return `US $${(number / 1e9).toFixed(2)} billion`;
    } else if (number >= 1e6) {
      return `US $${(number / 1e6).toFixed(2)} million`;
    } else if (number >= 1e3) {
      return `US $${(number / 1e3).toFixed(2)} thousand`;
    } else {
      return `US $${number}`;
    }
  };
  var financialInfo = {
    last3yrRevenue:null
  }
  const currentYear = new Date().getFullYear();

  var summaryScores = {
    ARCScore : "",
    legalScore : "",
    alternativeScore : "",
    countryScore : "",
    riskScore : "",
    sectorScore: "",
    recommendedLimit : "",
    requestedLimit : "",
    underwritingReason : "",
    currentArBalance : "",
    totalArBalance : "",
    termsRecommended : "",
    currentRecommended : "",
    paydex : "",
    tenure : "",
    avgCustomerPaymentDays : "",
    arear : "-",
    delinquencyScore : "",
    failureScore : "",
    altmanZScore : "",
    arBalance30 : "",
    arBalance60 : "",
    arBalance90 : "",
    lastUpdatedDate : ""
  };
  
  var executiveData = {
    Description: "",
    Main_sector: "",
    Number_of_employees: "",
    Market_cap: "",
    Credit_Rating: "",
    Founders: "",
    Key_staff: "",
    Main_location_office: "",
    Revenue: "",
    Symbol: "",
    Founded: "",
    Parent_company: "",
    Website: "",
    Main_competitors: "",
  };

  if (currentCompany && Object.keys(currentCompany).length > 0) {
    const getExecutiveDetails = () => {
      const data = getExecutiveData({
        company,
        summary: currentCompany,
        overview: companyOverviewData,
        incomeStatement: companyIncomeStatementData,
        AgingDataForCompany: agingData,
        DnbData: DnbData,
        tenantId: tenantId,
        linkedInJSON: linkedInJSON,
        dnBFinancialV2Data: dnBFinancialV2Data,
        dnbJsonData: dnbJsonData,
        balanceSheet: companyBalanceSheetData,
      });

      ({ summaryScores, executiveData,financialInfo } = data);
      revenueYear1 = financialInfo?.last3yrRevenue[currentYear - 1];
      revenueYear2 = financialInfo?.last3yrRevenue[currentYear - 2];
      revenueYear3 = financialInfo?.last3yrRevenue[currentYear - 3];
    };

    var financialData = getFinancialData({
      overview: companyOverviewData,
      balanceSheet: companyBalanceSheetData,
      incomeStatement: companyIncomeStatementData,
      dnBFinancialV2Data: dnBFinancialV2Data,
      company: company,
      linkedInJSON: linkedInJSON,
      dnbJsonData: dnbJsonData,
    });

    getExecutiveDetails();

    function isValid(value) {
      return (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        !Number.isNaN(value) &&
        value !== "NaN" &&
        value !== Infinity
      );
    }

    const scores = [
      { label: "Risk Segment", value: summaryScores?.riskScore, priority: 1 },
      { label: "Alternative Score", value: summaryScores?.alternativeScore, priority: 2 },
      { label: "Delinquency Score", value: summaryScores?.delinquencyScore, priority: 3 },
      { label: "Legal Score", value: summaryScores?.legalScore, priority: 4 },
      { label: "Failure Score", value: summaryScores?.failureScore, priority: 5 },
      { label: "Sector Score", value: summaryScores?.sectorScore, priority: 6 },
      { label: "Country Score", value: summaryScores?.countryScore, priority: 7 },
      { label: "Altman Z Score", value: summaryScores?.altmanZScore, priority: 8 },
    ];

    const availableScores = scores.filter(
      (score) =>
        score.value !== undefined && score.value !== null && !isNaN(score.value)
    );

    const nonAltmanScores = availableScores.filter(
      (score) => score.label !== "Altman Z Score"
    );
    if(nonAltmanScores.length < 4 && isValid(summaryScores?.altmanZScore)){
      nonAltmanScores.push({ label: "Altman Z Score", value: summaryScores?.altmanZScore, priority: 7 })  
    }
    nonAltmanScores.sort((a, b) => {
      if (b.value === a.value) {
        return a.priority - b.priority;
      }
      return b.value - a.value;
    });

    var displayScores =
      nonAltmanScores.length > 4
        ? nonAltmanScores.slice(0, 4)
        : nonAltmanScores;
  }

  const manualSource = company?.manualSource
    ? JSON.parse(company.manualSource)
    : {};

  const predefinedFields = [
    "description",
    "industry",
    "founders",
    "keyPeople",
    "founded",
    "ticker",
    "website",
    "country",
    "city",
    "parentCompany",
    "revenue",
    "marketCap",
    "numberOfEmployees",
    "AQR",
    "netIncome",
    "yearsOfOperation",
    "equity"
  ];

  const additionalFields = Object.keys(manualSource)
    .filter(field => !predefinedFields.includes(field))
    .map((field) => ({
      label: `${field}`,
      value: manualSource[field]
    }))
    .filter(field => field.value !== undefined && field.value !== null && field.value !== '');  // Filter out empty values
  const infoboxCard = [
    {
      label: "Description",
      value:
        executiveData?.Description?.length > 100
          ? executiveData.Description.substring(0, 100) + "..."
          : executiveData?.Description,
    },
    { label: "Main Sector", value: executiveData?.Main_sector },
    { label: "Founded", value: executiveData?.Founded },
    { label: "Symbol", value: executiveData?.Symbol },
    { label: "Website", value:  executiveData?.Website },
    { label: "Revenue", value: formatMoney(executiveData?.Revenue) },
    { label: "Market Cap", value: executiveData?.Market_cap },
    { label: "Number of Employees", value: executiveData?.Number_of_employees },
    { label: "Credit Rating", value: executiveData?.Credit_Rating },
    { label: "Main Office Location", value: executiveData?.Main_location_office },
    { label: "Founders", value: executiveData?.Founders },
    { label: "Key Staff", value: executiveData?.Key_staff },
    { label: "Parent Company", value: executiveData?.Parent_company },
  ];

  // Combine predefined infoboxCard with additional manualSource fields
  const combinedInfoboxCard = [...infoboxCard, ...additionalFields];

  const executiveSummary = {
    ARCScore: summaryScores?.ARCScore,
    legalScore: summaryScores?.legalScore,
    sectorScore: summaryScores?.sectorScore,
    alternativeScore: summaryScores?.alternativeScore,
    countryScore: summaryScores?.countryScore,
    riskScore: summaryScores?.riskScore,
    recommendedLimit: summaryScores?.recommendedLimit,
    underwritingReason: summaryScores?.underwritingReason,
    currentArBalance: summaryScores?.currentArBalance,
    totalArBalance: summaryScores?.totalArBalance,
    termsRecommended: summaryScores?.termsRecommended,
    currentRecommended: summaryScores?.currentRecommended,
    paydex: summaryScores?.paydex,
    tenure: summaryScores?.tenure,
    avgCustomerPaymentDays: summaryScores?.avgCustomerPaymentDays,
    arear: summaryScores?.arear,
    delinquencyScore: summaryScores?.delinquencyScore,
    failureScore: summaryScores?.failureScore,
    altmanZScore: summaryScores?.altmanZScore,
    displayScores,
    requestedCreditLimit: formatCurrency(company?.requestedCreditLimit),
    infoboxCard:combinedInfoboxCard,
    location:executiveData?.Main_location_office
  };

  const base64ToBlob = (base64String, mimeType) => {
    // Add padding if needed
    const paddedBase64String = base64String.padEnd(base64String.length + (4 - (base64String.length % 4)) % 4, '=');

    const byteCharacters = atob(paddedBase64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  const downloadBlob = (blob, fileName) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success('The report is generated!');
  };
  const isAllVarsInvalid = [
    financialData?.isAllFinanceInfoVarsInvalid,
    financialData?.isAllPRVarsInvalid,
    financialData?.isAllQRVarsInvalid,
    financialData?.isAllYRVarsInvalid
  ].every(Boolean);

  function DownloadButton() {
    const generatePDF = async () => {
      setLoading(true)
      toast.info("The report is being generated, we'll update once it's ready");
      const companyTreeData = getCompanyTreeData(company?.companyTreeData)
      const data = {companySummary,questions,executiveSummary,financialData,company,companyTreeData,website:getWebsiteLink(company?.googleSearch),dnbJsonData,financialDataNotAvailable:isAllVarsInvalid}
      const payload = {
        data:JSON.stringify(data),
        tenantId
      }
      try {
        const res = await API.graphql(
          graphqlOperation(companyDocGenerator, payload)
        );

        const base64String = JSON.parse(res.data.companyDocGenerator).body;
        const pdfBlob = base64ToBlob(base64String, "application/pdf");
        downloadBlob(pdfBlob, `${company?.name} - Crediarc Report.pdf`);
        setLoading(false)
      } catch (error) {
        console.error('Error generating PDF:', error);
        toast.error("Error while generating report");
        setLoading(false)
      }
    };

    return (
      <Button
        onClick={generatePDF}
        style={{
          color: loading ? "#A9A9A9" : "#5186EC",
          textTransform: "none",
          fontFamily: "Rubik, sans-serif",
          width: "49px",
          height: "17px",
          fontWeight: "250",
          cursor: loading ? "not-allowed" : "pointer",
        }}
      >
        <img
          src="/exportIcon.png"
          alt="export Icon"
          style={{
            width: "30px",
            height: "22px",
            filter: loading ? "grayscale(100%)" : "none",
            opacity: loading ? 0.5 : 1,
          }}
        />
        Export
      </Button>
    );
  }

  const handleDelete = async () => {
    try {
      ReactGA.event({
        category: isAdmin ? 'Admin User' : 'User',
        action: isAdmin ? `Del_comp-${tenantEmail}_admin_${tenantName}` : `Del_comp-${tenantEmail}_${tenantName}`
      });

      const companyInput = {
        tenantId: company.tenantId,
        id: company.id,
      };
      const result = await API.graphql(
        graphqlOperation(listLastVisitedCompanies, {
          tenantId: tenantId,
        })
      );
      const lastVisitedCompany =
        result.data.listLastVisitedCompanies.items.filter(
          (elem) => elem.companyId === company.id
        );
      if (lastVisitedCompany[0]?.lastOpenedAt) {
        await API.graphql(
          graphqlOperation(deleteLastVisitedCompanies, {
            input: {
              tenantId: company.tenantId,
              lastOpenedAt: lastVisitedCompany[0]?.lastOpenedAt,
            },
          })
        );
      }

      const deleteCompanyResponse = await API.graphql(
        graphqlOperation(deleteCompany, {
          input: companyInput,
        })
      );

      if (
        company.companyGoogleSearchId &&
        company.companyGoogleSearchTenantId
      ) {
        const deleteGoogleSearchResponse = await API.graphql(
          graphqlOperation(deleteGoogleSearch, {
            input: {
              id: company.companyGoogleSearchId,
              tenantId: company.companyGoogleSearchTenantId,
            },
          })
        );

        if (
          company.googleSearch.googleSearchKnowledgeGraphId &&
          company.googleSearch.googleSearchKnowledgeGraphTenantId
        ) {
          const deleteKnowledgeGraphResponse = await API.graphql(
            graphqlOperation(deleteKnowledgeGraph, {
              input: {
                id: company.googleSearch.googleSearchKnowledgeGraphId,
                tenantId:
                  company.googleSearch.googleSearchKnowledgeGraphTenantId,
              },
            })
          );
        }
      }

      if (company.companyWikipediaId && company.companyWikipediaTenantId) {
        const deleteWikipediaResponse = await API.graphql(
          graphqlOperation(deleteWikipedia, {
            input: {
              id: company.companyWikipediaId,
              tenantId: company.companyWikipediaTenantId,
            },
          })
        );
      }

      if (
        company.companyYahooFinanceId &&
        company.companyYahooFinanceTenantId
      ) {
        const deleteYahooFinanceResponse = await API.graphql(
          graphqlOperation(deleteYahooFinance, {
            input: {
              id: company.companyYahooFinanceId,
              tenantId: company.companyYahooFinanceTenantId,
            },
          })
        );
      }
      await deleteCompanySummary(tenantId, company?.id);
      if(isAdmin) navigate(`/tenant-dashboard/${selectedTenant}/company`);
      else navigate("/company");
    } catch (error) {
      console.error("Error deleting company:", error);
    }
  };

  const onTriggerAgentClick = async () => {
    ReactGA.event({
     category: isAdmin ? 'Admin User' : 'User',
      action: isAdmin ? `Trigger_QA-${tenantEmail}_admin_${tenantName}` : `Trigger_QA-${tenantEmail}_${tenantName}`
    });

    toast.success(`Trigger Agent started!`, {
      bodyStyle: {
        fontWeight: "bold",
      },
    });
    setDisabled(true);
    await API.graphql(
      graphqlOperation(triggerQuestionsAgent, {
        tenantId: company.tenantId,
        id: company.id,
      })
    );
    timer.current = window.setTimeout(() => {
      setDisabled(false);
    }, 16000);
  };
  const getDnBFile = async () => {
    if (tenantId) {
      try {
        const key = "public/dnbData.json";
        let summary = await fetchFileFromS3(key);
        summary = normalizeRowData(summary);
        setDnbData(summary);
        console.log("DNB DATA: ", DnbData);
      } catch (error) {
        console.log(error);
        return null;
      }
    }
  };

  const getAgingFile = async () => {
    if (!tenantId) return;

    if (company?.isManuallyAdded) {
      const key = `public/aging-json-files/${tenantId}/${company?.agingFileName}`;
      const fileData = await fetchFileFromS3(key)
      
      if (fileData) {
        const summary = normalizeRowData(fileData);
        console.log("fileData",summary)
        const matchedItem = summary?.find(
          (item) => item["Company Name"].toLowerCase() === company?.name?.toLowerCase()
        );
        setAgingData(matchedItem);
      }
    } else {
      const key = `public/aging_${tenantId}.json`;
      const fileData = await fetchFileFromS3(key)
      
      if (fileData) {
        const summary = normalizeRowData(fileData);
        const matchedItem = summary?.find(
          (item) => item["name"].toLowerCase() === company?.name?.toLowerCase()
        );
        setAgingData(matchedItem);
      }
    }
    return null;
  };

  const getLinkedInFile = async () => {
    if (company?.linkedInFileName) {
      try {
        const key = company?.linkedInFileName;
        const bucket = "crediarc-linkedin-data";
        const linkedInFile = await fetchFileFromS3(key, bucket);
        console.log("🚀 linkedIn File", linkedInFile);
        setLinkedInJSON(linkedInFile);
      } catch (error) {
        console.log(error);
        return null;
      }
    }
  };

  const getSummaryData = async () => {
    getAlerts();
    if(tenantId){
    try {
      let summary = await fetchCompanySummary(tenantId, company?.id)
      console.log("summary fetched", summary);
      setCurrentCompany(summary);
      return { summary }
      } catch (error) {
        console.log(error);
        return null;
      }
    }
  }

  const fetchTenantName = async () => {
    const {name} = await getTenantInfo();
    setTenantName(name);
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const CircleWithIcon = () => {
    const circleStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '20px', // Adjust the size as needed
      height: '20px', // Adjust the size as needed
      background: '#E7BF3C33',
      borderRadius: '50%',
      opacity: 1,
    };

  return (
    <div style={circleStyle}>
      <img src={bulbColor} style={{height: "12px" , width: "12px"}} alt="bulbicon"/>
    </div>
  );
};

  function getWebsiteLink(data) {
    if (data) {
      const { knowledgeGraph, organicResult } = data;
      let websiteLink = knowledgeGraph?.website;

      if (!websiteLink && organicResult?.items) {
        const companyNameWords = company?.name.split(' ') || [];
        for (const element of organicResult.items) {
          for (const word of companyNameWords) {
            const urlRegex = /^(http|https):\/\/(?!.*(linkedin\.com|youtube\.com|facebook\.com|twitter\.com|instagram\.com|pinterest\.com|tiktok\.com|snapchat\.com|reddit\.com))[^\s/$.?#].[^\s]*$/;
              if (element?.source.toLowerCase().includes(word.toLowerCase()) && urlRegex.test(element?.link)) {
              websiteLink = element?.link;
              return websiteLink;
            }
          }
        }
      }
      return websiteLink;
    }
  }
  const website = getWebsiteLink(company?.googleSearch);

  const {isFeatureFlag} = useFeatureFlag();
  const {
    show_company_card,
    show_events,
    show_executive_summary,
    show_files_section,
    show_finance_info,
    show_news,
    show_questions_section
  } = isFeatureFlag?.["Company_Cards_Page"] || {};

  return (
    <div
      className="CompanyPage"
      style={{ width: "100%", backgroundColor: "#ECEDF0" }}
    >
      <div>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box
           style={{ display: "flex", alignItems: "center", padding: "20px" , justifyContent: "center" }}
          >
            <Avatar
              sx={{ width: 50, height: 50, cursor: "pointer" }}
              src={
                company.logo ||
                // google favicon search
                `https://www.google.com/s2/favicons?sz=64&domain=${website}`
              }
            />
            <Typography
              style={{
                height: "33px",
                paddingLeft: "20px",
                fontFamily: "Rubik, sans-serif",
                fontSize: "28px",
                color: "#1A2A56",
                fontWeight: "500",
                paddingRight: "20px",
              }}
            >
              {company?.name}
            </Typography>
            <Box paddingTop={"15px"}>
              <Box
                style={{
                  width: "fit-content",
                  display: "flex",
                  alignContent: "center",
                  background: "#FFFFFF",
                  border: "1px solid #E9E9E9",
                  borderRadius: "8px",
                  opacity: 1,
                  padding: '5px'
                }}
              >
                <CircleWithIcon />
                <Typography sx={{ fontSize: "14px", color: "#2F3D63" , paddingLeft: '10px' }}>
                  {latestAlert}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              height: "17px",
              width: "310px",
              gap: "8px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingTop: "30px",
            paddingRight: "10px",
            }}
          >
            <VerifyEntityModal  handleDelete={handleDelete} companyUniqueID={company?.dnbCompanyId} companyInfo={company} requestedLimit={currentCompany?.["Requested Limit"]} />
            <Button
              onClick={handleDelete}
              style={{
                color: "#5186EC",
                width: "180px",
                height: "17px",
                fontSize: "14px",
                textTransform: "none",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "250",
              }}
            >
              <img src = '/binIcon.png' alt =  'Bin Icon'
                style={{ width: "22px", height: "17px" }}
              />
              Delete Company
            </Button>
            <Button
              onClick={onTriggerAgentClick}
              disabled={disabled}
              style={{
                color: "#5186EC",
                textTransform: "none",
                fontFamily: "Rubik, sans-serif",
                width: "56px",
                height: "17px",
                fontWeight: "250",
              }}
              startIcon={<RefreshIcon style={{ color: "#5186EC" }} />}
            >
              Refresh
            </Button>
            {showExport?<DownloadButton jsonData={company} filename={company?.name} />:null}
          </Box>
        </Box>
      </div>
      <Paper
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          backgroundColor: "#FAFAFC",
        }}
      >
        {/* <Box my={5}>
          {
            currentCompany ? (
              <Box>
                <Box style={{ display: "flex", gap: "5px" }}>
                  <Typography variant='body2' fontWeight={"bold"}>Combined Score :</Typography>
                  <Typography variant='body2' fontWeight={"bold"}>{currentCompany["Combined Score"]}</Typography>
                </Box>
                {
                  !currentCompany["Recommended Net"] === "" && (<Box style={{ display: "flex", gap: "5px" }}>
                    <Typography variant='body2' fontWeight={"bold"}>Recommended Net :</Typography>
                    <Typography variant='body2' fontWeight={"bold"}>{currentCompany["Recommended Net"]}</Typography>
                  </Box>)
                }
                <Box style={{ display: "flex", gap: "5px" }}>
                  <Typography variant='body2' fontWeight={"bold"}>Recommended Limit :</Typography>
                  <Typography variant='body2' fontWeight={"bold"}>{currentCompany["Recommended Limit"]}</Typography>
                </Box>
                <Box style={{ display: "flex", gap: "5px" }}>
                  <Typography variant='body2' fontWeight={"bold"}>Underwriting Reason :</Typography>
                  <Typography variant='body2' fontWeight={"bold"}>{currentCompany["Underwriting Reason"]}</Typography>
                </Box>
                {
                  currentCompany["Altman Z Score"] && (<Box style={{ display: "flex", gap: "5px" }}>
                    <Typography variant='body2' fontWeight={"bold"}>Altman Z Score :</Typography>
                    <Typography variant='body2' fontWeight={"bold"}>{currentCompany["Altman Z Score"]}</Typography>
                  </Box>)
                }
                {
                  parentCompanyName && (
                    <Box style={{ display: "flex", gap: "5px" }}>
                    <Typography variant='body2' fontWeight={"bold"}>Parent Company :</Typography>
                    <Typography variant='body2' fontWeight={"bold"}>{parentCompanyName}</Typography>
                    </Box>
                  )
                }
              </Box>
            ) : null
          }
        </Box> */}
        <div>
          <Tabs
            sx={{
              borderBottom: 1,
              paddingTop: "20px",
              paddingLeft: "25px",
              "& .MuiTabs-indicator": {
                backgroundColor: "#2F3D63",
                width: "176px",
                borderRadius: "8px 8px 0px 0px",
                height: "3px",
              },
              "& .MuiTab-root": {
                color: "#777A82",
                textTransform: "none",
                height: "24px",
                fontSize: "20px",
                fontFamily: "Rubik , sans-serif",
                width: "176px",
                fontWeight: "250",
              },
              "& .Mui-selected": {
                fontWeight: "500",
                textTransform: "none",
                height: "24px",
                fontSize: "20px",
                fontFamily: "Rubik , sans-serif",
                width: "176px",
                color: "#2F3D61",
              },
            }}
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="company tabs"
          >
            <Tab label="General Info" {...a11yProps(0)} width="120px" />
            {show_files_section && <Tab label="Files" {...a11yProps(1)} width="86px" />}
            {show_questions_section && <Tab label="Questions" {...a11yProps(2)} width="112px" />}
            { <Tab label="Additional Info" {...a11yProps(3)} width="112px" />}
          </Tabs>
          <TabPanel value={selectedTab} index={0}>
            {show_company_card && (
              <ExecutiveSummary
                company={company}
                summary={currentCompany}
                overview={companyOverviewData}
                incomeStatement={companyIncomeStatementData}
                AgingDataForCompany={agingData}
                DnbData={DnbData}
                tenantId={tenantId}
                linkedInJSON={linkedInJSON}
                setShowExport={setShowExport}
                setCurrentCompany={setCurrentCompany}
                dnBFinancialV2Data={dnBFinancialV2Data}
                dnbJsonData={dnbJsonData}
                executiveData={executiveData}
                summaryScores={summaryScores}
              />
            )}
            {show_executive_summary && (<Typography
                style={{
                  textAlign: "left",
                  fontSize: "20px",
                  color: "#1A2A56",
                  fontWeight: "600",
                  width: "290px",
                  height: "24px",
                  padding: " 0 25px",
                }}
              >
                Executive Summary
              </Typography>
            )}
            {company && show_executive_summary && (
              <CompanySummary
                company={company}
                setCompanySummary={setCompanySummary}
              />
            )}
            {show_company_card && company?.companyTreeData && (
              <CompanyTree 
                treeData={company.companyTreeData} 
              />
            )}
            {company && show_finance_info && !isAllVarsInvalid && (
              <CompanyFinancialInfo
                overview={companyOverviewData}
                balanceSheet={companyBalanceSheetData}
                incomeStatement={companyIncomeStatementData}
                linkedInJSON={linkedInJSON}
                financialData={financialData}
              />
            )}
            {company?.googleSearch?.news?.items.length > 0 && show_news && <News company={company} />}
            {company?.summary?.split("\\n").length > 0 && (show_events && events?.length>0) && <CompanySummaryEvents company={company} />}
            
            {companyEvents.length > 0 && <CompanyEventTimeline timelineData={companyEvents} />}
          
          </TabPanel>
          {show_files_section && (
            <TabPanel value={selectedTab} index={1}>
              <ChatFile company={company} />
            </TabPanel>
          )}
          {show_questions_section && (
            <TabPanel value={selectedTab} index={show_files_section ? 2 : 1}>
              <QuestionsAccordion
                questions={questions}
                companyId={company.id}
                setQuestions={setQuestions}
              />
            </TabPanel>
          )}
          <TabPanel value={selectedTab} index={!!show_files_section + !!show_questions_section + 1}>
            <Box
              style={{
                background: "#FAFAFC 0% 0% no-repeat padding-box",
                boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
                borderRadius: "8px",
                opacity: 1,
                padding: "25px",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}>
              <Typography
                style={{
                  color: "#2F3D63",
                  fontSize: "20px",
                  textAlign: "left",
                  fontWeight: "500",
                }}>
                Additional Info
              </Typography>
              <div
                style={{
                  color: "#4A4A4A",
                  lineHeight: "1.6", 
                  fontSize: "16px", 
                }}>
                <p>
                  {additionalInfo}
                </p>
              </div>
            </Box>
          </TabPanel>
          <div id="question-page-section" style={{ display: "none" }}>
            <QuestionsAccordion
              questions={questions}
              companyId={company.id}
              setQuestions={setQuestions}
              pdf={true}
            />
          </div>
        </div>
        {/*<ResourcesTabs company={company} companyOverviewData={companyOverviewData} companyBalanceSheetData={companyBalanceSheetData} companyIncomeStatementData={companyIncomeStatementData} />
          <CompanySummary company={company} />
        <ChatFile company={company} />
        <DemoProperties company={company} />
        <QuestionsAccordion company={company} />
        <ResourcesTabs company={company} companyOverviewData={companyOverviewData} companyBalanceSheetData={companyBalanceSheetData} companyIncomeStatementData={companyIncomeStatementData} />
            */}
      </Paper>
    </div>
  );
}

export default CompanyPage;