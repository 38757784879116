
import React, { useState } from "react";
import {
  Modal,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  Grid,
  styled,
  StepConnector,
  stepConnectorClasses,
} from "@mui/material";
import Tick from "./Assets/Images/tick.svg";
import { API, graphqlOperation } from "aws-amplify";
import { companiesByName, triggerSendMitigationEmail } from "./graphql/queries";
import { getTenantInfo } from "./utils/GraphQLHelper/GetTenantInfo";
import { toast } from "react-toastify";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
import { getTenantIdAuth0, isAdminAuth0 } from "./authUtils";
import { fetchFileFromS3 } from "./utils/Helper";
import AWS from 'aws-sdk';
const ses = new AWS.SES({ apiVersion: "2010-12-01" });
const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#E9E9E9",
  zIndex: 1,
  color: "#fff",
  width: 45,
  height: 45,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "0px 3px 6px #00000029",
  ...(ownerState.active && {
    backgroundColor: "#2F3D63",
    boxShadow: "0px 3px 6px #00000029",
    border: "2px solid #5186EC",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#2F3D63",
    boxShadow: "0px 3px 6px #00000029",
  }),
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#2F3D63",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#2F3D63",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 0,
    border: "1px solid #2F3D63",
    backgroundColor: "#2F3D63",
    borderRadius: 1,
  },
}));

const SendRequestModal = ({ open, onClose,fileType,companyInfo,navigationFrom }) => {
  const [activeStep, setActiveStep] = useState(0);
  console.log("🚀 ~ activeStep:", activeStep)
  const [formData, setFormData] = useState({
    firstName: "",
    emailAddress: "",
    lastName: "",
    phoneNumber: "",
    customMessage: "",
  });
  const [errors, setErrors] = useState({
    emailAddress: "",
  });
  const [tenantID,setTenantId]=useState(null)
  const [companyDetail,setCompanyDetail]=useState(null)
  const [domain,setDomain]=useState("")
  const [address,setAddress]=useState("")
  const [linkedInJSON , setLinkedInJSON] = useState({});

  const steps = ["Contact Details", "Custom Message"];


  const EMAIL_ADDRESS_REGEX = 
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

const validateEmail = (formData) => {
  if (!formData?.emailAddress?.trim()) {
    return { hasError: true, errorMessage: "Email address is required" };
  }
  if (!EMAIL_ADDRESS_REGEX.test(formData.emailAddress)) {
    return { hasError: true, errorMessage: "The value must be a valid email address" };
  }
  return { hasError: false, errorMessage: "" };
};


React.useEffect(() => {
  const fetchData = async () => {
    try {
      const isAdmin = await isAdminAuth0();
      const selectedTenant = getTenantIdFromURL();
      const tenantId = isAdmin ? selectedTenant : await getTenantIdAuth0();
      setTenantId(tenantId);
      if (navigationFrom === 'table' && open) {
        const result = await API.graphql(
          graphqlOperation(companiesByName, {
            name: companyInfo.name,
            limit: 1,
            filter: {
              tenantId: {
                eq: tenantId,
              },
            },
          })
        );
        if (result?.data?.companiesByName?.items?.length > 0) {
          const data = result.data.companiesByName.items[0];
          setCompanyDetail(data);

          const linkedInData = await getLinkedInFile(data);
          const WikiInfobox = data.wikipedia?.infobox
            ? JSON.parse(data.wikipedia.infobox)
            : {};

          const google = data.googleSearch?.knowledgeGraph?.raw
            ? JSON.parse(data.googleSearch.knowledgeGraph.raw)
            : {};

          const website = linkedInData?.website || google?.website || "";
          setDomain(website);

          const location =
            google?.headquarters ||
            data?.country ||
            (WikiInfobox?.hq_location_country?.manual === true
              ? `${WikiInfobox.hq_location_country.value}, ${WikiInfobox.hq_location_city.value}`
              : WikiInfobox?.hq_location_country
              ? `${WikiInfobox.hq_location_country}, ${WikiInfobox.hq_location_city}`
              : "");

          setAddress(location || "");
        } else {
          console.log("No company data found.");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  if (open) {
   
    fetchData();
  }
}, [open, navigationFrom, companyInfo]);



console.log("open value",open)
const getLinkedInFile = async (companyData1) => {
  if(companyData1?.linkedInFileName){
  try {
    const key = companyData1?.linkedInFileName;
    const bucket = "crediarc-linkedin-data";
    const linkedInFile = await fetchFileFromS3(key, bucket);
    console.log("linkedIn File",linkedInFile)
    setLinkedInJSON(linkedInFile);
    return linkedInFile
  } catch (error) {
    console.log(error);
    return null;
  }
}
};


const makeGraphqlRequest = async (action, companyId = "", page = "", body = "") => {
  if (action === "getCompanyByID") {
    console.log("Mocking getCompanyByID...");
    return {
      data: {
        handleRequestSuiteDash: JSON.stringify({
          statusCode: 404,
          body: JSON.stringify({
            message: "Company not found.",
          }),
        }),
      },
    };
  }

  if (action === "createCompany") {
    console.log("Mocking createCompany...");
    return {
      data: {
        handleRequestSuiteDash: JSON.stringify({
          statusCode: 200,
          body: JSON.stringify({
            message: "Company created successfully.",
            data: { companyId: "mockCompanyId" },
          }),
        }),
      },
    };
  }
  if (action === "updateCompany") {
    console.log("Mocking updateCompany...");
    return {
      data: {
        handleRequestSuiteDash: JSON.stringify({
          statusCode: 200,
          body: JSON.stringify({
            message: "Company updateCompany successfully.",
            data: { companyId: "mockCompanyId" },
          }),
        }),
      },
    };
  }

  throw new Error(`Unhandled action: ${action}`);
};


const handleNext = async () => {
  try {
    const tenantData = await getTenantInfo();
    const companyName = companyInfo.name;
    const createCompanyField = () => {
      if (navigationFrom === 'table') {
        return { id: companyDetail.id, companyName: companyInfo.name, domain: domain, address: address };
      } else {
        return { id: companyInfo.id, companyName: companyInfo.name, domain: companyInfo.domain, address: companyInfo.address };
      }
    };

    if (activeStep === 1) {
      const companyResponse = await makeGraphqlRequest("getCompanyByID", companyName);
      const parsedResp = JSON.parse(companyResponse.data.handleRequestSuiteDash);
      
      if (parsedResp?.statusCode === 404) {
        const body = JSON.parse(parsedResp.body);
        if (body.message === "Company not found.") {
          const newCompanyData = {
            name: companyName,
            role: "Client",
            primaryContact: {
              first_name: formData.firstName,
              last_name: formData.lastName,
              email: formData.emailAddress,
              send_welcome_email: true,
              create_primary_contact_if_not_exists: true,
              prevent_individual_mode: true,
            },
          };
          
          const requestFieldToBesend = { tenantId: tenantID, fileType: fileType, tenantName: tenantData.name, message: formData.customMessage };
          console.log("requestFieldToBesend",requestFieldToBesend)
          const updateCustomField = {
            public_custom_fields: {
              "148e6cb1-d4ee-4d4e-b5a4-97901a66e848": JSON.stringify([requestFieldToBesend]),
              "6ef3e16d-d189-40eb-8005-42d59b257c0d": JSON.stringify(createCompanyField()),
            },
          };

          // Step 4: Create and update company in a single flow
          const successresp=await makeGraphqlRequest("createCompany", "", "", newCompanyData);
          console.log("success response from create com",successresp)
          const check=successresp?.data?.handleRequestSuiteDash
          if(check){
            const getResponse=JSON.parse(check)
            if(getResponse.statusCode===200){
              const body=JSON.parse(getResponse.body)
              console.log("body---->",body)
              const updateresp=await makeGraphqlRequest("updateCompany", companyName, "", updateCustomField);
              console.log("updateresp response ",updateresp)
              await API.graphql(
                graphqlOperation(triggerSendMitigationEmail, {
                  tenantName: tenantData?.name,
                  companyName: companyName,
                  customMessage: formData?.customMessage,
                  fullName: formData?.firstName,
                  email: formData?.emailAddress,
                  mitigationType : fileType
                })
              );
              toast.success(`Request to ${companyName} has been sent`);
            }
          }
          
        }
      } else if (parsedResp?.statusCode === 200) {
        const companyData = JSON.parse(parsedResp.body).data;
        const requestFieldToBesend = { tenantId: tenantID, fileType: fileType, tenantName: tenantData.name, message: formData.customMessage };
        const customField = companyData?.data?.public_custom_fields || {};
        const existingField = customField["148e6cb1-d4ee-4d4e-b5a4-97901a66e848"] ? customField["148e6cb1-d4ee-4d4e-b5a4-97901a66e848"] : [];
        console.log("existing field",existingField)
        existingField.push(requestFieldToBesend);
        
        const updateFields = {
          public_custom_fields: {
            "148e6cb1-d4ee-4d4e-b5a4-97901a66e848": JSON.stringify(existingField),
            "6ef3e16d-d189-40eb-8005-42d59b257c0d": JSON.stringify(createCompanyField()),
          },
        };

        await makeGraphqlRequest("updateCompany", companyName, "", updateFields);
        await API.graphql(
          graphqlOperation(triggerSendMitigationEmail, {
            tenantName: tenantData?.name,
            companyName: companyName,
            customMessage: formData?.customMessage,
            fullName: formData?.firstName,
            email: formData?.emailAddress,
            mitigationType : fileType
          })
        );
        toast.success(`Request to ${companyName} has been sent`);
      } else {
        toast.error("Unexpected result fetching company details.");
        console.error("Unexpected status code:", parsedResp.statusCode);
      }

      handleClose(); // Close modal or stepper
    } else {
      // Form validation for email address
      const validationResult = validateEmail(formData);
      if (validationResult.hasError) {
        setErrors({ emailAddress: validationResult.errorMessage });
        toast.error(validationResult.errorMessage);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  } catch (error) {
    toast.error("An error occurred while processing the request.");
    console.error("Error in handleNext:", error);
  }
};




  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClose = () => {
    onClose();
    setActiveStep(0);
    setFormData({
      firstName: "",
      emailAddress: "",
      lastName: "",
      phoneNumber: "",
      customMessage: "",
    });
  };

  const toTitleCase = (str) => {
    str = `Send ${fileType} Request To ` + str;
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  function ColorlibStepIcon(props) {
    const { active, completed, className, icon } = props;

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {completed ? (
          <img src={Tick} width={25} alt="" />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: active ? "#fff" : "#777A82",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              {icon}
            </span>
          </div>
        )}
      </ColorlibStepIconRoot>
    );
  }

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                margin="normal"
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                margin="normal"
                label="Email Address"
                name="emailAddress"
                value={formData.emailAddress}
                onChange={handleChange}
                required
                error={!!errors.emailAddress}
                helperText={errors.emailAddress}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                margin="normal"
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                margin="normal"
                label="Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Do you want to add a personal message?
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              label="Custom Message"
              name="customMessage"
              value={formData.customMessage}
              onChange={handleChange}
              multiline
              rows={4}
            />
          </Box>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="contact-modal-title"
      aria-describedby="contact-modal-description"
      sx={{
        backdropFilter: "blur(2px)",
        backgroundColor: "rgb(255, 255, 255, 0.5)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
        onClick={(event) => {
          if (event.target === event.currentTarget) {
            handleClose();
          }
        }}
      >
        <Box
          sx={{
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "#2F3D63",
            borderRadius: "20px",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            id="modal-modal-title"
            component="h2"
            sx={{
              textAlign: "center",
              padding: 2,
              color: "#2F3D63",
            }}
          >
            {toTitleCase(companyInfo.name)}
          </Typography>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            connector={<ColorlibConnector />}
            style={{
              background: "#5186EC1A",
              height: "82px",
              width: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  icon={index + 1}
                ></StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ mt: 2, width: "100%" }}>
            {renderStepContent(activeStep)}
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                variant="contained"
                sx={{
                  mr: 1,
                  width: "132px",
                  height: "35px",
                  borderRadius: "8px",
                  backgroundColor: "#5186EC",
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                sx={{
                  width: "132px",
                  height: "35px",
                  borderRadius: "8px",
                  backgroundColor: "#5186EC",
                }}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SendRequestModal;