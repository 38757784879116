export const safeJSONParse = (data) => {
  try {
    if (typeof data === "object") return data;
    let parsed = data;
    while (typeof parsed === "string") {
      try {
        parsed = JSON.parse(parsed);
      } catch {
        break;
      }
    }
    return parsed;
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return null;
  }
};

export const getCompanyTreeData = (treeData) => {
  let data = safeJSONParse(treeData);

  if (!data) {
    return {
      data: null,
      treeNotExist: true,
      hasKeyStaff: false,
      hasParentCompany: false,
      hasSubsidiaries: false,
      hasRelatedCompanies: false,
      hasCompetitors: false,
      hasRelatedPeople: false,
    };
  }

  const hasKeyStaff =
    Array.isArray(data.key_staff) &&
    data.key_staff.length > 0 &&
    data.key_staff.some((staff) => staff.full_name || staff.position);

  const hasParentCompany = Object.values(data.parent_company || {}).some(
    (value) => value && value !== ""
  );

  const hasSubsidiaries =
    Array.isArray(data.subsidiaries) &&
    data.subsidiaries.length > 0 &&
    data.subsidiaries.some(
      (sub) =>
        sub.name ||
        sub.country ||
        sub.industry ||
        sub.ownership_percentage ||
        sub.revenue ||
        sub.number_of_employees
    );

  const hasRelatedCompanies =
    Array.isArray(data.related_companies) &&
    data.related_companies.length > 0 &&
    data.related_companies.some(
      (company) => company.name || company.country || company.relationship_type
    );

  const hasCompetitors =
    Array.isArray(data.competitors) &&
    data.competitors.length > 0 &&
    data.competitors.some((comp) => comp.name || comp.country);

  const hasRelatedPeople =
    Array.isArray(data.related_people) &&
    data.related_people.length > 0 &&
    data.related_people.some(
      (person) => person.full_name || person.relationship_type
    );

  const hasAnyData =
    hasKeyStaff ||
    hasParentCompany ||
    hasSubsidiaries ||
    hasRelatedCompanies ||
    hasCompetitors ||
    hasRelatedPeople;

  const treeNotExist = !hasAnyData;

  return {
    data,
    treeNotExist,
    hasKeyStaff,
    hasParentCompany,
    hasSubsidiaries,
    hasRelatedCompanies,
    hasCompetitors,
    hasRelatedPeople,
  };
};
