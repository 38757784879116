import React, { useState } from 'react';
import { 
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getCompanyTreeData, safeJSONParse } from './getCompanyTreeData';

const CustomIcon = () => (
  <Box
    sx={{
      width: 30,
      height: 30,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.29)',
    }}
  >
    <ExpandMoreIcon style={{ color: "#5186EC" }} />
  </Box>
);

const SectionHeader = ({ title, isExpanded, onClick }) => (
  <Box 
    onClick={onClick}
    sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      cursor: 'pointer',
      marginBottom: '16px'
    }}
  >
    <Typography sx={{ marginRight: '8px', color: '#A2A2A2', fontSize: '20px' }}>
      {isExpanded ? '-' : '+'}
    </Typography>
    <Typography
      style={{
        fontSize: "16px",
        color: "#1A2A56",
        fontWeight: "500",
      }}
    >
      {title}
    </Typography>
  </Box>
);

const InfoRow = ({ label, value }) => {
  if (!value || value === 'undefined' || value === 'NaN' || value === 'N/A' || value === '') {
    return null;
  }
  
  return (
    <Box display="flex" gap={2} mb={1}>
      <Typography color="textSecondary" style={{ minWidth: '150px' }}>
        {label}:
      </Typography>
      <Typography>{value}</Typography>
    </Box>
  );
};

const CompanyTree = ({ treeData }) => {
  const data = safeJSONParse(treeData)
  const checkExist = getCompanyTreeData(treeData)
  const [expandedSections, setExpandedSections] = useState({
    companyInfo: false,
    keyStaff: false,
    parent: false,
    subsidiaries: false,
    related: false,
    competitors: false,
    relatedPeople: false
  });
  
  const {
    hasKeyStaff,
    hasParentCompany,
    hasSubsidiaries,
    hasRelatedCompanies,
    hasCompetitors,
    hasRelatedPeople,
    treeNotExist,
  } = checkExist;

  if (treeNotExist) {
    return null;
  }


  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const formatRevenue = (revenue) => {
    if (!revenue) return 'N/A';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      notation: 'compact',
      maximumFractionDigits: 1
    }).format(revenue);
  };

  const formatEmployees = (employees) => {
    if (!employees) return 'N/A';
    return new Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    }).format(employees);
  };

  return (
    <div style={{padding:"0 25px",marginBottom:"25px"}}>
    <Accordion
      defaultExpanded
      sx={{
        background: "#FAFAFC",
        boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
        borderRadius: "8px",
        opacity: "1",
        marginTop: "10px",
      }}
    >
      <AccordionSummary expandIcon={<CustomIcon />}>
        <Typography
          style={{
            fontSize: "20px",
            color: "#1A2A56",
            fontWeight: "500",
          }}
        >
          Company Tree
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {hasKeyStaff && (
          <Box sx={{ marginBottom: 0 }}>
            <SectionHeader 
              title="Key Staff" 
              isExpanded={expandedSections.keyStaff}
              onClick={() => toggleSection('keyStaff')}
            />
            {expandedSections.keyStaff && (
              <Box sx={{ padding: "10px 0 10px 20px",backgroundColor:"#F5F5F7", borderRadius:"10px" }}>
                {data.key_staff.map((staff, index) => (
                  <Box key={index} mb={index !== data.key_staff.length - 1 ? 3 : 0}>
                    <InfoRow label="Name" value={staff.full_name} />
                    <InfoRow label="Position" value={staff.position} />
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}

        {hasParentCompany && (
          <Box sx={{ marginBottom: 0 }}>
            <SectionHeader 
              title="Parent Company" 
              isExpanded={expandedSections.parent}
              onClick={() => toggleSection('parent')}
            />
            {expandedSections.parent && (
              <Box sx={{ padding: "10px 0 10px 20px",backgroundColor:"#F5F5F7", borderRadius:"10px" }}>
                <InfoRow label="Name" value={data.parent_company.name} />
                <InfoRow label="Country" value={data.parent_company.country} />
                <InfoRow label="Industry" value={data.parent_company.industry} />
                <InfoRow label="Revenue" value={formatRevenue(data.parent_company.revenue)} />
                <InfoRow label="Number of Employees" value={formatEmployees(data.parent_company.number_of_employees)} />
              </Box>
            )}
          </Box>
        )}

        {hasSubsidiaries && (
          <Box sx={{ marginBottom: 0 }}>
            <SectionHeader 
              title="Subsidiaries" 
              isExpanded={expandedSections.subsidiaries}
              onClick={() => toggleSection('subsidiaries')}
            />
            {expandedSections.subsidiaries && (
              <Box sx={{ padding: "10px 0 10px 20px",backgroundColor:"#F5F5F7", borderRadius:"10px" }}>
                {data.subsidiaries.map((subsidiary, index) => (
                  <Box key={index} mb={index !== data.subsidiaries.length - 1 ? 3 : 0}>
                    {subsidiary.name && <InfoRow label="Name" value={subsidiary.name} />}
                    {subsidiary.country && <InfoRow label="Country" value={subsidiary.country} />}
                    {subsidiary.industry && <InfoRow label="Industry" value={subsidiary.industry} />}
                    {subsidiary.ownership_percentage && (
                      <InfoRow label="Ownership %" value={`${subsidiary.ownership_percentage}%`} />
                    )}
                    {subsidiary.revenue && <InfoRow label="Revenue" value={formatRevenue(subsidiary.revenue)} />}
                    {subsidiary.number_of_employees && (
                      <InfoRow label="Number of Employees" value={formatEmployees(subsidiary.number_of_employees)} />
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}

        {hasRelatedCompanies && (
          <Box sx={{ marginBottom: 0 }}>
            <SectionHeader 
              title="Related Companies" 
              isExpanded={expandedSections.related}
              onClick={() => toggleSection('related')}
            />
            {expandedSections.related && (
              <Box sx={{ padding: "10px 0 10px 20px",backgroundColor:"#F5F5F7", borderRadius:"10px" }}>
                {data.related_companies.map((company, index) => (
                  <Box key={index} mb={index !== data.related_companies.length - 1 ? 3 : 0}>
                    {company.name && <InfoRow label="Name" value={company.name} />}
                    {company.country && <InfoRow label="Country" value={company.country} />}
                    {company.relationship_type && <InfoRow label="Relationship" value={company.relationship_type} />}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}

        {hasCompetitors && (
          <Box sx={{ marginBottom: 0 }}>
            <SectionHeader 
              title="Competitors" 
              isExpanded={expandedSections.competitors}
              onClick={() => toggleSection('competitors')}
            />
            {expandedSections.competitors && (
              <Box sx={{ padding: "10px 0 10px 20px",backgroundColor:"#F5F5F7", borderRadius:"10px"}}>
                {data.competitors.map((competitor, index) => (
                  <Box key={index} mb={index !== data.competitors.length - 1 ? 3 : 0}>
                    {competitor.name && <InfoRow label="Name" value={competitor.name} />}
                    {competitor.country && <InfoRow label="Country" value={competitor.country} />}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}

        {hasRelatedPeople && (
          <Box sx={{ marginBottom: 0 }}>
            <SectionHeader 
              title="Related People" 
              isExpanded={expandedSections.relatedPeople}
              onClick={() => toggleSection('relatedPeople')}
            />
            {expandedSections.relatedPeople && (
              <Box sx={{ padding: "10px 0 10px 20px",backgroundColor:"#F5F5F7", borderRadius:"10px" }}>
                {data.related_people.map((person, index) => (
                  <Box key={index} mb={index !== data.related_people.length - 1 ? 3 : 0}>
                    {person.full_name && <InfoRow label="Name" value={person.full_name} />}
                    {person.relationship_type && <InfoRow label="Relationship" value={person.relationship_type} />}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
    </div>
  );
};

export default CompanyTree; 