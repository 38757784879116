/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const notifyUser = /* GraphQL */ `
  mutation NotifyUser($input: NotifyUserInput!) {
    notifyUser(input: $input) {
      message
      tenantId
      companyName
      __typename
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      tenantId
      id
      name
      country
      ticker
      googleSearch {
        tenantId
        id
        knowledgeGraph {
          tenantId
          id
          title
          type
          kgmid
          knowledgeGraphSearchLink
          serpapiKnowledgeGraphSearchLink
          website
          rating
          reviewCount
          address
          phone
          relatedQuestions {
            items {
              tenantId
              id
              question
              snippet
              title
              link
              displayedLink
              thumbnail
              nextPageToken
              serpapiLink
              createdAt
              updatedAt
              knowledgeGraphRelatedQuestionsTenantId
              knowledgeGraphRelatedQuestionsId
              __typename
            }
            nextToken
            __typename
          }
          raw
          createdAt
          updatedAt
          __typename
        }
        organicResult {
          items {
            tenantId
            id
            position
            title
            link
            displayed_link
            favicon
            snippet
            snippet_highlighted_words
            source
            createdAt
            updatedAt
            googleSearchOrganicResultTenantId
            googleSearchOrganicResultId
            __typename
          }
          nextToken
          __typename
        }
        news {
          items {
            tenantId
            id
            position
            link
            title
            source
            date
            snippet
            thumbnail
            createdAt
            updatedAt
            googleSearchNewsTenantId
            googleSearchNewsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        googleSearchKnowledgeGraphTenantId
        googleSearchKnowledgeGraphId
        __typename
      }
      wikipedia {
        tenantId
        id
        content
        infobox
        traded
        createdAt
        updatedAt
        __typename
      }
      yahooFinance {
        tenantId
        id
        info
        actions
        dividends
        splits
        capital_gains
        shares
        income_stmt
        quarterly_income_stmt
        balance_sheet
        quarterly_balance_sheet
        cashflow
        quarterly_cashflow
        history
        major_holders
        institutional_holders
        mutualfund_holders
        earnings_dates
        isin
        options
        news
        createdAt
        updatedAt
        __typename
      }
      customSource
      dnbCompanyId
      requestedCreditLimit
      view
      questions {
        items {
          tenantId
          id
          question
          answer
          summary
          citations {
            items {
              tenantId
              id
              text
              link
              date
              createdAt
              updatedAt
              companyQuestionCitationsTenantId
              companyQuestionCitationsId
              __typename
            }
            nextToken
            __typename
          }
          template {
            tenantId
            id
            question
            position
            inRisk
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          companyQuestionsTenantId
          companyQuestionsId
          companyQuestionTemplateTenantId
          companyQuestionTemplateId
          __typename
        }
        nextToken
        __typename
      }
      parentCompanyName
      parentDnbCompanyId
      parentCountry
      parentTicker
      summary
      companyJsonData
      isManuallyAdded
      agingFileName
      lastOpenedAt
      manualSource
      linkedInFileName
      companyTreeData
      accountID
      createdAt
      updatedAt
      companyGoogleSearchTenantId
      companyGoogleSearchId
      companyWikipediaTenantId
      companyWikipediaId
      companyYahooFinanceTenantId
      companyYahooFinanceId
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      tenantId
      id
      name
      country
      ticker
      googleSearch {
        tenantId
        id
        knowledgeGraph {
          tenantId
          id
          title
          type
          kgmid
          knowledgeGraphSearchLink
          serpapiKnowledgeGraphSearchLink
          website
          rating
          reviewCount
          address
          phone
          relatedQuestions {
            items {
              tenantId
              id
              question
              snippet
              title
              link
              displayedLink
              thumbnail
              nextPageToken
              serpapiLink
              createdAt
              updatedAt
              knowledgeGraphRelatedQuestionsTenantId
              knowledgeGraphRelatedQuestionsId
              __typename
            }
            nextToken
            __typename
          }
          raw
          createdAt
          updatedAt
          __typename
        }
        organicResult {
          items {
            tenantId
            id
            position
            title
            link
            displayed_link
            favicon
            snippet
            snippet_highlighted_words
            source
            createdAt
            updatedAt
            googleSearchOrganicResultTenantId
            googleSearchOrganicResultId
            __typename
          }
          nextToken
          __typename
        }
        news {
          items {
            tenantId
            id
            position
            link
            title
            source
            date
            snippet
            thumbnail
            createdAt
            updatedAt
            googleSearchNewsTenantId
            googleSearchNewsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        googleSearchKnowledgeGraphTenantId
        googleSearchKnowledgeGraphId
        __typename
      }
      wikipedia {
        tenantId
        id
        content
        infobox
        traded
        createdAt
        updatedAt
        __typename
      }
      yahooFinance {
        tenantId
        id
        info
        actions
        dividends
        splits
        capital_gains
        shares
        income_stmt
        quarterly_income_stmt
        balance_sheet
        quarterly_balance_sheet
        cashflow
        quarterly_cashflow
        history
        major_holders
        institutional_holders
        mutualfund_holders
        earnings_dates
        isin
        options
        news
        createdAt
        updatedAt
        __typename
      }
      customSource
      dnbCompanyId
      requestedCreditLimit
      view
      questions {
        items {
          tenantId
          id
          question
          answer
          summary
          citations {
            items {
              tenantId
              id
              text
              link
              date
              createdAt
              updatedAt
              companyQuestionCitationsTenantId
              companyQuestionCitationsId
              __typename
            }
            nextToken
            __typename
          }
          template {
            tenantId
            id
            question
            position
            inRisk
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          companyQuestionsTenantId
          companyQuestionsId
          companyQuestionTemplateTenantId
          companyQuestionTemplateId
          __typename
        }
        nextToken
        __typename
      }
      parentCompanyName
      parentDnbCompanyId
      parentCountry
      parentTicker
      summary
      companyJsonData
      isManuallyAdded
      agingFileName
      lastOpenedAt
      manualSource
      linkedInFileName
      companyTreeData
      accountID
      createdAt
      updatedAt
      companyGoogleSearchTenantId
      companyGoogleSearchId
      companyWikipediaTenantId
      companyWikipediaId
      companyYahooFinanceTenantId
      companyYahooFinanceId
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      tenantId
      id
      name
      country
      ticker
      googleSearch {
        tenantId
        id
        knowledgeGraph {
          tenantId
          id
          title
          type
          kgmid
          knowledgeGraphSearchLink
          serpapiKnowledgeGraphSearchLink
          website
          rating
          reviewCount
          address
          phone
          relatedQuestions {
            items {
              tenantId
              id
              question
              snippet
              title
              link
              displayedLink
              thumbnail
              nextPageToken
              serpapiLink
              createdAt
              updatedAt
              knowledgeGraphRelatedQuestionsTenantId
              knowledgeGraphRelatedQuestionsId
              __typename
            }
            nextToken
            __typename
          }
          raw
          createdAt
          updatedAt
          __typename
        }
        organicResult {
          items {
            tenantId
            id
            position
            title
            link
            displayed_link
            favicon
            snippet
            snippet_highlighted_words
            source
            createdAt
            updatedAt
            googleSearchOrganicResultTenantId
            googleSearchOrganicResultId
            __typename
          }
          nextToken
          __typename
        }
        news {
          items {
            tenantId
            id
            position
            link
            title
            source
            date
            snippet
            thumbnail
            createdAt
            updatedAt
            googleSearchNewsTenantId
            googleSearchNewsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        googleSearchKnowledgeGraphTenantId
        googleSearchKnowledgeGraphId
        __typename
      }
      wikipedia {
        tenantId
        id
        content
        infobox
        traded
        createdAt
        updatedAt
        __typename
      }
      yahooFinance {
        tenantId
        id
        info
        actions
        dividends
        splits
        capital_gains
        shares
        income_stmt
        quarterly_income_stmt
        balance_sheet
        quarterly_balance_sheet
        cashflow
        quarterly_cashflow
        history
        major_holders
        institutional_holders
        mutualfund_holders
        earnings_dates
        isin
        options
        news
        createdAt
        updatedAt
        __typename
      }
      customSource
      dnbCompanyId
      requestedCreditLimit
      view
      questions {
        items {
          tenantId
          id
          question
          answer
          summary
          citations {
            items {
              tenantId
              id
              text
              link
              date
              createdAt
              updatedAt
              companyQuestionCitationsTenantId
              companyQuestionCitationsId
              __typename
            }
            nextToken
            __typename
          }
          template {
            tenantId
            id
            question
            position
            inRisk
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          companyQuestionsTenantId
          companyQuestionsId
          companyQuestionTemplateTenantId
          companyQuestionTemplateId
          __typename
        }
        nextToken
        __typename
      }
      parentCompanyName
      parentDnbCompanyId
      parentCountry
      parentTicker
      summary
      companyJsonData
      isManuallyAdded
      agingFileName
      lastOpenedAt
      manualSource
      linkedInFileName
      companyTreeData
      accountID
      createdAt
      updatedAt
      companyGoogleSearchTenantId
      companyGoogleSearchId
      companyWikipediaTenantId
      companyWikipediaId
      companyYahooFinanceTenantId
      companyYahooFinanceId
      __typename
    }
  }
`;
export const createCompanySummaryRecord = /* GraphQL */ `
  mutation CreateCompanySummaryRecord(
    $input: CreateCompanySummaryRecordInput!
    $condition: ModelCompanySummaryRecordConditionInput
  ) {
    createCompanySummaryRecord(input: $input, condition: $condition) {
      tenantId
      companyId
      accountId
      companyName
      summaryData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompanySummaryRecord = /* GraphQL */ `
  mutation UpdateCompanySummaryRecord(
    $input: UpdateCompanySummaryRecordInput!
    $condition: ModelCompanySummaryRecordConditionInput
  ) {
    updateCompanySummaryRecord(input: $input, condition: $condition) {
      tenantId
      companyId
      accountId
      companyName
      summaryData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompanySummaryRecord = /* GraphQL */ `
  mutation DeleteCompanySummaryRecord(
    $input: DeleteCompanySummaryRecordInput!
    $condition: ModelCompanySummaryRecordConditionInput
  ) {
    deleteCompanySummaryRecord(input: $input, condition: $condition) {
      tenantId
      companyId
      accountId
      companyName
      summaryData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLastVisitedCompanies = /* GraphQL */ `
  mutation CreateLastVisitedCompanies(
    $input: CreateLastVisitedCompaniesInput!
    $condition: ModelLastVisitedCompaniesConditionInput
  ) {
    createLastVisitedCompanies(input: $input, condition: $condition) {
      tenantId
      id
      lastOpenedAt
      companyId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLastVisitedCompanies = /* GraphQL */ `
  mutation UpdateLastVisitedCompanies(
    $input: UpdateLastVisitedCompaniesInput!
    $condition: ModelLastVisitedCompaniesConditionInput
  ) {
    updateLastVisitedCompanies(input: $input, condition: $condition) {
      tenantId
      id
      lastOpenedAt
      companyId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLastVisitedCompanies = /* GraphQL */ `
  mutation DeleteLastVisitedCompanies(
    $input: DeleteLastVisitedCompaniesInput!
    $condition: ModelLastVisitedCompaniesConditionInput
  ) {
    deleteLastVisitedCompanies(input: $input, condition: $condition) {
      tenantId
      id
      lastOpenedAt
      companyId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompanyRecord = /* GraphQL */ `
  mutation CreateCompanyRecord(
    $input: CreateCompanyRecordInput!
    $condition: ModelCompanyRecordConditionInput
  ) {
    createCompanyRecord(input: $input, condition: $condition) {
      tenantId
      id
      name
      companyInfo
      summary
      view
      originalItemUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompanyRecord = /* GraphQL */ `
  mutation UpdateCompanyRecord(
    $input: UpdateCompanyRecordInput!
    $condition: ModelCompanyRecordConditionInput
  ) {
    updateCompanyRecord(input: $input, condition: $condition) {
      tenantId
      id
      name
      companyInfo
      summary
      view
      originalItemUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompanyRecord = /* GraphQL */ `
  mutation DeleteCompanyRecord(
    $input: DeleteCompanyRecordInput!
    $condition: ModelCompanyRecordConditionInput
  ) {
    deleteCompanyRecord(input: $input, condition: $condition) {
      tenantId
      id
      name
      companyInfo
      summary
      view
      originalItemUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNewCompanyRecord = /* GraphQL */ `
  mutation CreateNewCompanyRecord(
    $input: CreateNewCompanyRecordInput!
    $condition: ModelNewCompanyRecordConditionInput
  ) {
    createNewCompanyRecord(input: $input, condition: $condition) {
      tenantId
      id
      name
      companyInfo
      summary
      view
      originalItemUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNewCompanyRecord = /* GraphQL */ `
  mutation UpdateNewCompanyRecord(
    $input: UpdateNewCompanyRecordInput!
    $condition: ModelNewCompanyRecordConditionInput
  ) {
    updateNewCompanyRecord(input: $input, condition: $condition) {
      tenantId
      id
      name
      companyInfo
      summary
      view
      originalItemUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNewCompanyRecord = /* GraphQL */ `
  mutation DeleteNewCompanyRecord(
    $input: DeleteNewCompanyRecordInput!
    $condition: ModelNewCompanyRecordConditionInput
  ) {
    deleteNewCompanyRecord(input: $input, condition: $condition) {
      tenantId
      id
      name
      companyInfo
      summary
      view
      originalItemUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGoogleSearch = /* GraphQL */ `
  mutation CreateGoogleSearch(
    $input: CreateGoogleSearchInput!
    $condition: ModelGoogleSearchConditionInput
  ) {
    createGoogleSearch(input: $input, condition: $condition) {
      tenantId
      id
      knowledgeGraph {
        tenantId
        id
        title
        type
        kgmid
        knowledgeGraphSearchLink
        serpapiKnowledgeGraphSearchLink
        website
        rating
        reviewCount
        address
        phone
        relatedQuestions {
          items {
            tenantId
            id
            question
            snippet
            title
            link
            displayedLink
            thumbnail
            nextPageToken
            serpapiLink
            createdAt
            updatedAt
            knowledgeGraphRelatedQuestionsTenantId
            knowledgeGraphRelatedQuestionsId
            __typename
          }
          nextToken
          __typename
        }
        raw
        createdAt
        updatedAt
        __typename
      }
      organicResult {
        items {
          tenantId
          id
          position
          title
          link
          displayed_link
          favicon
          snippet
          snippet_highlighted_words
          source
          createdAt
          updatedAt
          googleSearchOrganicResultTenantId
          googleSearchOrganicResultId
          __typename
        }
        nextToken
        __typename
      }
      news {
        items {
          tenantId
          id
          position
          link
          title
          source
          date
          snippet
          thumbnail
          createdAt
          updatedAt
          googleSearchNewsTenantId
          googleSearchNewsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      googleSearchKnowledgeGraphTenantId
      googleSearchKnowledgeGraphId
      __typename
    }
  }
`;
export const updateGoogleSearch = /* GraphQL */ `
  mutation UpdateGoogleSearch(
    $input: UpdateGoogleSearchInput!
    $condition: ModelGoogleSearchConditionInput
  ) {
    updateGoogleSearch(input: $input, condition: $condition) {
      tenantId
      id
      knowledgeGraph {
        tenantId
        id
        title
        type
        kgmid
        knowledgeGraphSearchLink
        serpapiKnowledgeGraphSearchLink
        website
        rating
        reviewCount
        address
        phone
        relatedQuestions {
          items {
            tenantId
            id
            question
            snippet
            title
            link
            displayedLink
            thumbnail
            nextPageToken
            serpapiLink
            createdAt
            updatedAt
            knowledgeGraphRelatedQuestionsTenantId
            knowledgeGraphRelatedQuestionsId
            __typename
          }
          nextToken
          __typename
        }
        raw
        createdAt
        updatedAt
        __typename
      }
      organicResult {
        items {
          tenantId
          id
          position
          title
          link
          displayed_link
          favicon
          snippet
          snippet_highlighted_words
          source
          createdAt
          updatedAt
          googleSearchOrganicResultTenantId
          googleSearchOrganicResultId
          __typename
        }
        nextToken
        __typename
      }
      news {
        items {
          tenantId
          id
          position
          link
          title
          source
          date
          snippet
          thumbnail
          createdAt
          updatedAt
          googleSearchNewsTenantId
          googleSearchNewsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      googleSearchKnowledgeGraphTenantId
      googleSearchKnowledgeGraphId
      __typename
    }
  }
`;
export const deleteGoogleSearch = /* GraphQL */ `
  mutation DeleteGoogleSearch(
    $input: DeleteGoogleSearchInput!
    $condition: ModelGoogleSearchConditionInput
  ) {
    deleteGoogleSearch(input: $input, condition: $condition) {
      tenantId
      id
      knowledgeGraph {
        tenantId
        id
        title
        type
        kgmid
        knowledgeGraphSearchLink
        serpapiKnowledgeGraphSearchLink
        website
        rating
        reviewCount
        address
        phone
        relatedQuestions {
          items {
            tenantId
            id
            question
            snippet
            title
            link
            displayedLink
            thumbnail
            nextPageToken
            serpapiLink
            createdAt
            updatedAt
            knowledgeGraphRelatedQuestionsTenantId
            knowledgeGraphRelatedQuestionsId
            __typename
          }
          nextToken
          __typename
        }
        raw
        createdAt
        updatedAt
        __typename
      }
      organicResult {
        items {
          tenantId
          id
          position
          title
          link
          displayed_link
          favicon
          snippet
          snippet_highlighted_words
          source
          createdAt
          updatedAt
          googleSearchOrganicResultTenantId
          googleSearchOrganicResultId
          __typename
        }
        nextToken
        __typename
      }
      news {
        items {
          tenantId
          id
          position
          link
          title
          source
          date
          snippet
          thumbnail
          createdAt
          updatedAt
          googleSearchNewsTenantId
          googleSearchNewsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      googleSearchKnowledgeGraphTenantId
      googleSearchKnowledgeGraphId
      __typename
    }
  }
`;
export const createWikipedia = /* GraphQL */ `
  mutation CreateWikipedia(
    $input: CreateWikipediaInput!
    $condition: ModelWikipediaConditionInput
  ) {
    createWikipedia(input: $input, condition: $condition) {
      tenantId
      id
      content
      infobox
      traded
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWikipedia = /* GraphQL */ `
  mutation UpdateWikipedia(
    $input: UpdateWikipediaInput!
    $condition: ModelWikipediaConditionInput
  ) {
    updateWikipedia(input: $input, condition: $condition) {
      tenantId
      id
      content
      infobox
      traded
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWikipedia = /* GraphQL */ `
  mutation DeleteWikipedia(
    $input: DeleteWikipediaInput!
    $condition: ModelWikipediaConditionInput
  ) {
    deleteWikipedia(input: $input, condition: $condition) {
      tenantId
      id
      content
      infobox
      traded
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createYahooFinance = /* GraphQL */ `
  mutation CreateYahooFinance(
    $input: CreateYahooFinanceInput!
    $condition: ModelYahooFinanceConditionInput
  ) {
    createYahooFinance(input: $input, condition: $condition) {
      tenantId
      id
      info
      actions
      dividends
      splits
      capital_gains
      shares
      income_stmt
      quarterly_income_stmt
      balance_sheet
      quarterly_balance_sheet
      cashflow
      quarterly_cashflow
      history
      major_holders
      institutional_holders
      mutualfund_holders
      earnings_dates
      isin
      options
      news
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateYahooFinance = /* GraphQL */ `
  mutation UpdateYahooFinance(
    $input: UpdateYahooFinanceInput!
    $condition: ModelYahooFinanceConditionInput
  ) {
    updateYahooFinance(input: $input, condition: $condition) {
      tenantId
      id
      info
      actions
      dividends
      splits
      capital_gains
      shares
      income_stmt
      quarterly_income_stmt
      balance_sheet
      quarterly_balance_sheet
      cashflow
      quarterly_cashflow
      history
      major_holders
      institutional_holders
      mutualfund_holders
      earnings_dates
      isin
      options
      news
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteYahooFinance = /* GraphQL */ `
  mutation DeleteYahooFinance(
    $input: DeleteYahooFinanceInput!
    $condition: ModelYahooFinanceConditionInput
  ) {
    deleteYahooFinance(input: $input, condition: $condition) {
      tenantId
      id
      info
      actions
      dividends
      splits
      capital_gains
      shares
      income_stmt
      quarterly_income_stmt
      balance_sheet
      quarterly_balance_sheet
      cashflow
      quarterly_cashflow
      history
      major_holders
      institutional_holders
      mutualfund_holders
      earnings_dates
      isin
      options
      news
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createKnowledgeGraph = /* GraphQL */ `
  mutation CreateKnowledgeGraph(
    $input: CreateKnowledgeGraphInput!
    $condition: ModelKnowledgeGraphConditionInput
  ) {
    createKnowledgeGraph(input: $input, condition: $condition) {
      tenantId
      id
      title
      type
      kgmid
      knowledgeGraphSearchLink
      serpapiKnowledgeGraphSearchLink
      website
      rating
      reviewCount
      address
      phone
      relatedQuestions {
        items {
          tenantId
          id
          question
          snippet
          title
          link
          displayedLink
          thumbnail
          nextPageToken
          serpapiLink
          createdAt
          updatedAt
          knowledgeGraphRelatedQuestionsTenantId
          knowledgeGraphRelatedQuestionsId
          __typename
        }
        nextToken
        __typename
      }
      raw
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateKnowledgeGraph = /* GraphQL */ `
  mutation UpdateKnowledgeGraph(
    $input: UpdateKnowledgeGraphInput!
    $condition: ModelKnowledgeGraphConditionInput
  ) {
    updateKnowledgeGraph(input: $input, condition: $condition) {
      tenantId
      id
      title
      type
      kgmid
      knowledgeGraphSearchLink
      serpapiKnowledgeGraphSearchLink
      website
      rating
      reviewCount
      address
      phone
      relatedQuestions {
        items {
          tenantId
          id
          question
          snippet
          title
          link
          displayedLink
          thumbnail
          nextPageToken
          serpapiLink
          createdAt
          updatedAt
          knowledgeGraphRelatedQuestionsTenantId
          knowledgeGraphRelatedQuestionsId
          __typename
        }
        nextToken
        __typename
      }
      raw
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteKnowledgeGraph = /* GraphQL */ `
  mutation DeleteKnowledgeGraph(
    $input: DeleteKnowledgeGraphInput!
    $condition: ModelKnowledgeGraphConditionInput
  ) {
    deleteKnowledgeGraph(input: $input, condition: $condition) {
      tenantId
      id
      title
      type
      kgmid
      knowledgeGraphSearchLink
      serpapiKnowledgeGraphSearchLink
      website
      rating
      reviewCount
      address
      phone
      relatedQuestions {
        items {
          tenantId
          id
          question
          snippet
          title
          link
          displayedLink
          thumbnail
          nextPageToken
          serpapiLink
          createdAt
          updatedAt
          knowledgeGraphRelatedQuestionsTenantId
          knowledgeGraphRelatedQuestionsId
          __typename
        }
        nextToken
        __typename
      }
      raw
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGoogleRelatedQuestion = /* GraphQL */ `
  mutation CreateGoogleRelatedQuestion(
    $input: CreateGoogleRelatedQuestionInput!
    $condition: ModelGoogleRelatedQuestionConditionInput
  ) {
    createGoogleRelatedQuestion(input: $input, condition: $condition) {
      tenantId
      id
      question
      snippet
      title
      link
      displayedLink
      thumbnail
      nextPageToken
      serpapiLink
      createdAt
      updatedAt
      knowledgeGraphRelatedQuestionsTenantId
      knowledgeGraphRelatedQuestionsId
      __typename
    }
  }
`;
export const updateGoogleRelatedQuestion = /* GraphQL */ `
  mutation UpdateGoogleRelatedQuestion(
    $input: UpdateGoogleRelatedQuestionInput!
    $condition: ModelGoogleRelatedQuestionConditionInput
  ) {
    updateGoogleRelatedQuestion(input: $input, condition: $condition) {
      tenantId
      id
      question
      snippet
      title
      link
      displayedLink
      thumbnail
      nextPageToken
      serpapiLink
      createdAt
      updatedAt
      knowledgeGraphRelatedQuestionsTenantId
      knowledgeGraphRelatedQuestionsId
      __typename
    }
  }
`;
export const deleteGoogleRelatedQuestion = /* GraphQL */ `
  mutation DeleteGoogleRelatedQuestion(
    $input: DeleteGoogleRelatedQuestionInput!
    $condition: ModelGoogleRelatedQuestionConditionInput
  ) {
    deleteGoogleRelatedQuestion(input: $input, condition: $condition) {
      tenantId
      id
      question
      snippet
      title
      link
      displayedLink
      thumbnail
      nextPageToken
      serpapiLink
      createdAt
      updatedAt
      knowledgeGraphRelatedQuestionsTenantId
      knowledgeGraphRelatedQuestionsId
      __typename
    }
  }
`;
export const createGoogleOrganicResult = /* GraphQL */ `
  mutation CreateGoogleOrganicResult(
    $input: CreateGoogleOrganicResultInput!
    $condition: ModelGoogleOrganicResultConditionInput
  ) {
    createGoogleOrganicResult(input: $input, condition: $condition) {
      tenantId
      id
      position
      title
      link
      displayed_link
      favicon
      snippet
      snippet_highlighted_words
      source
      createdAt
      updatedAt
      googleSearchOrganicResultTenantId
      googleSearchOrganicResultId
      __typename
    }
  }
`;
export const updateGoogleOrganicResult = /* GraphQL */ `
  mutation UpdateGoogleOrganicResult(
    $input: UpdateGoogleOrganicResultInput!
    $condition: ModelGoogleOrganicResultConditionInput
  ) {
    updateGoogleOrganicResult(input: $input, condition: $condition) {
      tenantId
      id
      position
      title
      link
      displayed_link
      favicon
      snippet
      snippet_highlighted_words
      source
      createdAt
      updatedAt
      googleSearchOrganicResultTenantId
      googleSearchOrganicResultId
      __typename
    }
  }
`;
export const deleteGoogleOrganicResult = /* GraphQL */ `
  mutation DeleteGoogleOrganicResult(
    $input: DeleteGoogleOrganicResultInput!
    $condition: ModelGoogleOrganicResultConditionInput
  ) {
    deleteGoogleOrganicResult(input: $input, condition: $condition) {
      tenantId
      id
      position
      title
      link
      displayed_link
      favicon
      snippet
      snippet_highlighted_words
      source
      createdAt
      updatedAt
      googleSearchOrganicResultTenantId
      googleSearchOrganicResultId
      __typename
    }
  }
`;
export const createCompanyQuestion = /* GraphQL */ `
  mutation CreateCompanyQuestion(
    $input: CreateCompanyQuestionInput!
    $condition: ModelCompanyQuestionConditionInput
  ) {
    createCompanyQuestion(input: $input, condition: $condition) {
      tenantId
      id
      question
      answer
      summary
      citations {
        items {
          tenantId
          id
          text
          link
          date
          createdAt
          updatedAt
          companyQuestionCitationsTenantId
          companyQuestionCitationsId
          __typename
        }
        nextToken
        __typename
      }
      template {
        tenantId
        id
        question
        position
        inRisk
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      companyQuestionsTenantId
      companyQuestionsId
      companyQuestionTemplateTenantId
      companyQuestionTemplateId
      __typename
    }
  }
`;
export const updateCompanyQuestion = /* GraphQL */ `
  mutation UpdateCompanyQuestion(
    $input: UpdateCompanyQuestionInput!
    $condition: ModelCompanyQuestionConditionInput
  ) {
    updateCompanyQuestion(input: $input, condition: $condition) {
      tenantId
      id
      question
      answer
      summary
      citations {
        items {
          tenantId
          id
          text
          link
          date
          createdAt
          updatedAt
          companyQuestionCitationsTenantId
          companyQuestionCitationsId
          __typename
        }
        nextToken
        __typename
      }
      template {
        tenantId
        id
        question
        position
        inRisk
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      companyQuestionsTenantId
      companyQuestionsId
      companyQuestionTemplateTenantId
      companyQuestionTemplateId
      __typename
    }
  }
`;
export const deleteCompanyQuestion = /* GraphQL */ `
  mutation DeleteCompanyQuestion(
    $input: DeleteCompanyQuestionInput!
    $condition: ModelCompanyQuestionConditionInput
  ) {
    deleteCompanyQuestion(input: $input, condition: $condition) {
      tenantId
      id
      question
      answer
      summary
      citations {
        items {
          tenantId
          id
          text
          link
          date
          createdAt
          updatedAt
          companyQuestionCitationsTenantId
          companyQuestionCitationsId
          __typename
        }
        nextToken
        __typename
      }
      template {
        tenantId
        id
        question
        position
        inRisk
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      companyQuestionsTenantId
      companyQuestionsId
      companyQuestionTemplateTenantId
      companyQuestionTemplateId
      __typename
    }
  }
`;
export const createCompanyCitation = /* GraphQL */ `
  mutation CreateCompanyCitation(
    $input: CreateCompanyCitationInput!
    $condition: ModelCompanyCitationConditionInput
  ) {
    createCompanyCitation(input: $input, condition: $condition) {
      tenantId
      id
      text
      link
      date
      createdAt
      updatedAt
      companyQuestionCitationsTenantId
      companyQuestionCitationsId
      __typename
    }
  }
`;
export const updateCompanyCitation = /* GraphQL */ `
  mutation UpdateCompanyCitation(
    $input: UpdateCompanyCitationInput!
    $condition: ModelCompanyCitationConditionInput
  ) {
    updateCompanyCitation(input: $input, condition: $condition) {
      tenantId
      id
      text
      link
      date
      createdAt
      updatedAt
      companyQuestionCitationsTenantId
      companyQuestionCitationsId
      __typename
    }
  }
`;
export const deleteCompanyCitation = /* GraphQL */ `
  mutation DeleteCompanyCitation(
    $input: DeleteCompanyCitationInput!
    $condition: ModelCompanyCitationConditionInput
  ) {
    deleteCompanyCitation(input: $input, condition: $condition) {
      tenantId
      id
      text
      link
      date
      createdAt
      updatedAt
      companyQuestionCitationsTenantId
      companyQuestionCitationsId
      __typename
    }
  }
`;
export const createGoogleNews = /* GraphQL */ `
  mutation CreateGoogleNews(
    $input: CreateGoogleNewsInput!
    $condition: ModelGoogleNewsConditionInput
  ) {
    createGoogleNews(input: $input, condition: $condition) {
      tenantId
      id
      position
      link
      title
      source
      date
      snippet
      thumbnail
      createdAt
      updatedAt
      googleSearchNewsTenantId
      googleSearchNewsId
      __typename
    }
  }
`;
export const updateGoogleNews = /* GraphQL */ `
  mutation UpdateGoogleNews(
    $input: UpdateGoogleNewsInput!
    $condition: ModelGoogleNewsConditionInput
  ) {
    updateGoogleNews(input: $input, condition: $condition) {
      tenantId
      id
      position
      link
      title
      source
      date
      snippet
      thumbnail
      createdAt
      updatedAt
      googleSearchNewsTenantId
      googleSearchNewsId
      __typename
    }
  }
`;
export const deleteGoogleNews = /* GraphQL */ `
  mutation DeleteGoogleNews(
    $input: DeleteGoogleNewsInput!
    $condition: ModelGoogleNewsConditionInput
  ) {
    deleteGoogleNews(input: $input, condition: $condition) {
      tenantId
      id
      position
      link
      title
      source
      date
      snippet
      thumbnail
      createdAt
      updatedAt
      googleSearchNewsTenantId
      googleSearchNewsId
      __typename
    }
  }
`;
export const createQuestionTemplate = /* GraphQL */ `
  mutation CreateQuestionTemplate(
    $input: CreateQuestionTemplateInput!
    $condition: ModelQuestionTemplateConditionInput
  ) {
    createQuestionTemplate(input: $input, condition: $condition) {
      tenantId
      id
      question
      position
      inRisk
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateQuestionTemplate = /* GraphQL */ `
  mutation UpdateQuestionTemplate(
    $input: UpdateQuestionTemplateInput!
    $condition: ModelQuestionTemplateConditionInput
  ) {
    updateQuestionTemplate(input: $input, condition: $condition) {
      tenantId
      id
      question
      position
      inRisk
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteQuestionTemplate = /* GraphQL */ `
  mutation DeleteQuestionTemplate(
    $input: DeleteQuestionTemplateInput!
    $condition: ModelQuestionTemplateConditionInput
  ) {
    deleteQuestionTemplate(input: $input, condition: $condition) {
      tenantId
      id
      question
      position
      inRisk
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTenant = /* GraphQL */ `
  mutation CreateTenant(
    $input: CreateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    createTenant(input: $input, condition: $condition) {
      id
      name
      isPaid
      isAREnabled
      createdAt
      updatedAt
      role
      counter
      scheduleInfo
      email
      emailList
      agingUpdatedUpTo
      arThreshold
      __typename
    }
  }
`;
export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant(
    $input: UpdateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input: $input, condition: $condition) {
      id
      name
      isPaid
      isAREnabled
      createdAt
      updatedAt
      role
      counter
      scheduleInfo
      email
      emailList
      agingUpdatedUpTo
      arThreshold
      __typename
    }
  }
`;
export const deleteTenant = /* GraphQL */ `
  mutation DeleteTenant(
    $input: DeleteTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    deleteTenant(input: $input, condition: $condition) {
      id
      name
      isPaid
      isAREnabled
      createdAt
      updatedAt
      role
      counter
      scheduleInfo
      email
      emailList
      agingUpdatedUpTo
      arThreshold
      __typename
    }
  }
`;
export const createAPIMapping = /* GraphQL */ `
  mutation CreateAPIMapping(
    $input: CreateAPIMappingInput!
    $condition: ModelAPIMappingConditionInput
  ) {
    createAPIMapping(input: $input, condition: $condition) {
      tenantId
      id
      isPaid
      origin
      endpoint
      totalCalls
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAPIMapping = /* GraphQL */ `
  mutation UpdateAPIMapping(
    $input: UpdateAPIMappingInput!
    $condition: ModelAPIMappingConditionInput
  ) {
    updateAPIMapping(input: $input, condition: $condition) {
      tenantId
      id
      isPaid
      origin
      endpoint
      totalCalls
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAPIMapping = /* GraphQL */ `
  mutation DeleteAPIMapping(
    $input: DeleteAPIMappingInput!
    $condition: ModelAPIMappingConditionInput
  ) {
    deleteAPIMapping(input: $input, condition: $condition) {
      tenantId
      id
      isPaid
      origin
      endpoint
      totalCalls
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPortfolioScoreRecord = /* GraphQL */ `
  mutation CreatePortfolioScoreRecord(
    $input: CreatePortfolioScoreRecordInput!
    $condition: ModelPortfolioScoreRecordConditionInput
  ) {
    createPortfolioScoreRecord(input: $input, condition: $condition) {
      tenantId
      id
      averagePortfolioScore
      totalValidCompanies
      totalMoneyAtRisk
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePortfolioScoreRecord = /* GraphQL */ `
  mutation UpdatePortfolioScoreRecord(
    $input: UpdatePortfolioScoreRecordInput!
    $condition: ModelPortfolioScoreRecordConditionInput
  ) {
    updatePortfolioScoreRecord(input: $input, condition: $condition) {
      tenantId
      id
      averagePortfolioScore
      totalValidCompanies
      totalMoneyAtRisk
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePortfolioScoreRecord = /* GraphQL */ `
  mutation DeletePortfolioScoreRecord(
    $input: DeletePortfolioScoreRecordInput!
    $condition: ModelPortfolioScoreRecordConditionInput
  ) {
    deletePortfolioScoreRecord(input: $input, condition: $condition) {
      tenantId
      id
      averagePortfolioScore
      totalValidCompanies
      totalMoneyAtRisk
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDashboardPreferences = /* GraphQL */ `
  mutation CreateDashboardPreferences(
    $input: CreateDashboardPreferencesInput!
    $condition: ModelDashboardPreferencesConditionInput
  ) {
    createDashboardPreferences(input: $input, condition: $condition) {
      tenantId
      id
      feature
      createdAt
      updatedAt
      preferences
      __typename
    }
  }
`;
export const updateDashboardPreferences = /* GraphQL */ `
  mutation UpdateDashboardPreferences(
    $input: UpdateDashboardPreferencesInput!
    $condition: ModelDashboardPreferencesConditionInput
  ) {
    updateDashboardPreferences(input: $input, condition: $condition) {
      tenantId
      id
      feature
      createdAt
      updatedAt
      preferences
      __typename
    }
  }
`;
export const deleteDashboardPreferences = /* GraphQL */ `
  mutation DeleteDashboardPreferences(
    $input: DeleteDashboardPreferencesInput!
    $condition: ModelDashboardPreferencesConditionInput
  ) {
    deleteDashboardPreferences(input: $input, condition: $condition) {
      tenantId
      id
      feature
      createdAt
      updatedAt
      preferences
      __typename
    }
  }
`;
export const createAlert = /* GraphQL */ `
  mutation CreateAlert(
    $input: CreateAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    createAlert(input: $input, condition: $condition) {
      tenantId
      id
      message
      isRead
      createdAt
      updatedAt
      type
      companyInfo
      __typename
    }
  }
`;
export const updateAlert = /* GraphQL */ `
  mutation UpdateAlert(
    $input: UpdateAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    updateAlert(input: $input, condition: $condition) {
      tenantId
      id
      message
      isRead
      createdAt
      updatedAt
      type
      companyInfo
      __typename
    }
  }
`;
export const deleteAlert = /* GraphQL */ `
  mutation DeleteAlert(
    $input: DeleteAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    deleteAlert(input: $input, condition: $condition) {
      tenantId
      id
      message
      isRead
      createdAt
      updatedAt
      type
      companyInfo
      __typename
    }
  }
`;
export const createChatFileCollection = /* GraphQL */ `
  mutation CreateChatFileCollection(
    $input: CreateChatFileCollectionInput!
    $condition: ModelChatFileCollectionConditionInput
  ) {
    createChatFileCollection(input: $input, condition: $condition) {
      tenantId
      id
      fileId
      fileName
      fileS3Path
      companyId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateChatFileCollection = /* GraphQL */ `
  mutation UpdateChatFileCollection(
    $input: UpdateChatFileCollectionInput!
    $condition: ModelChatFileCollectionConditionInput
  ) {
    updateChatFileCollection(input: $input, condition: $condition) {
      tenantId
      id
      fileId
      fileName
      fileS3Path
      companyId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteChatFileCollection = /* GraphQL */ `
  mutation DeleteChatFileCollection(
    $input: DeleteChatFileCollectionInput!
    $condition: ModelChatFileCollectionConditionInput
  ) {
    deleteChatFileCollection(input: $input, condition: $condition) {
      tenantId
      id
      fileId
      fileName
      fileS3Path
      companyId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChatHistory = /* GraphQL */ `
  mutation CreateChatHistory(
    $input: CreateChatHistoryInput!
    $condition: ModelChatHistoryConditionInput
  ) {
    createChatHistory(input: $input, condition: $condition) {
      tenantId
      id
      chatSessionId
      fileId
      fileName
      companyId
      messages
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateChatHistory = /* GraphQL */ `
  mutation UpdateChatHistory(
    $input: UpdateChatHistoryInput!
    $condition: ModelChatHistoryConditionInput
  ) {
    updateChatHistory(input: $input, condition: $condition) {
      tenantId
      id
      chatSessionId
      fileId
      fileName
      companyId
      messages
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteChatHistory = /* GraphQL */ `
  mutation DeleteChatHistory(
    $input: DeleteChatHistoryInput!
    $condition: ModelChatHistoryConditionInput
  ) {
    deleteChatHistory(input: $input, condition: $condition) {
      tenantId
      id
      chatSessionId
      fileId
      fileName
      companyId
      messages
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFeatureFlag = /* GraphQL */ `
  mutation CreateFeatureFlag(
    $input: CreateFeatureFlagInput!
    $condition: ModelFeatureFlagConditionInput
  ) {
    createFeatureFlag(input: $input, condition: $condition) {
      id
      features
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFeatureFlag = /* GraphQL */ `
  mutation UpdateFeatureFlag(
    $input: UpdateFeatureFlagInput!
    $condition: ModelFeatureFlagConditionInput
  ) {
    updateFeatureFlag(input: $input, condition: $condition) {
      id
      features
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFeatureFlag = /* GraphQL */ `
  mutation DeleteFeatureFlag(
    $input: DeleteFeatureFlagInput!
    $condition: ModelFeatureFlagConditionInput
  ) {
    deleteFeatureFlag(input: $input, condition: $condition) {
      id
      features
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomRawText = /* GraphQL */ `
  mutation CreateCustomRawText(
    $input: CreateCustomRawTextInput!
    $condition: ModelCustomRawTextConditionInput
  ) {
    createCustomRawText(input: $input, condition: $condition) {
      id
      tenantId
      companyId
      text
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomRawText = /* GraphQL */ `
  mutation UpdateCustomRawText(
    $input: UpdateCustomRawTextInput!
    $condition: ModelCustomRawTextConditionInput
  ) {
    updateCustomRawText(input: $input, condition: $condition) {
      id
      tenantId
      companyId
      text
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomRawText = /* GraphQL */ `
  mutation DeleteCustomRawText(
    $input: DeleteCustomRawTextInput!
    $condition: ModelCustomRawTextConditionInput
  ) {
    deleteCustomRawText(input: $input, condition: $condition) {
      id
      tenantId
      companyId
      text
      createdAt
      updatedAt
      __typename
    }
  }
`;
