import React, { useState, useEffect } from "react";
import { Paper, Typography, Box, Button, TextField, Select, MenuItem, IconButton, } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import {
  createCustomRawText,
  updateCompany,
  updateCustomRawText,
  updateNewCompanyRecord,
  updateWikipedia,
} from "./graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { useLoaderData } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addNewsLink,
  getCompany,
  getNewCompanyRecord,
  getTenant,
  triggerQuestionsAgent,
  listCustomRawTexts,
} from "./graphql/queries";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
import { getTenantIdAuth0, isAdminAuth0 } from "./authUtils";
import AWS from "aws-sdk";
import {fetchCompanySummary, fetchFileFromS3, normalizeRowData, updateCompanySummary, extractYearlyRevenue } from "./utils/Helper";
import CreateCompanyModal from './CreateCompanyModal';

const ManualSourceForm = ({
  manualSource,
  handleManualSourceChange,
  handleManualSourceSubmit,
  loading,
  errors,
  setErrors,
  setManualSource
}) => {
  const [newField, setNewField] = useState("");  
  const [newFieldValue, setNewFieldValue] = useState("");
  const [customFieldName, setCustomFieldName] = useState(""); 
  const [customFieldValue, setCustomFieldValue] = useState("");

  const isNumeric = (value) => !isNaN(parseFloat(value)) && isFinite(value);

  const allPossibleFields = [
    "description",
    "industry",
    "founders",
    "keyPeople",
    "founded",
    "ticker",
    "website",
    "country",
    "city",
    "parentCompany"
  ];

  const handleAddNewField = () => {
    const numericFields = ["assets", "operatingIncome"];
    let newErrors = {};

    if (numericFields.includes(newField)) {
      if (!isNumeric(newFieldValue)) {
        newErrors[newField] = `${newField} must be a numerical value`;
      }
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setManualSource((prevManualSource) => {
        const updatedManualSource = {
          ...prevManualSource,
          [newField]: newFieldValue,
        };
        console.log("Adding new field:", updatedManualSource); 
        handleManualSourceSubmit(updatedManualSource, true);
        return updatedManualSource;
      });
      setNewField("");
      setNewFieldValue("");
    }
  };

  const handleAddCustomField = () => {
    let newErrors = {};
  
    if (!customFieldName) {
      newErrors.customFieldName = "Custom field name cannot be empty";
    }
  
    setErrors(newErrors);
  
    if (Object.keys(newErrors).length === 0) {
      setManualSource((prevManualSource) => {
        const updatedManualSource = { ...prevManualSource, [customFieldName]: customFieldValue };
        console.log("Adding custom field:", updatedManualSource); 
        handleManualSourceSubmit(updatedManualSource, true);
        return updatedManualSource;
      });
      setCustomFieldName("");
      setCustomFieldValue("");
    }
  };

  const handleDeleteField = (field) => {
    setManualSource((prevManualSource) => {
      const updatedManualSource = { ...prevManualSource };
      delete updatedManualSource[field];
      handleManualSourceSubmit(updatedManualSource, true);
      return updatedManualSource;
    });
  };
  
  const missingFields = allPossibleFields.filter(
    (field) => !(field in manualSource)
  );

  const predefinedFieldsList = [
    'equity', 
    'netIncome', 
    'revenue', 
    'numberOfEmployees', 
    'marketCap', 
    'yearsOfOperation', 
    'AQR'
  ];

  const predefinedFields = Object.keys(manualSource).filter((key) => predefinedFieldsList.includes(key));
  const customFields = Object.keys(manualSource).filter((key) => !predefinedFieldsList.includes(key));

  return (
    <div>
      <Typography variant="h6" fontWeight="bold" sx={{ alignSelf: "flex-start", mb: 2 }}>
        Manual Source
      </Typography>

      {manualSource ? (
        <>
          {predefinedFields.map((key) => (
            <div style={{ display: 'flex', alignItems: 'center' }} key={key}>
              <TextField
                label={key}
                fullWidth
                error={!!errors[key]}
                helperText={errors[key]}
                value={manualSource[key]}
                onChange={(e) => handleManualSourceChange(key, e.target.value)}
                sx={{ mb: 2 }}
              />
              {allPossibleFields.includes(key) && (
                <IconButton onClick={() => handleDeleteField(key)} sx={{ marginLeft: 1 }}>
                  <CancelIcon />
                </IconButton>
              )}
            </div>
          ))}

          {customFields.length > 0 && (
            <>
              {customFields.map((key) => (
                <div style={{ display: 'flex', alignItems: 'center' }} key={key}>
                  <TextField
                    label={key}
                    fullWidth
                    error={!!errors[key]}
                    helperText={errors[key]}
                    value={manualSource[key]}
                    onChange={(e) => handleManualSourceChange(key, e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  <IconButton onClick={() => handleDeleteField(key)} sx={{ marginLeft: 1 }}>
                    <CancelIcon />
                  </IconButton>
                </div>
              ))}
            </>
          )}
        </>
      ) : (
        <Typography variant="body1" color="text.secondary">
          No manual source data available.
        </Typography>
      )}

      {missingFields.length > 0 && (
        <div style={{ marginBottom: '20px' }}>
          <Typography variant="h6">Add a New Field</Typography>
          <Select
            value={newField}
            onChange={(e) => setNewField(e.target.value)}
            displayEmpty
            fullWidth
            sx={{ mb: 2 }}
          >
            <MenuItem value="" disabled>Select a field</MenuItem>
            {missingFields.map((field) => (
              <MenuItem key={field} value={field}>
                {field}
              </MenuItem>
            ))}
          </Select>
          {newField && (
            <TextField
              label={`Value for ${newField}`}
              fullWidth
              value={newFieldValue}
              error={!!errors[newField]}
              helperText={errors[newField]}
              onChange={(e) => setNewFieldValue(e.target.value)}
              sx={{ mb: 2 }}
            />
          )}
          <Button
            sx={{ width: 200, m: 2, alignSelf: 'center' }}
            variant="contained"
            onClick={handleAddNewField}
            disabled={!newField || !newFieldValue}
          >
            Add Field
          </Button>
        </div>
      )}

      {/* Add custom field */}
      <div style={{ marginBottom: '20px' }}>
        <Typography variant="h6">Add a Custom Field</Typography>
        <TextField
          label="Custom Field Name"
          fullWidth
          value={customFieldName}
          error={!!errors.customFieldName}
          helperText={errors.customFieldName}
          onChange={(e) => setCustomFieldName(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Custom Field Value"
          fullWidth
          value={customFieldValue}
          onChange={(e) => setCustomFieldValue(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button
          sx={{ width: 200, m: 2, alignSelf: 'center' }}
          variant="contained"
          onClick={handleAddCustomField}
          disabled={!customFieldName || !customFieldValue}
        >
          Add Custom Field
        </Button>
      </div>

      <Button
        sx={{ width: 200, m: 2, alignSelf: 'center' }}
        variant="contained"
        onClick={handleManualSourceSubmit}
        disabled={loading}
      >
        Update Financial Info
      </Button>
    </div>
  );
};



export default function CustomSourcePage() {
  const { company } = useLoaderData();

  const [additionalInfo, setAdditionalInfo] = useState("");
  const [textFieldValue, setTextFieldValue] = useState(
    company.customSource || ""
  );
  const [companySummary, setCompanySummary] = useState(
    company.summary ? JSON.parse(company.summary) : ""
  );
  const [ticker, setTicker] = useState(company.ticker || "");
  const [country, setCountry] = useState(company.country || "");
  const [requestedCreditLimit, setRequestedCreditLimit] = useState(
    company.requestedCreditLimit || 0
  );
  const [parentCompanyName, setParentCompanyName] = useState(
    company.parentCompanyName || ""
  );
  const [parentDnbCompanyId, setParentDnbCompanyId] = useState(
    company.parentDnbCompanyId || ""
  );
  const [parentCountry, setParentCountry] = useState(
    company.parentCountry || ""
  );
  const [parentTicker, setParentTicker] = useState(company.parentTicker || "");
  const [companyJsonData, setCompanyJsonData] = useState(
    company.companyJsonData || ""
  );
  const [isManuallyAdded, setIsManuallyAdded] = useState(
    company.isManuallyAdded || false
  );
  const [currentTenant, setCurrentTenant] = useState(null);
  const [editedFields, setEditedFields] = useState({});
  const [companyRecord, setCompanyRecord] = useState({});
  const [loading, setLoading] = useState(false);
  const [infobox, setInfobox] = useState(() => {
    try {
      return company?.wikipedia?.infobox
        ? JSON.parse(company.wikipedia.infobox)
        : {};
    } catch (error) {
      console.error("Error parsing wikipedia infobox", error);
      return {};
    }
  });
  const [manualSource, setManualSource] = useState(() => {
    return company?.manualSource
      ? JSON.parse(company?.manualSource)
      : {
          equity: 0,
          netIncome: 0,
          revenue: 0,
          numberOfEmployees: 0,
          marketCap: 0,
          yearsOfOperation: 0,
          AQR: 0,
        };
  });
  const [avData, setAVData] = useState({});
  const [dnbData, setDnBData] = useState({});
  const [googleSearch, setGoogleSearch] = useState({});
  const [newsData, setNewsData] = useState({});
  const [newUrl, setNewUrl] = useState('');
  const [linkedInURL, setLinkedInURL] = useState('');
  const [errors, setErrors] = useState({});
  const [isDnbCalled, setIsDnbCalled] = useState(false);
  const [isCreateCompanyModalOpen, setCreateCompanyModalOpen] = useState(false);

  const isNumeric = (value) => !isNaN(parseFloat(value)) && isFinite(value);


  const validateFields = () => {
    let newErrors = {};
    const numericFields = ["assets", "operatingIncome", "revenue", "netIncome", "equity", "numberOfEmployees", "marketCap", "yearsOfOperation"];

    Object.keys(manualSource).forEach((key) => {
      if (key === 'AQR' && manualSource[key] && !isNumeric(manualSource[key])) {
        newErrors[key] = "AQR must be a valid ratio value";
      } else if (numericFields.includes(key)) {
        if (manualSource[key] && !isNumeric(manualSource[key])) {
          newErrors[key] = `${key} must be a numerical value`;
        }
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleManualSourceSubmit = async (customData, flag) => {
    const data = flag ? customData : manualSource
    console.log("data",data)
    setLoading(true);
    try {
      if (validateFields()) {
      const result = await API.graphql(
        graphqlOperation(updateCompany, {
          input: {
            tenantId: company.tenantId,
            id: company.id,
            manualSource: JSON.stringify(data),
          },
        })
      );
      const response = result.data.updateCompany;
      console.log("update manual source response",response);
      toast.success("Manual information updated successfully!");
    }
    } catch (error) {
      console.error(error);
      toast.error("Error updating Financial information");
    }
    setLoading(false);
  };

  const handleUpdateAddInfo = async () => {
    try {
      console.log("Checking for existing info...");

      // Query to check if data already exists
      const existingData = await API.graphql(
        graphqlOperation(listCustomRawTexts, {
          filter: {
            tenantId: { eq: company.tenantId },
            companyId: { eq: company.id },
          },
        })
      );

      const existingItem = existingData?.data?.listCustomRawTexts?.items?.[0];

      if (existingItem) {
        const result = await API.graphql(
          graphqlOperation(updateCustomRawText, {
            input: {
              id: existingItem.id, 
              text: additionalInfo,
            },
          })
        );
        toast.success("Additional information updated successfully!");
      } else {
        const result = await API.graphql(
          graphqlOperation(createCustomRawText, {
            input: {
              tenantId: company.tenantId,
              companyId: company.id,
              text: additionalInfo,
            },
          })
        );
        toast.success("Additional information added successfully!");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error updating or adding additional info");
    }
  };

  const handleManualSourceChange = (key, value) => {
    console.log("key, value",key, value)
    setManualSource((prevManualSource) => ({
      ...prevManualSource,
      [key]: value,
    }));
  };

  const handleInfoboxChange = (key, value) => {
    setInfobox((prevInfobox) => ({
      ...prevInfobox,
      [key]: value,
    }));
    setEditedFields((prevEditedFields) => ({
      ...prevEditedFields,
      [key]: true,
    }));

    console.log("editedFields", editedFields);
  };

  const handleUpdateCustomSourceClick = async () => {
    setLoading(true);
    try {
      const response = await handleUpdateCompanyTable();
      await handleUpdateWikipedia(response.updatedAt);
      await handleAddNewsLink()
      setNewUrl("")
      toast.success("Company information updated successfully!");
    } catch (error) {
      console.error(error);
      toast.error("Error updating company information");
    }
    setLoading(false);
  };

  const handleUpdateWikipedia = async (updateTime) => {
    try {
      const updatedInfobox = { ...infobox };
      const result = await API.graphql(
        graphqlOperation(updateWikipedia, {
          input: {
            tenantId: company.tenantId,
            id: company.wikipedia.id,
            infobox: JSON.stringify(updatedInfobox),
          },
        })
      );

      await syncCompanyRecord(updatedInfobox, updateTime);
      console.log("wiki update", result.data.updateWikipedia);
      toast.success("Wikipedia information updated successfully!");
    } catch (error) {
      console.error(error);
      toast.error("Error updating Wikipedia information");
    }
    setLoading(false);
  };

  const getCurrentTenant = async () => {
    const selectedTenant = getTenantIdFromURL();
    const isAdmin = await isAdminAuth0();
    const tenantId = isAdmin ? selectedTenant : await getTenantIdAuth0();
    console.log("getCurrentTenant");
    const response = await API.graphql(
      graphqlOperation(getTenant, {
        id: tenantId,
      })
    );

    console.log("Current Tenant -> ", response.data?.getTenant);
    setCurrentTenant(response?.data?.getTenant);
  };

  const fetchCompanyRecord = async () => {
    try {
      let result = await API.graphql(
        graphqlOperation(getNewCompanyRecord, {
          tenantId: company.tenantId,
          id: company.id,
        })
      );

      const record = result.data?.getNewCompanyRecord;
      setCompanyRecord(record);
    } catch (error) {
      console.log("error while fetching company records", error);
    }
  };

  const syncCompanyRecord = async (infoboxData, updateTime) => {
    let companyInfo = companyRecord.companyInfo
      ? JSON.parse(companyRecord.companyInfo)
      : null;

    if (companyInfo) {
      const newCompanyInfo = {
        ...companyInfo,
        country: country || null,
        ticker: ticker || null,
        infobox: JSON.stringify(infoboxData),
      };

      try {
        let result = await API.graphql(
          graphqlOperation(updateNewCompanyRecord, {
            input: {
              tenantId: company.tenantId,
              id: company.id,
              companyInfo: JSON.stringify(newCompanyInfo),
              originalItemUpdatedAt: updateTime,
            },
          })
        );

        console.log("Company Record Updated", result);
      } catch (error) {
        console.log("error while updating company records", error);
      }
    }
  };

  const fetchAVdata = async () => {
    try {
      const currentYear = new Date().getFullYear();
      const key = `public/alpha-vantage-financial-info/${company?.name}-${company.tenantId}.json`;
      const jsonData = await fetchFileFromS3(key);
      if (jsonData) {
        const companyOverviewData = jsonData.overview;
        const companyBalanceSheetData = jsonData.balanceSheet;
        const companyIncomeStatementData = jsonData.incomeStatement;
        let extractedData = {
          "Net Income": companyIncomeStatementData?.annualReports[0]?.netIncome,
          Revenue: companyIncomeStatementData?.annualReports?.find((rep) =>
            rep?.fiscalDateEnding?.includes(currentYear - 1)
          )?.totalRevenue,
          Equity:
            companyBalanceSheetData?.annualReports[0]?.totalShareholderEquity,
          "Market Cap": companyOverviewData?.MarketCapitalization,
        };
        setAVData(extractedData);
      } else setAVData(null);
    } catch (error) {
      console.log("Failed to read file", error);
    }
  };

  const fetchDnbData = async () => {
    if (!company?.dnbCompanyId) {
      setDnBData(null);
      console.log("No DnB Company ID found");
      return;
    }
  
    const extractRevenueFromYearlyData = (yearlyRevData) => {
      console.log("Extracting revenue from yearly data", yearlyRevData);
      if (!yearlyRevData || !Array.isArray(yearlyRevData) || yearlyRevData.length === 0) {
        return null;
      }
  
      const usdRevenue = yearlyRevData.find((item) => item?.currency === "USD");
      if (usdRevenue?.value) {
        console.log("Found USD revenue", usdRevenue.value);
        return formatNumber(usdRevenue.value);
      }
  
      const firstEntry = yearlyRevData[0];
      if (firstEntry?.currency && firstEntry?.value) {
        console.log("Using first entry for revenue", firstEntry);
        return `${firstEntry.currency} ${firstEntry.value}`;
      }
  
      return null;
    };
  
    const calculateRevenueV2 = (financialData) => {
      console.log("Calculating revenue V2", financialData);
      const { units, currency, overview } = financialData?.organization?.latestFinancials || {};
  
      const multiplier = {
        Thousand: 1000,
        Million: 1000000,
        Billion: 1000000000,
      }[units] || 1;
  
      if (currency === "USD" && overview?.salesRevenue) {
        console.log("Revenue V2 in USD", overview.salesRevenue, "Multiplier", multiplier);
        return formatNumber(overview.salesRevenue * multiplier);
      }
  
      return null;
    };
  
    try {
      let key = `public/company-info/info-${company.dnbCompanyId}.json`;
      console.log("Fetching company-info file from S3", key);
      let currentCompany = await fetchFileFromS3(key);
  
      if (currentCompany) {
        setIsDnbCalled(true);
        console.log("File fetched successfully", currentCompany);
        currentCompany = normalizeRowData(currentCompany);
        let yearlyRevData = extractYearlyRevenue(currentCompany[0]?.response?.organization);
  
        const extractedData = {
          Revenue: yearlyRevData,
          "Number Of Employees": currentCompany[0]?.response?.organization?.domesticUltimate?.numberOfEmployees?.[0]?.value,
        };
  
        console.log("Extracted data", extractedData);
  
        
        if (extractedData.Revenue) {
          console.log("Revenue found, setting data");
          setDnBData(extractedData);
          return;
        } else {
          setDnBData(null);
        }
      } else {
        console.log("No data found for the first key");
      }
  
      key = `public/company-financial-v2/financial-${company.dnbCompanyId}.json`;
      console.log("Fetching file from S3", key);
      let summary = await fetchFileFromS3(key);
  
      if (summary) {
        setIsDnbCalled(true);
        console.log("company-financial-v2 File fetched successfully", summary);
        summary = normalizeRowData(summary);
        const dnbFinancialsV2 = summary[0]?.response;
  
        const V2Revenue = calculateRevenueV2(dnbFinancialsV2);
        if (V2Revenue) {
          const extractedData = {
            Revenue: V2Revenue,
          };
  
          console.log("Revenue V2 found, setting data", extractedData);
          setDnBData(extractedData);
        } else {
          setDnBData(null);
          console.log("No revenue found in V2 data");
        }
      } else {
        setDnBData(null);
        console.log("No data found for the second key");
      }
    } catch (error) {
      console.error("Error fetching DnB data", error);
      setDnBData(null);
    }
  };

  const setCallDnbManuallyFlag = async () => {
    try {
      setLoading(true)
      console.log("Setting callDnbManuallyFlag true", company.tenantId);
      const companySummary = await fetchCompanySummary(company?.tenantId, company?.id)
      if (companySummary) {
        console.log("Updating flag for company", company.name);
        companySummary.callDnbManuallyFlag = true;
        const companySummaryUpdateInput = {
          tenantId: company?.tenantId,
          companyId: company?.id,
          summaryData: JSON.stringify(companySummary),
        };
        await updateCompanySummary(companySummaryUpdateInput);
        await API.graphql(
          graphqlOperation(triggerQuestionsAgent, {
            tenantId: company.tenantId,
            id: company.id,
          })
        );
        toast.success("Underwriting Triggered with manual DnB call!")
        console.log("Flag updated successfully");
      } else {
        console.log("Company not found in summary", company.name);
      }
    } catch (error) {
      console.error("Error setting callDnbManuallyFlag", error);
    } finally{
      setLoading(false)
    }
  };

  const parseDate = (dateString) => {
    return new Date(dateString);
  };

  const refreshNewsData = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(getCompany, {
          tenantId: company.tenantId,
          id: company.id,
        })
      );
      if (result.data && result.data.getCompany && result.data.getCompany.googleSearch) {
        const searchData = result.data.getCompany.googleSearch;
        setGoogleSearch(searchData);
        searchData.news.items.sort((a, b) => {
          const dateA = parseDate(a.date);
          const dateB = parseDate(b.date);
        
          return dateB - dateA; 
        });
        setNewsData(searchData.news.items);
        setGoogleSearch(searchData)
      } else {
        console.log("No news data available.");
      }
    } catch (error) {
      console.log("Error fetching news data", error);
    }
  };

  const handleAddNewsLink = async () => {
    try {
      setLoading(true)
      await API.graphql(
        graphqlOperation(addNewsLink, {
          tenantId: googleSearch.tenantId,
          url: newUrl,
          googleSearchNewsId: googleSearch.id,
        })
      );
      setNewUrl('');
      setLoading(false)
      refreshNewsData();
      toast.success("News Link added successfully!");
      
    } catch (error) {
      console.log("Error adding news link", error);
    }
  };
  const handleUpdateCompanyTable = async () => {
      let filename = '';
      if(linkedInURL){
        filename = await getLinkedInFileName();
      }
    try {
      const result = await API.graphql(
        graphqlOperation(updateCompany, {
          input: {
            tenantId: company.tenantId,
            id: company.id,
            customSource: textFieldValue,
            summary: JSON.stringify(companySummary),
            ticker,
            country,
            requestedCreditLimit,
            parentCompanyName,
            parentDnbCompanyId,
            parentCountry,
            parentTicker,
            companyJsonData,
            isManuallyAdded,
            linkedInFileName:filename
          },
        })
      );
      console.log("Updated Company Result",result.data.updateCompany)
      return result.data.updateCompany;
    } catch (error) {
      console.log("Error adding news link", error);
    }
  };

  async function getLinkedInFileName() {
    const url = 'https://5zoenoqpswg7yejzkhvbjkir3u0xksar.lambda-url.eu-central-1.on.aws/';
    const data = {
      url: linkedInURL,
    };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const result = await response.json();

      const filename = result.path.split("/")[1];
      console.log('LinkedIn parser file name',filename);
      return filename; 
    } catch (error) {
      console.error('Error:', error);
    }
  }
  const getAdditionalInfo = async() => {
    //use composite key or gsi
    try {
      const result = await API.graphql(
        graphqlOperation(listCustomRawTexts, {
          filter: {
            companyId: { eq: company.id },
            tenantId: { eq: company.tenantId },
          }
        })
      );
      let response = result?.data?.listCustomRawTexts?.items
      if(response && response?.length>0){
        setAdditionalInfo(response[0]?.text)
      }
      console.log("getting additional info",result.data)
    } catch (error) {
      console.log("error while fetching additional info",error)
    }
  }

  useEffect(() => {
    (async () => {
      getCurrentTenant();
      fetchCompanyRecord();
      fetchAVdata();
      fetchDnbData();
      refreshNewsData();
      await getAdditionalInfo();
    })();
  }, []);

  const formatNumber = (number) => {
    if (isNaN(number) || !number) {
      return "-"; // Return a placeholder for missing values
    }
    if (number >= 1e9) {
      return `US $${(number / 1e9).toFixed(2)} billion`;
    } else if (number >= 1e6) {
      return `US $${(number / 1e6).toFixed(2)} million`;
    } else if (number >= 1e3) {
      return `US $${(number / 1e3).toFixed(2)} thousand`;
    } else {
      return `US $${number}`;
    }
  };

  const handleDunsIdUpdate = async (dunsId) => {
    console.log("Duns Id ", dunsId);
    setCreateCompanyModalOpen(false);

    try {
      const result = await API.graphql(
        graphqlOperation(updateCompany, {
          input: {
            tenantId: company.tenantId,
            id: company.id,
            dnbCompanyId: dunsId, 
          },
        })
      );
      console.log("Company updated with new DUNS ID:", result.data.updateCompany);
      toast.success("DnB ID updated successfully!");
      await setCallDnbManuallyFlag();
    } catch (error) {
      console.error("Error updating DUNS ID:", error);
      toast.error("Failed to update DUNS ID.");
    }
  };

  return (
    <React.Fragment>
      <Paper
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Typography variant="h2" fontWeight="bold" color="text.primary">
            {company.name}
          </Typography>
        </Box>
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Custom Text
        </Typography>
        <TextField
          id="outlined-multiline-flexible"
          label="Enter custom text"
          multiline
          rows={4}
          fullWidth
          value={textFieldValue}
          onChange={(e) => setTextFieldValue(e.target.value)}
        />
        <Button
          sx={{ width: 200, m: 2, alignSelf: "center" }}
          variant="contained"
          onClick={handleUpdateCustomSourceClick}
          disabled={loading}
        >
          Update Custom Source
        </Button>
        <div class="div">
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Additional Info
        </Typography>
        <TextField
          id="outlined-multiline-flexible"
          label="Enter Additional Info"
          multiline
          rows={4}
          fullWidth
          value={additionalInfo}
          onChange={(e) => setAdditionalInfo(e.target.value)}
        />
        <Button
          sx={{ width: 200, m: 2, alignSelf: "center" }}
          variant="contained"
          onClick={handleUpdateAddInfo}
          disabled={loading}
        >
          Update Additional Info
        </Button>
        </div>
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Summary
        </Typography>
        <TextField
          id="outlined-multiline-flexible"
          label="Enter Company Summary"
          multiline
          // rows={10}
          fullWidth
          value={companySummary}
          onChange={(e) => setCompanySummary(e.target.value)}
        />
        <Button
          sx={{ width: 200, m: 2, alignSelf: "center" }}
          variant="contained"
          onClick={handleUpdateCustomSourceClick}
          disabled={loading}
        >
          Update Summary
        </Button>
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Country
        </Typography>
        <TextField
          id="country"
          label="Country"
          fullWidth
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        />
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Ticker
        </Typography>
        <TextField
          id="ticker"
          label="Ticker"
          fullWidth
          value={ticker}
          onChange={(e) => setTicker(e.target.value)}
        />
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Requested Credit Limit
        </Typography>
        <TextField
          id="requestedCreditLimit"
          label="Requested Credit Limit"
          type="number"
          fullWidth
          value={requestedCreditLimit}
          onChange={(e) => setRequestedCreditLimit(parseFloat(e.target.value))}
        />
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Parent Company Name
        </Typography>
        <TextField
          id="parentCompanyName"
          label="Parent Company Name"
          fullWidth
          value={parentCompanyName}
          onChange={(e) => setParentCompanyName(e.target.value)}
        />{" "}
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Parent DNB Company ID
        </Typography>
        <TextField
          id="parentDnbCompanyId"
          label="Parent DNB Company ID"
          fullWidth
          value={parentDnbCompanyId}
          onChange={(e) => setParentDnbCompanyId(e.target.value)}
        />
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Parent Country
        </Typography>
        <TextField
          id="parentCountry"
          label="Parent Country"
          fullWidth
          value={parentCountry}
          onChange={(e) => setParentCountry(e.target.value)}
        />
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Parent Ticker
        </Typography>
        <TextField
          id="parentTicker"
          label="Parent Ticker"
          fullWidth
          value={parentTicker}
          onChange={(e) => setParentTicker(e.target.value)}
        />
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Company Linked URL
        </Typography>
        <TextField
          id="CompanyLinkedURL"
          label="Company Linked URL"
          fullWidth
          value={linkedInURL}
          onChange={(e) => setLinkedInURL(e.target.value)}
        />
        {newsData.length===0?(<><Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Add News Link
        </Typography>
        <TextField
          id="NewsLink"
          label="News Link"
          fullWidth
          value={newUrl}
          onChange={(e) => setNewUrl(e.target.value)}
        /></>):null}
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Wikipedia Infobox
        </Typography>
        {infobox ? (
          Object.keys(infobox).map((key) => (
            <TextField
              key={key}
              label={key}
              fullWidth
              value={infobox[key]?.manual ? infobox[key].value : infobox[key]}
              onChange={(e) => handleInfoboxChange(key, e.target.value)}
              sx={{ mb: 2 }}
            />
          ))
        ) : (
          <Typography variant="body1" color="text.secondary">
            No infobox data available.
          </Typography>
        )}
        <Button
          sx={{ width: 200, m: 2, alignSelf: "center" }}
          variant="contained"
          onClick={handleUpdateCustomSourceClick}
          disabled={loading}
        >
          Update Company Info
        </Button>
        <Typography
          variant="h3"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", my: 2 }}
        >
          Financial Information
        </Typography>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", my: 2 }}
        >
          Alpha Vantage:
        </Typography>
        {avData
          ? Object.keys(avData).map((key) => (
              <Typography key={key} sx={{ mb: 2 }}>
                <strong>{key}:</strong> {formatNumber(avData[key])}
              </Typography>
            ))
          : 'No Alpha Vantage Data Available'}
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ alignSelf: "flex-start", my: 2 }}
        >
          DnB:
        </Typography>
        {dnbData ? (
          Object.keys(dnbData).map((key) => (
            <Typography key={key} sx={{ mb: 2 }}>
              <strong>{key}:</strong> {dnbData[key]}
            </Typography>
          ))
        ) : (
          <>
            <Typography>No DnB Data Available</Typography>
            <Button
              variant='contained'
              onClick={setCallDnbManuallyFlag}
              disabled={(!company?.dnbCompanyId)|| (!dnbData && isDnbCalled) || loading}
              sx={{ width: 200, alignSelf: 'left' }}
              >
              Call DnB Manually
            </Button>
            {!company?.dnbCompanyId && (
              <Button
                variant="contained"
                onClick={() => setCreateCompanyModalOpen(true)}
                sx={{ width: 200, alignSelf: 'left', mt:2 }}
              >
                Search DnB
              </Button>
            )}
            {!dnbData && isDnbCalled && (
              <Typography sx={{ color: "gray" }}>
                DnB API aleady called.
              </Typography>
            )}
          </>
        )}
        <ManualSourceForm
          manualSource={manualSource}
          handleManualSourceChange={handleManualSourceChange}
          handleManualSourceSubmit={handleManualSourceSubmit}
          loading={loading}
          errors={errors}
          setErrors={setErrors}
          setManualSource={setManualSource}
        />
        <CreateCompanyModal
          open={isCreateCompanyModalOpen}
          onClose={() => setCreateCompanyModalOpen(false)}
          onDunsIdSelect={handleDunsIdUpdate}
        />
      </Paper>
    </React.Fragment>
  );
}
